import { getAllEntities } from '@oma-kala-shared/core/logic';
import { Gear, GearActivityType, GearType, LocalStateGear } from '@oma-kala-shared/core/model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../store';

export interface GearState {
    loadingGearTypes: boolean;
    gearTypes: GearType[];
    loadingUserGear: boolean;
    userGear: LocalStateGear[];
    gearActivityTypes: GearActivityType[];
}

export const initialState: GearState = {
    loadingGearTypes: false,
    gearTypes: [],
    loadingUserGear: false,
    userGear: [],
    gearActivityTypes: [],
};

const gearSlice = createSlice({
    name: 'gear',
    initialState,
    reducers: {
        setGearTypesInternal: (state: GearState, action: PayloadAction<GearType[]>) => {
            state.gearTypes = action.payload;
            state.loadingGearTypes = false;
        },
        setUserGearInternal: (state: GearState, action: PayloadAction<LocalStateGear[]>) => {
            state.userGear = action.payload;
            state.loadingUserGear = false;
        },
        setGearActivityTypesInternal: (state: GearState, action: PayloadAction<GearActivityType[]>) => {
            state.gearActivityTypes = action.payload;
        },
    },
});

export const setGearTypes =
    (gearTypes: GearType[]): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(gearSlice.actions.setGearTypesInternal(gearTypes));
    };

export const setGearActivityTypes =
    (gearActivityType: GearActivityType[]): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(gearSlice.actions.setGearActivityTypesInternal(gearActivityType));
    };

export const setUserGear =
    (gear: LocalStateGear[]): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(gearSlice.actions.setUserGearInternal(gear));
    };

export const selectGearTypes = (state: RootState): GearType[] => state.gear.gearTypes;
export const selectUserGearLocalState = (state: RootState): LocalStateGear[] => state.gear.userGear;
export const selectUserGear = (state: RootState): Gear[] => getAllEntities(state.gear.userGear);
export const selectGearActivityTypes = (state: RootState): GearActivityType[] => state.gear.gearActivityTypes;

export default gearSlice.reducer;
