import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, GearType } from '@oma-kala-shared/core/model';

import { RequestStatus } from 'app/model';

import { setGearTypes } from '../gear/gearSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';

/**
 * Fetch gear types from the back-end.
 *
 * @param {string} syncToken
 * @return {AppThunk} Dispatchable thunk
 */
export const fetchGearTypes =
    (syncToken?: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.log('Fetch gear types');
        if (syncToken) {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));
        }
        const webService: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<GearType[]> = await webService.getGearTypes();
        if (response.successful) {
            dispatch(setGearTypes(response.content));
            if (syncToken) {
                dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
            }
        } else {
            console.debug('Fetching gear types failed: ' + response.message);
            if (syncToken) {
                dispatch(
                    setSyncState({
                        syncToken,
                        value: { status: RequestStatus.Failed, message: response.message as string },
                    })
                );
            }
        }
    };
