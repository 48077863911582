import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

interface RegistrationSectionWrapperProps {
    title: string;
    children?: JSX.Element;
    description?: string;
}

export const RegistrationSectionWrapper = ({ title, description, children }: RegistrationSectionWrapperProps) => {
    return (
        <Box>
            <Box>
                <Typography variant={'subtitle1'} fontWeight={'bold'}>
                    {title}
                </Typography>
                {description && (
                    <Typography mb={2} variant={'body2'}>
                        {description}
                    </Typography>
                )}
            </Box>
            {children}
            <Divider style={{ marginTop: 15, marginBottom: 15, backgroundColor: '#fff' }} />
        </Box>
    );
};
