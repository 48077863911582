import { OpenInNew } from '@mui/icons-material';
import { Grid, Link, styled, Typography } from '@mui/material';
import { CMSItem, CMSTag } from '@oma-kala-shared/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { filterCmsItemsByTag, getLocalizedLinkTag, isLinkTypeLoggedInOnly } from 'app/logic/CmsService';
import { selectCmsLinks } from 'app/state/cms/cmsSlice';
import { selectIsLoggedIn, selectUserIsAdmin } from 'app/state/user/userSlice';
import { landingPurple } from 'app/theme';

const FooterWrapper = styled('div')({
    backgroundColor: landingPurple,
    color: '#fff',
    padding: '30px 50px',
    height: '100%',
    minHeight: 430,
    display: 'flex',
    alignItems: 'center',
});

const FooterItem = styled('div')({
    padding: 30,
    a: {
        color: '#fff',
    },
    ul: {
        margin: 0,
        padding: 0,
        width: '100%',
    },
    li: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
    },
});

const FooterItemTitle = styled('div')({
    fontSize: '32px',
    borderBottom: '2px solid #fff',
    marginBottom: '12px',
});

const LogoImg = styled('img')({
    width: '150px',
    height: 'auto',
});

/**
 * @return {JSX.Element}
 */
export function Footer() {
    const { i18n, t } = useTranslation();
    const links: CMSItem[] = useSelector(selectCmsLinks);
    const baseUrl: string | undefined = process.env.REACT_APP_BASE_URL;

    const userIsAdmin: boolean = useSelector(selectUserIsAdmin);
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);

    if (!baseUrl) {
        console.warn('Base URL not found; received: ' + baseUrl);
    }

    const isLinkHidden = (linkItem: CMSItem) => {
        return !isLoggedIn && isLinkTypeLoggedInOnly(linkItem);
    };

    const omakalaLinksLeft: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_LEFT_FI,
            swedish: CMSTag.LINK_LEFT_SV,
            english: CMSTag.LINK_LEFT_EN,
            sami: CMSTag.LINK_LEFT_SE,
        })
    ).filter(i => !isLinkHidden(i));
    const omakalaLinksMiddle: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_MIDDLE_FI,
            swedish: CMSTag.LINK_MIDDLE_SV,
            english: CMSTag.LINK_MIDDLE_EN,
            sami: CMSTag.LINK_MIDDLE_SE,
        })
    ).filter(i => !isLinkHidden(i));
    const omakalaLinksRight: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_RIGHT_FI,
            swedish: CMSTag.LINK_RIGHT_SV,
            english: CMSTag.LINK_RIGHT_EN,
            sami: CMSTag.LINK_RIGHT_SE,
        })
    ).filter(i => !isLinkHidden(i));

    return (
        <FooterWrapper>
            <Grid container spacing={5}>
                <Grid item md={4}>
                    <FooterItem>
                        <FooterItemTitle>Omakala.fi</FooterItemTitle>
                        <ul>
                            {userIsAdmin && (
                                <li>
                                    <LinkToNewTab
                                        href={(baseUrl ?? '') + '/cms/wp-login.php'}
                                        text={t('footer.mainFooter.omaKalaLinks.cmsLogin')}
                                    />
                                </li>
                            )}
                            <li>
                                <Link component={RouterLink} to="/about" color="inherit" underline="hover">
                                    <Typography>{t('footer.mainFooter.omaKalaLinks.about')}</Typography>
                                </Link>
                            </li>
                            <li>
                                <Link component={RouterLink} to="/technical-requirements" color="inherit" underline="hover">
                                    <Typography>{t('footer.mainFooter.omaKalaLinks.technicalRequirements')}</Typography>
                                </Link>
                            </li>
                            <li>
                                <Link component={RouterLink} to="/terms-and-conditions" color="inherit" underline="hover">
                                    <Typography>{t('footer.mainFooter.omaKalaLinks.terms')}</Typography>
                                </Link>
                            </li>
                            {omakalaLinksLeft.map((item: CMSItem) => (
                                <CMSLink key={item.id} item={item} />
                            ))}
                        </ul>
                    </FooterItem>
                </Grid>

                <Grid item md={4}>
                    <FooterItem>
                        <FooterItemTitle>{t('footer.mainFooter.links.link')}</FooterItemTitle>
                        {omakalaLinksMiddle.map((item: CMSItem) => (
                            <CMSLink key={item.id} item={item} />
                        ))}
                    </FooterItem>
                </Grid>
                <Grid item md={4}>
                    <FooterItem>
                        <FooterItemTitle>Luke.fi</FooterItemTitle>
                        {omakalaLinksRight.map((item: CMSItem) => (
                            <CMSLink key={item.id} item={item} />
                        ))}
                    </FooterItem>
                </Grid>
            </Grid>
        </FooterWrapper>
    );
}

const CMSLink = ({ item }: { item: CMSItem }) => {
    const url: string = getLink(item);
    const isRelative: boolean = url.startsWith('/');

    return (
        <li>
            {isRelative ? (
                <Link to={url} component={RouterLink} color="inherit" underline="hover">
                    <span dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                </Link>
            ) : (
                <Link href={url} color="inherit" underline="hover" target="_blank">
                    <span dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                    <OpenInNew fontSize="small" style={{ paddingLeft: 2, marginBottom: -2, fontSize: '0.95em' }} />
                </Link>
            )}
        </li>
    );
};

// Links are defined in the CMS in the excerpt field and are always enclosed by parenthesis
const getLink = (item: CMSItem): string => {
    const regex = /<p>(.+)<\/p>/;
    if (!regex.test(item.excerpt.rendered)) {
        return item.excerpt.rendered;
    }
    const matches: RegExpExecArray | null = regex.exec(item.excerpt.rendered);
    return matches === null ? item.excerpt.rendered : matches[1];
};

// Render a link to a new tab, demarked with an icon
const LinkToNewTab = ({ href, text }: { href: string; text: string }) => {
    return (
        <Link href={href} color="inherit" underline="hover" target="_blank">
            <span>{text}</span>
            <OpenInNew fontSize="small" style={{ paddingLeft: 2, marginBottom: -2, fontSize: '0.95em' }} />
        </Link>
    );
};
