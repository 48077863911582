import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import { useNavigate } from 'react-router-dom';

import { PushNotificationTable } from 'app/components/display';
import { ContentPage } from 'app/components/layout';
import { useSyncToken } from 'app/hooks';
import { PushNotification } from 'app/model';
import { setErrorMessage, setInfoMessage } from 'app/state/message/messageSlice';
import { selectNotifications } from 'app/state/notification/notificationSlice';
import { fetchPushNotifications } from 'app/state/thunks';
import { visuallyHidden } from '@mui/utils';

/**
 * @return {JSX.Element}
 */
export const BrowsePushNotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const notifications: PushNotification[] = useSelector(selectNotifications);

    const [syncToken, isLoading] = useSyncToken({
        onSuccess: (msg?: string) => {
            dispatch(setInfoMessage(t('admin.notifications.browse.loaded')));
        },
        onError: (msg: string) => {
            dispatch(setErrorMessage(t('admin.notifications.browse.loadError', { message: msg })));
        },
    });

    const reloadNotifications = () => {
        dispatch(fetchPushNotifications(syncToken));
    };

    useEffect(() => {
        reloadNotifications();
    }, [syncToken]);

    const handleReturn = () => navigate('/');

    return (
        <ContentPage title={t('admin.notifications.browse.title')} description={t('admin.notifications.browse.description')}>
            <Grid container direction="column" spacing={2}>
                {/* TODO: Remove this section when article grouping is implemented "properly" */}
                <Grid item>
                    <Typography variant="body2">
                        {/* eslint-disable-next-line max-len */}
                        NOTE: Notifications are currently grouped by their creation dates and may not necessarily be mappped to the exact
                        same news articles.
                    </Typography>
                    <Typography variant="body2">LOK-XXX will fix this by grouping notifications using tags.</Typography>
                </Grid>
                <Grid item>
                    <ResizableBox width={500} height={400} draggableOpts minConstraints={[600, 400]} maxConstraints={[1200, 400]}>
                        <LinearProgress sx={!isLoading ? visuallyHidden : undefined} />
                        <PushNotificationTable notifications={notifications} />
                    </ResizableBox>
                </Grid>
                <Grid item xs>
                    <Button variant="contained" sx={{ m: 1 }} onClick={handleReturn}>
                        {t('common.return')}
                    </Button>
                    <Button variant="outlined" sx={{ m: 1 }} disabled={isLoading} onClick={reloadNotifications}>
                        {t('common.reload')}
                    </Button>
                </Grid>
            </Grid>
        </ContentPage>
    );
};
