import { LayerSettingMap, MapLayer, LayerCommonSettingName, LayerSettings } from '@oma-kala-shared/core/model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSetting, storeSetting } from 'app/logic/StorageService';

import { AppThunk, RootState } from 'app/state/store';

export interface LayerState {
    settings: LayerSettingMap;
    selectedBaseLayer: string;
}

export const mapLayersSettings: LayerSettingMap = {
    [MapLayer.ACTIVITY]: {
        visible: true,
        editable: true,
        settings: [],
    },
    [MapLayer.CATCH]: {
        visible: true,
        editable: true,
        settings: [],
    },
    [MapLayer.WFS_FISHING_RESTRICTIONS]: {
        visible: true,
        editable: false,
        settings: [],
    },
    [MapLayer.HIGHLIGHT_LAYER]: {
        visible: true,
        editable: false,
        settings: [],
    },
    [MapLayer.POINT_OF_INTEREST]: {
        visible: true,
        editable: true,
        settings: [],
    },
    [MapLayer.POSITION]: {
        visible: true,
        editable: false,
        settings: [],
    },
    [MapLayer.DEPTH_CURVES]: {
        visible: true,
        editable: true,
        settings: [],
    },
    [MapLayer.WMS_FISHING_RESTRICTIONS]: {
        visible: true,
        editable: true,
        settings: [
            {
                name: LayerCommonSettingName.FROM_DATE,
                type: 'date',
                value: '',
            },
            {
                name: LayerCommonSettingName.TO_DATE,
                type: 'date',
                value: '',
            },
            {
                name: 'deviatingRestrictionsOnly',
                type: 'boolean',
                value: false,
            },
        ],
    },
    [MapLayer.ROUTE]: {
        visible: true,
        editable: false,
        settings: [],
    },
    [MapLayer.ORTHOPHOTO_LAYER]: {
        visible: false,
        editable: true,
        isBaseLayer: true,
        settings: [],
    },
    [MapLayer.TERRAIN_LAYER]: {
        visible: false,
        editable: true,
        isBaseLayer: true,
        settings: [],
    },
    [MapLayer.VECTOR_LAYER]: {
        visible: true,
        editable: true,
        isBaseLayer: true,
        settings: [],
    },
};

const localStorageKey = 'MAP_LAYER_SETTINGS';

export type MapLayerSettingStorageKey = typeof localStorageKey;

const mapSettingStoredInStorage = getSetting(localStorageKey);

const restoreState = mapSettingStoredInStorage === null ? null : JSON.parse(mapSettingStoredInStorage);

export const initialState: LayerState = {
    settings: mapLayersSettings,
    selectedBaseLayer: MapLayer.VECTOR_LAYER,
};

type SetLayerSettingPayload<T extends keyof LayerSettingMap> = { key: T; data: LayerSettingMap[T] };

export type BaseLayer = MapLayer.VECTOR_LAYER | MapLayer.TERRAIN_LAYER | MapLayer.ORTHOPHOTO_LAYER;

const mapLayerSlice = createSlice({
    name: 'mapLayer',
    initialState: restoreState ?? initialState,
    reducers: {
        setLayerSetting: <T extends keyof LayerSettingMap>(state: LayerState, action: PayloadAction<SetLayerSettingPayload<T>>) => {
            state.settings[action.payload.key] = action.payload.data;
            storeSetting(localStorageKey, JSON.stringify(state));
        },
        setLayerSettings: (state: LayerState, action: PayloadAction<LayerSettingMap>) => {
            state.settings = action.payload;
            storeSetting(localStorageKey, JSON.stringify(state));
        },
        setBaseLayer: (state: LayerState, action: PayloadAction<BaseLayer>) => {
            state.selectedBaseLayer = action.payload;

            const newSetting: LayerSettingMap = { ...state.settings };

            Object.entries(newSetting).forEach(([layer, setting]) => {
                if (!setting.isBaseLayer) {
                    return;
                }

                (newSetting[layer as keyof LayerSettingMap] as LayerSettings) = { ...setting, visible: layer === action.payload };
            });

            state.settings = newSetting;

            storeSetting(localStorageKey, JSON.stringify(state));
        },
    },
});

export const { setLayerSetting, setBaseLayer } = mapLayerSlice.actions;

export const setMapLayerSettings =
    (settings: LayerSettingMap): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(mapLayerSlice.actions.setLayerSettings(settings));
    };

/*
 * This allows us to use the same selector for all layers, instead of creating a separate selector
 * or doing some type checking in code. For example:
 *  useSelector(selectLayerSetting(MapLayer.ACTIVITY)) is of type ActivityLayerSetting, while:
 *  useSelector(selectLayerSetting(MapLayer.CATCH)) is of type CatchLayerSetting
 */
export const selectMapLayerSetting =
    <T extends keyof LayerSettingMap>(layer: T) =>
    (state: RootState): LayerSettingMap[T] => {
        return state.mapLayer.settings[layer];
    };

export const selectMapLayerSettings = (state: RootState): LayerSettingMap => state.mapLayer.settings;
export const selectMapBaseLayer = (state: RootState): string => state.mapLayer.selectedBaseLayer;

export default mapLayerSlice.reducer;
