import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Typography, Modal, Grid, Box, Paper } from '@mui/material';
import { capitalize, createDefaultGear } from '@oma-kala-shared/core/logic';
import { Gear, GearType, GearVisibilityType } from '@oma-kala-shared/core/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ContentPage } from 'app/components/layout';
import { selectGearTypes, selectUserGear } from 'app/state/gear/gearSlice';
import { fetchUserGear } from 'app/state/thunks/fetchUserGear';
import { appTheme } from 'app/theme';

import { getGearTypeIcon } from './../../logic/GearService';
import { useSyncToken } from '../../hooks/useSyncToken';
import { fetchGearTypes } from './../../state/thunks/fetchGearTypes';
import GearItem from './GearItem';

export const GearPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gears: Gear[] = useSelector(selectUserGear);
    const gearTypes: GearType[] = useSelector(selectGearTypes);
    const [syncGearTypesToken, isLoadingGearTypes] = useSyncToken({ onSuccess: () => {}, onError: () => {} });
    const [syncGearsToken, isLoadingGears] = useSyncToken({ onSuccess: () => {}, onError: () => {} });
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        dispatch(fetchGearTypes(syncGearTypesToken));
        dispatch(fetchUserGear(syncGearsToken));
    }, []);

    const handleAddNewGear = () => {
        setOpenModal(true);
    };

    const handleCancelAddNewGear = () => {
        setOpenModal(false);
    };

    const handleSelectNewGear = (gearType: GearType) => {
        const gear: Gear = createDefaultGear(gearType, t(`gear.gearType.${gearType.name}.defaultGearName`));
        navigate('/gear/newGear', { state: { gear: gear, gearType: gearType, isNewGear: true } });
    };

    return (
        <ContentPage title={t('gear.titleMyGear')}>
            <Box sx={button}>
                <Button sx={button} onClick={handleAddNewGear}>
                    <AddIcon fontSize={'small'} />
                    {t('gear.addNewGear')}
                </Button>
            </Box>
            {isLoadingGears && (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                    <CircularProgress />
                </div>
            )}

            {!isLoadingGears && gears.length === 0 && <Typography>{t('gear.gearsNotFound')}</Typography>}
            {!isLoadingGears && (
                <div>
                    {gears.map((gear: Gear, idx) => {
                        if (gear.gearVisibilityType === GearVisibilityType.DELETED) {
                            return null;
                        }
                        return <GearItem key={idx} gear={gear} gearType={gearTypes.find((gt: GearType) => gt.id === gear.idGearType)!} />;
                    })}
                </div>
            )}
            <Modal open={openModal} onClose={handleCancelAddNewGear}>
                <Box sx={styleBox} component="section">
                    <Grid container spacing={2} style={{ marginBottom: 30 }} sx={{}}>
                        {gearTypes.map((gearType: GearType, index) => {
                            return (
                                <Grid item xs={12} md={6} key={index}>
                                    <Paper sx={paper}>
                                        <Button sx={gearItem} onClick={() => handleSelectNewGear(gearType)}>
                                            <img
                                                style={{
                                                    width: '100%',
                                                    maxWidth: 60,
                                                    objectFit: 'contain',
                                                }}
                                                src={getGearTypeIcon(gearType.name)}
                                            />
                                            <Typography>{capitalize(t(`gear.gearType.${gearType.name}.name`))}</Typography>
                                        </Button>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box sx={button}>
                        <Button onClick={handleCancelAddNewGear}>{t('common.cancel')}</Button>
                    </Box>
                </Box>
            </Modal>
        </ContentPage>
    );
};

const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: '80vh',
    backgroundColor: '#fff',
    boxShadow: 24,
    padding: 4,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
};

const button = {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
        backgroundColor: appTheme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(0,83,166,0.75)',
        },
    },
};
const paper = { margin: '10px' };
const gearItem = {
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '4px',
    textTransform: 'none',
};
