import { Feature, MapBrowserEvent, View } from 'ol';
import Map from 'ol/Map';
import { Coordinate } from 'ol/coordinate';
import { Circle, Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import BaseMapHandler from './BaseMapHandler';

/**
 * Map layers and interactions used in declaration views to set the catch location/geometry
 */
export class DeclarationMapHandler extends BaseMapHandler {
    clickListener!: (event: MapBrowserEvent<UIEvent>) => void;
    circleFeatures!: Feature<Circle>[];
    imageLayer!: VectorLayer<VectorSource<Circle>>;
    previousCenter: Coordinate | undefined;
    previousZoom: number | undefined;
    onLocationSet: (point: Point) => void;
    canMarkPoint?: boolean;

    /**
     * Creates a new WebService inteded for use in mobile clients
     * @param {Map} map reference to a OpenLayers map
     * @param {void} onLocationSet Returns coordinate when user clicks the map. Used to set the catch location/geometry
     * @param {boolean} canMarkPoint True if allow to mark point by click, otherwise False
     */
    constructor(
        protected map: Map,
        onLocationSet: (point: Point) => void,
        canMarkPoint?: boolean
    ) {
        super(map);
        this.onLocationSet = onLocationSet;
        this.canMarkPoint = canMarkPoint === undefined ? true : canMarkPoint === true;
        this.init();
    }

    /**
     *
     */
    init() {
        // Set up the map functions. Everything set here should be destroyed in the destroy function
        this.circleFeatures = [];
        this.circleFeatures.push(new Feature(new Circle([0, 0], 3200)));
        const circleStyle = new Style({
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.25)', // Transparent blue fill
            }),
            stroke: new Stroke({
                color: '#1D38A0',
                width: 5,
            }),
        });
        this.circleFeatures[0].setStyle(circleStyle);

        this.circleFeatures.push(new Feature(new Circle([0, 0], 400)));
        const circleInnerStyle = new Style({
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.25)', // Transparent blue fill
            }),
            stroke: new Stroke({
                color: '#1D38A0',
                width: 4,
            }),
        });
        this.circleFeatures[1].setStyle(circleInnerStyle);

        const vectorSource = new VectorSource({
            features: this.circleFeatures,
        });

        this.imageLayer = new VectorLayer({
            source: vectorSource,
        });
        this.map.addLayer(this.imageLayer);

        this.clickListener = (event: MapBrowserEvent<UIEvent>) => {
            if (this.canMarkPoint) {
                this.setLocation(event.coordinate, false);
            }
            this.onLocationSet(new Point(event.coordinate));
        };

        this.map.on('click', this.clickListener);
    }

    /**
     * Destroys layers and other listeners used by this resource
     */
    destroy() {
        super.destroy();
        this.map.un('click', this.clickListener);
        this.map.removeLayer(this.imageLayer);
    }

    /**
     *
     * @param {Feature} feature
     * @param {boolean} newVisibility
     *
     * @return {void}
     */
    private showHideFeature(feature: Feature, newVisibility: boolean) {
        // if the visibility we are trying to set is the same, do nothing
        if (feature.get('visible') === newVisibility) {
            return;
        }
        // otherwise, change the style of the feature,
        // and save the old style as a property so we can restore it later
        if (newVisibility === true) {
            const featureSavedStyle = feature.get('savedStyle');

            if (featureSavedStyle) {
                feature.setStyle(featureSavedStyle);
            }
        } else {
            feature.set('savedStyle', feature.getStyle());
            feature.setStyle(new Style()); // this is actually hiding the feature
        }
        feature.set('visible', newVisibility);
    }

    /**
     * Centers the map view to 'coordinate' and zooms to 1:200000. Smooth transition if 'animateToLocation' is true
     * @param {Coordinate} coordinate
     * @param {boolean} animateToLocation - smooth transition to coordinate, at zoom level
     * @param {boolean} showCircle - Need to show circle feature or not
     * @param {number} scaleValue - value for scale use to calculate zoom level
     */
    setLocation(coordinate: Coordinate, animateToLocation = true, showCircle = true, scaleValue = this.defaultScale): void {
        this.circleFeatures.forEach(feature => {
            feature.getGeometry()?.setCenter(coordinate);
            this.showHideFeature(feature, showCircle);
        });

        if (animateToLocation) {
            this.animateToCoordinate(coordinate, this.getZoom(scaleValue)!);
        }
    }
}

export default DeclarationMapHandler;
