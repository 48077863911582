import moment from 'moment';

import { DateDiffResult } from 'app/model';

/**
 * Formats the specified date to a full date format.
 * Example date: 09-09-2021 18:50
 * @param {Date} date The date to format
 * @return {string} The formatted date
 */
export function defaultFormatFullDate(date: Date): string {
    const formattedDate = defaultFormatDate(date);
    const timestamp = defaultFormatTimestamp(date);
    return formattedDate + ' ' + timestamp;
}

/**
 * Formats the specified date to the default app format.
 * Example date: Thu, Sep 9, 2021
 * @param {Date} date The date to format
 * @return {string} The formatted date
 */
export function defaultFormatDate(date: Date): string {
    return moment(date).format('DD-MM-YYYY');
}

/**
 * Formats the specified date to the default timestamp format.
 * Example timestamp: 18:50
 * @param {Date} date The date to format
 * @return {string} The formatted date
 */
export function defaultFormatTimestamp(date: Date): string {
    return moment(date).format('HH:mm');
}

/**
 * Formats the specified date diff to the default timestamp format, with hours and minutes.
 * Example timestamp: 03:05:08
 * @param {DateDiffResult} diff
 * @return {string} res
 */
export function defaultFormatDateDiff(diff: DateDiffResult): string {
    const hours: string = diff.hours >= 10 ? '' + diff.hours : '0' + diff.hours;
    const minutes: string = diff.minutes >= 10 ? '' + diff.minutes : '0' + diff.minutes;
    const seconds: string = diff.seconds >= 10 ? '' + diff.seconds : '0' + diff.seconds;
    return moment(hours + minutes + seconds, 'HHmmss').format('HH:mm:ss');
}

/**
 * Compares two dates and returns an integer comparison result.
 * - if (dateA < dateB) return < 0;
 * - if (dateA === dateB) return 0;
 * - if (dateA > dateB) return > 0;
 * @param {Date} dateA The first date
 * @param {Date} dateB The second date
 * @return {number} The comparison result
 */
export function compareDates(dateA: Date, dateB: Date): number {
    if (!dateA) {
        return !dateB ? 0 : -1;
    }
    return dateA.getTime() - dateB.getTime();
}

/**
 * Calculates the hour, minute and second difference between the two specified dates.
 * If dateA > dateB, the returned result will be negative.
 * @param {Date} dateA
 * @param {Date} dateB
 * @return {number} The hour(s), minute(s) and second(s) difference between the dates
 */
export function dateDiff(dateA: Date, dateB: Date): DateDiffResult {
    const diff: number = moment(dateB).diff(moment(dateA));
    const dur: moment.Duration = moment.duration(diff);
    const hours = dur.asHours();
    return {
        hours: hours < 0 ? Math.ceil(hours) : Math.floor(hours),
        minutes: dur.minutes(),
        seconds: dur.seconds(),
    };
}
