import { Button, CircularProgress, Grid, Paper, TextField, Typography, Link } from '@mui/material';
import { FeedbackRequest, UserData } from '@oma-kala-shared/core/model';
import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import { ContentPage } from 'app/components/layout';
import { useSyncToken } from 'app/hooks';
import { setErrorMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { sendFeedback } from 'app/state/thunks';
import { selectLoggedInUserData } from 'app/state/user/userSlice';

/**
 * Create a default feedback request for the given user data
 * @param {UserData | null} userData
 * @return {FeedbackRequest}
 */
function createDefaultState(userData: UserData | null): FeedbackRequest {
    return {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        message: '',
    };
}

/**
 * @return {JSX.Element}
 */
export function FeedbackPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userData: UserData | null = useSelector(selectLoggedInUserData);

    const [state, setState] = useState<FeedbackRequest>(createDefaultState(userData));
    const [captcha, setCaptcha] = useState<string>('');
    const [canSend, setCanSend] = useState<boolean>(false);
    const lukeMail = 'omakala@luke.fi';

    const [syncToken, loading] = useSyncToken({
        onSuccess: () => {
            dispatch(setSuccessMessage(t('feedback.successMessage')));
            navigate(-1); // Navigate to previous page
        },
        onError: message => {
            dispatch(setErrorMessage(t('feedback.errorMessage', { message: message })));
        },
    });

    const onValueChanged = (key: keyof FeedbackRequest) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState(prev => ({ ...prev, [key]: e.target.value }));
    };

    const onCaptchaValueChanged = () => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCaptcha(e.target.value);
    };

    useEffect(() => {
        if (loading) {
            setCanSend(false);
        } else {
            setCanSend(userData || validateCaptcha(captcha, false));
        }
    }, [loading, captcha]);

    const handleSubmit = () => {
        dispatch(sendFeedback(syncToken, state));
    };

    useLayoutEffect(() => {
        if (!userData) {
            loadCaptchaEnginge(6);
        }
    }, []);

    return (
        <ContentPage title={t('feedback.header')} description={t('feedback.helpText')}>
            <Paper elevation={3} sx={{ padding: '24px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="firstName"
                            variant="standard"
                            fullWidth
                            autoComplete="given-name"
                            label={t('common.firstName')}
                            value={state.firstName}
                            onChange={onValueChanged('firstName')}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="lastName"
                            variant="standard"
                            fullWidth
                            autoComplete="family-name"
                            label={t('common.lastName')}
                            value={state.lastName}
                            onChange={onValueChanged('lastName')}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="email"
                            variant="standard"
                            fullWidth
                            autoComplete="email"
                            label={t('common.email')}
                            value={state.email}
                            onChange={onValueChanged('email')}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="feedback"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            label={t('feedback.feedback')}
                            value={state.message}
                            onChange={onValueChanged('message')}
                            disabled={loading}
                        />
                    </Grid>
                </Grid>

                {userData ? (
                    <></>
                ) : (
                    <Grid container spacing={3} sx={{ marginTop: 3 }}>
                        <Grid item xs={12}>
                            <div style={{ marginBottom: '24px' }}>{t('feedback.captchaMessage')}</div>
                            <LoadCanvasTemplateNoReload />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="captcha"
                                variant="standard"
                                autoComplete="email"
                                label={t('feedback.captcha')}
                                onChange={onCaptchaValueChanged()}
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={3} justifyContent="right" sx={{ marginTop: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={!canSend}>
                            {t('common.send')}
                            {loading && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Typography sx={{ marginTop: 2 }}>
                {t('feedback.email')}
                <Link href={`mailto:${lukeMail}`}>{lukeMail}</Link>
            </Typography>
        </ContentPage>
    );
}
