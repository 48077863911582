import { TokenData } from '@oma-kala-shared/core/model';
import i18n from 'i18next';

import { storeToken } from 'app/logic/LoginService';
import { RequestStatus } from 'app/model';
import { RoutePath } from 'app/model/Route';
import { AppThunk, RootState } from 'app/state/store';
import { setSyncState } from 'app/state/sync/syncSlice';

import { setErrorMessage } from '../message/messageSlice';
import { setTempTokenData } from '../user/userSlice';
import { initializeStore } from './initializeStore';
import WebService from '@oma-kala-shared/core/logic/WebService';
import { setUnreadMessageCount } from '../news/newsSlice';
import { createWebService } from './thunkHelpers';

/**
 * Login the user
 * @param {string} syncToken
 * @param {TokenData} tokenData
 * @return {AppThunk}
 */

export const loginUserWithSvk =
    (syncToken: string, tokenData: TokenData): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const registrationIdentificationUrl =
            process.env.REACT_APP_BASE_URL + `/v1/identification?RelayState=${window.location.origin + RoutePath.REGISTRATION}`;
        console.log('Loging in with SVK account');

        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const oid = tokenData.body.oid;

        if (oid) {
            dispatch(initializeStore(tokenData));
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
            const service: WebService = await createWebService(dispatch, getState);
            const language = i18n.language;
            const getStatsResponse = await service.getUnreadNewsStats(language);

            if (getStatsResponse.successful) {
                const unreadCount = getStatsResponse.content;
                dispatch(setUnreadMessageCount(unreadCount));
            }
        } else {
            // keep the temporary token in state, later used in registration request
            dispatch(setTempTokenData(tokenData));
            storeToken(tokenData);
            // Continue with registration for external user
            // confirmbox
            dispatch(setErrorMessage(i18n.t('registrationPrompt.text')));
            setTimeout(() => {
                window.location.href = registrationIdentificationUrl;
            }, 5000);
        }
    };
