import { Box, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectIsLoggedIn } from 'app/state/user/userSlice';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface ContentPageProps {
    children: ReactNode;
    title?: string;
    description?: string;
    width?: number | string;
    padding?: string | number;
    extraHeader?: ReactJSXElement;
}

export const ContentPage = ({ children, title, description, width, padding, extraHeader }: ContentPageProps) => {
    const { ContentPageWrapper, PageWrapper } = contentPageStyledComponents;
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);

    return (
        <ContentPageWrapper width={width} sx={{ padding: padding ? `${padding} !important` : '50px' }}>
            <Box pb={4}>
                {title && (
                    <Typography variant="h4" sx={{ marginBottom: '5px' }}>
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography sx={{ marginTop: '12px' }} variant="body2">
                        {description}
                    </Typography>
                )}
            </Box>
            {extraHeader}
            {children}
        </ContentPageWrapper>
    );
};

const contentPageStyledComponents = {
    ContentPageWrapper: styled(Box)(({ width = '35vw' }: { width?: number | string }) => ({
        // padding: 50,
        width: width,
        overflow: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D9D9D9',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#B3B3B3',
        },
    })),
    PageWrapper: styled(Box)(() => ({
        padding: 50,
    })),
};
