import React, { useEffect, useState } from 'react';

import { Box, CardMedia, Grid, styled, Typography } from '@mui/material';
import { CMSItem, CMSTag, LocalizedTags, OmaKalaLanguageCode } from '@oma-kala-shared/core/model';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ContentPage } from 'app/components/layout';
import { filterCmsItemsByTag, getDefaultTagsets, getLocalizedLinkTag } from 'app/logic/CmsService';
import { selectCmsElements } from 'app/state/cms/cmsSlice';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

import LandingWrapper from '../landing/LandingWrapper';
import { getStoreButtonImage, getStoreQrCodeImage, StoreVariantEnum } from 'app/logic/ImageService';
import { useStoreButtonImage } from 'app/hooks/useStoreButtonImage';

const AboutWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});
const AboutLandingWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(30, 41, 59, 0) 0%, #1E293B 100%)',
});

const MainWrapper = styled('div')({
    maxWidth: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    '@media only screen and (min-width: 900px)': {
        textAlign: 'left',
        maxWidth: '1200px',
        justifyItems: 'center',
    },
});

const SplashMessage = styled('div')(({ color }) => ({
    color: color,
    marginBottom: '40px',
    fontSize: '18px',
    fontWeight: 400,
    '@media only screen and (min-width: 768px)': {
        marginBottom: '30px',
    },
}));

/**
 * @return {JSX.Element}
 */
export function AboutPage() {
    const { t, i18n } = useTranslation();
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);

    const [appStoreButtonImage, playStoreButtonImage] = useStoreButtonImage();

    // Dynamic content from CMS to home page
    const elements: CMSItem[] = useSelector(selectCmsElements);
    const tagset: LocalizedTags = getDefaultTagsets();
    const cmsItem: CMSItem | undefined = first(filterCmsItemsByTag(elements, getLocalizedLinkTag(i18n.language, tagset)));
    if (isLoggedIn) {
        return (
            <ContentPage width={'45vw'} title={t('about.pageTitle')}>
                <AboutWrapper>
                    <MainWrapper>
                        <SplashMessage color={'black'}>
                            {cmsItem && (
                                <Typography sx={{ marginBottom: 6 }} fontSize={{ lg: 22, sm: 22 }}>
                                    <span dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }}></span>
                                </Typography>
                            )}
                            <Grid container spacing={3}>
                                <Grid item md={12} lg={6} sx={{ textAlign: 'center' }}>
                                    <Box sx={cardWrapper}>
                                        <Typography sx={{ marginBottom: 6 }} variant="body1">
                                            {t('about.downloadAndroid')}
                                        </Typography>
                                        <Box sx={{ m: 10 }}>
                                            <CardMedia
                                                component="img"
                                                image={getStoreQrCodeImage(StoreVariantEnum.PLAY_STORE)}
                                                alt={t('common.mobile.appStore.qrCodeAltText')}
                                            />
                                        </Box>
                                        <a href="https://play.google.com/store/apps/details?id=fi.luke.omakala">
                                            <img
                                                style={{ height: '50px' }}
                                                src={playStoreButtonImage}
                                                alt={t('common.mobile.appStore.linkAltText')}
                                            />
                                        </a>
                                    </Box>
                                </Grid>
                                <Grid item md={12} lg={6} sx={{ textAlign: 'center' }}>
                                    <Box sx={cardWrapper}>
                                        <Typography sx={{ marginBottom: 6 }} variant="body1">
                                            {t('about.downloadIos')}
                                        </Typography>
                                        <Box sx={{ m: 10 }}>
                                            <CardMedia
                                                component="img"
                                                image={getStoreQrCodeImage(StoreVariantEnum.APP_STORE)}
                                                alt={t('common.mobile.playStore.linkAltText')}
                                            />
                                        </Box>
                                        <a href="https://apps.apple.com/app/omakala/id1587329867">
                                            <img
                                                style={{ height: '50px' }}
                                                src={appStoreButtonImage}
                                                alt={t('common.mobile.playStore.linkAltText')}
                                            />
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </SplashMessage>
                    </MainWrapper>
                </AboutWrapper>
            </ContentPage>
        );
    } else {
        return (
            <LandingWrapper
                visibleLoginButton={true}
                visibleBackButton={true}
                main={
                    <AboutLandingWrapper>
                        <MainWrapper>
                            <SplashMessage color={'white'}>
                                {cmsItem && (
                                    <Typography sx={{ marginBottom: 6 }} fontSize={{ lg: 22, sm: 22 }}>
                                        <span dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }}></span>
                                    </Typography>
                                )}
                                <Grid container spacing={3}>
                                    <Grid item md={12} lg={6} sx={{ textAlign: 'center' }}>
                                        <Box sx={cardWrapper}>
                                            <Typography sx={{ marginBottom: 6 }} variant="body1">
                                                {t('about.downloadAndroid')}
                                            </Typography>
                                            <Box sx={{ m: 10 }}>
                                                <CardMedia
                                                    component="img"
                                                    image={getStoreQrCodeImage(StoreVariantEnum.PLAY_STORE)}
                                                    alt={t('common.mobile.appStore.qrCodeAltText')}
                                                />
                                            </Box>
                                            <a href="https://play.google.com/store/apps/details?id=fi.luke.omakala">
                                                <img
                                                    style={{ height: '50px' }}
                                                    src={playStoreButtonImage}
                                                    alt={t('common.mobile.appStore.linkAltText')}
                                                />
                                            </a>
                                        </Box>
                                    </Grid>
                                    <Grid item md={12} lg={6} sx={{ textAlign: 'center' }}>
                                        <Box sx={cardWrapper}>
                                            <Typography sx={{ marginBottom: 6 }} variant="body1">
                                                {t('about.downloadIos')}
                                            </Typography>
                                            <Box sx={{ m: 10 }}>
                                                <CardMedia
                                                    component="img"
                                                    image={getStoreQrCodeImage(StoreVariantEnum.APP_STORE)}
                                                    alt={t('common.mobile.playStore.linkAltText')}
                                                />
                                            </Box>
                                            <a href="https://apps.apple.com/app/omakala/id1587329867">
                                                <img
                                                    style={{ height: '50px' }}
                                                    src={appStoreButtonImage}
                                                    alt={t('common.mobile.playStore.linkAltText')}
                                                />
                                            </a>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </SplashMessage>
                        </MainWrapper>
                    </AboutLandingWrapper>
                }
            />
        );
    }
}

const cardWrapper = {
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
};
