import { ContentResponse, TokenData } from '@oma-kala-shared/core/model';

import { clearUserRoles, storeUserRoles } from 'app/logic/UserSettingService';
import { UserRole } from 'app/model/UserRole';
import { AppThunk, RootState } from 'app/state/store';
import { selectTokenData, setUserRoles } from 'app/state/user/userSlice';

import { createWebService } from './thunkHelpers';

/**
 * Fetches user roles for the currently logged in user.
 * If no user is logged in, does nothing
 * @return {AppThunk}
 */
export const fetchUserRoles = (): AppThunk => async (dispatch: any, getState: () => RootState) => {
    console.debug('fetching user roles');

    const rootState: RootState = getState();
    const tokenData: TokenData | null = selectTokenData(rootState);

    if (!tokenData?.body?.oid) {
        return;
    }
    const oid = tokenData.body.oid;
    if (!oid) return;

    const webService = await createWebService(dispatch, getState);

    const resp: ContentResponse<UserRole[]> = await webService.getUserRoles(oid);

    if (resp.successful) {
        await storeUserRoles(resp.content);
        dispatch(setUserRoles(resp.content));
    } else {
        await clearUserRoles();
        dispatch(setUserRoles(null));
    }

    console.debug('finished fetching user roles');
};
