/* eslint-disable prettier/prettier */
import {
    DeclarationDetails,
    DeclarationSearchResult,
} from '@oma-kala-shared/core/model';
import React, {  } from 'react';
import { Box, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type DeclarationListItemRenderer = (catchDeclaration: DeclarationDetails) => React.ReactElement;

type DeclarationListProps = {
    itemRenderer: DeclarationListItemRenderer;
    searchResults: DeclarationSearchResult | null;
};

const DeclarationList = ({ itemRenderer, searchResults }: DeclarationListProps) => {

    const { t } = useTranslation();

    return (
        <Box>
            {searchResults && (
                <Typography sx={{ fontSize: '22px' }}>
                    {searchResults.totalElements > 0
                        ? `${searchResults.totalElements} ${t('catchDeclaration.resultsFound')}`
                        : t('catchDeclaration.noResultsFound')}
                </Typography>
            )}
            <List>
                {searchResults?.declarations.map((catchDeclaration: DeclarationDetails) => {
                    return <React.Fragment  key={catchDeclaration.id}>{itemRenderer(catchDeclaration)}</React.Fragment>
                })}
            </List>
        </Box>
    );
};

export default DeclarationList;
