import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Message, MessageType } from 'app/model';
import { RootState } from 'app/state/store';

export interface MessageDetails {
    text: string;
    timeout: number;
}

export interface MessageState {
    currentMessage: Message | null;
    currentAlert: Message | null;
}

export const initialState: MessageState = {
    currentMessage: null,
    currentAlert: null,
};
const DEFAULT_TIMEOUT = 6000;
const messageSlice = createSlice({
    name: 'message',
    initialState: initialState,
    reducers: {
        setErrorMessage: (state: MessageState, action: PayloadAction<string>) => {
            state.currentMessage = { type: MessageType.ERROR, text: action.payload, timeout: DEFAULT_TIMEOUT };
        },
        setErrorMessageWithTimeout: (state: MessageState, action: PayloadAction<MessageDetails>) => {
            state.currentMessage = { type: MessageType.ERROR, text: action.payload.text, timeout: action.payload.timeout };
        },
        setInfoMessage: (state: MessageState, action: PayloadAction<string>) => {
            state.currentMessage = { type: MessageType.INFO, text: action.payload, timeout: DEFAULT_TIMEOUT };
        },
        setSuccessMessage: (state: MessageState, action: PayloadAction<string>) => {
            state.currentMessage = { type: MessageType.SUCCESS, text: action.payload, timeout: DEFAULT_TIMEOUT };
        },
        clearCurrentMessage: (state: MessageState) => {
            state.currentMessage = null;
        },
        setAlert: (state: MessageState, action: PayloadAction<Message>) => {
            state.currentAlert = action.payload;
        },
        clearCurrentAlert: (state: MessageState) => {
            state.currentAlert = null;
        },
    },
});

export const {
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
    clearCurrentMessage,
    setAlert,
    clearCurrentAlert,
    setErrorMessageWithTimeout,
} = messageSlice.actions;

export const selectCurrentMessage = (state: RootState): Message | null => state.message.currentMessage;
export const selectCurrentAlert = (state: RootState): Message | null => state.message.currentAlert;

export default messageSlice.reducer;
