import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';

/**
 * @param {any} props
 * @return {JSX.Element}
 */
export function LukeDeleteDeclarationNoticeModal(props: { onClose: () => void }) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={true}
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { width: '600px', maxWidth: '600px', padding: '16px 8px' } }}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        >
            <DialogContent>
                <p style={{ whiteSpace: 'pre-line' }}>{t('catchDeclaration.notifications.delete.lukeEmployeeNotice')}</p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'start', padding: '8px 18px' }}>
                <Button variant="outlined" onClick={props.onClose} sx={{ width: '100px', marginRight: '8px' }}>
                    {t('common.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
