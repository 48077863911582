/**
 * This component will show only the consent can be shown in web
 * so we need to handle in difference way.
 *
 * We need to make extra request to get only user consent in web
 * and we should compare it
 */

import { Grid, LinearProgress, Switch } from '@mui/material';
import { getConsentDisplayText } from '@oma-kala-shared/core/logic';
import { ConsentData, OmaKalaLanguageCode } from '@oma-kala-shared/core/model';
import useWebConsents from 'app/hooks/useWebConsents';
import i18n from 'i18n';
import React, { useState } from 'react';

export type ActionButtonRendererParams = {
    latestUserConsents: ConsentData[];
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    saveUserConsents: (newUserConsents: ConsentData[]) => void;
};

type UserConsentsProps = {
    actionButtonRenderer?: (params: ActionButtonRendererParams) => React.ReactNode;
    onValueChanged?: () => {};
};

const UserConsents = ({ actionButtonRenderer }: UserConsentsProps) => {
    const { webConsents, userConsents, saveUserConsents } = useWebConsents();
    const [userConsentState, setUserConsentState] = useState<ConsentData[]>(userConsents ?? []);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newState = userConsentState.map(consent => {
            return {
                ...consent,
                accepted: consent.consentId === e.target.id ? e.target.checked : consent.accepted,
            };
        });

        setUserConsentState(newState);
        setIsDirty(true);
    };

    const isChecked = (consentId: string) => {
        const existUserConsent = userConsentState.find((userConsent: ConsentData) => userConsent.consentId === consentId);

        if (!existUserConsent) {
            return false;
        }

        return existUserConsent.accepted;
    };

    if (!webConsents) {
        return <LinearProgress />;
    }

    return (
        <>
            {webConsents?.map((consent: ConsentData) => {
                return (
                    <Grid key={consent.consentId} container spacing={2} sx={{ marginTop: '4px' }}>
                        <Grid item xs={8}>
                            <span>{getConsentDisplayText(consent, i18n.language as OmaKalaLanguageCode)}</span>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            sx={{
                                marginLeft: '16px',
                            }}
                        >
                            <Switch checked={isChecked(consent.consentId)} id={consent.consentId} onChange={onChange} />
                        </Grid>
                    </Grid>
                );
            })}

            {actionButtonRenderer && actionButtonRenderer({ latestUserConsents: userConsentState, isDirty, setIsDirty, saveUserConsents })}
        </>
    );
};

export default UserConsents;
