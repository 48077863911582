import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeclarationCatchDetails } from '@oma-kala-shared/core/model';

type DeleteDeclarationCatchModalProps = {
    catchDetail: DeclarationCatchDetails;
    onSubmit: (deletingCatchDetail: DeclarationCatchDetails) => void;
    onClose: () => void;
};

/**
 * @param {any} props
 * @return {JSX.Element}
 */
export function DeleteDeclarationCatchModal({ catchDetail, onSubmit, onClose }: DeleteDeclarationCatchModalProps) {
    const { t } = useTranslation();
    const [input, setInput] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const onChange = (val: string) => {
        setInput(val);
        setError(val?.length < 4);
    };

    const proceed = () => {
        onSubmit({ ...catchDetail, deletionReason: input });
    };

    return (
        <Dialog
            open={true}
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { width: '600px', maxWidth: '600px', padding: '16px 8px' } }}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        >
            <DialogTitle>
                <Typography>{t('catchDeclaration.deletionReason')}</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={input}
                    label={t('catchDeclaration.reason')}
                    fullWidth
                    variant="standard"
                    required
                    onChange={e => onChange(e.target.value)}
                    error={error}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'start', padding: '8px 18px' }}>
                <Button
                    variant="contained"
                    onClick={() => proceed()}
                    sx={{ width: '100px', marginRight: '8px' }}
                    disabled={error || !input}
                >
                    {t('common.delete')}
                </Button>
                <Button variant="outlined" onClick={onClose} sx={{ width: '100px', marginRight: '8px' }}>
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
