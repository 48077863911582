import {
    CatchDeclarationPropertyUnitConfiguration,
    DeclarationFisherRequirementPropertyDetails,
    DeclarationSpeciesPropertyRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    UnitConfiguration,
} from '@oma-kala-shared/core/model';
import { DYNAMIC_FIELD_TYPE, FieldValueType, GridField, GridValue } from 'app/components/common/dynamic-grid';
import { t } from 'i18next';
import moment from 'moment';

/**
 * This is Helper class to handle the catch declaration requirements
 */
class CatchDeclarationRequirementHelper {
    // Get validation rule by requirement's type
    static getValidationRuleForType = (type: string): string => {
        switch (type.toLowerCase()) {
            case DYNAMIC_FIELD_TYPE.STRING:
                return 'required|min:1';
            case DYNAMIC_FIELD_TYPE.FLOAT:
                return 'numeric|min:0';
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return 'numeric|min:0';
            default:
                return 'required';
        }
    };

    /**
     * Get default value for requirement by type.
     * @param {string} type Type of requirement
     * @return {FieldValueType}
     */
    static getDefaultValueForType = (type: string): FieldValueType => {
        switch (type.toLowerCase()) {
            case DYNAMIC_FIELD_TYPE.STRING:
                return '';
            case DYNAMIC_FIELD_TYPE.FLOAT:
                return 0.0;
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return 0;
            case DYNAMIC_FIELD_TYPE.DATE:
                return moment();
            default:
                return '';
        }
    };

    /**
     * Generate grid field from property requirements
     *
     * @param {DeclarationPropertyRequirements[]} requirements
     * @param {Function} fieldFiltering
     * @param {Record<string, FieldValueType>} predefinedDefaultValues
     * @param {Function} visibilityHandler
     * @param {Function} fieldTypeHandler
     * @param {Record<string, FieldValueType>} predefinedValidationRules
     * @param {Function} fieldPostHandler
     * @param {Function} fieldLabelHandler
     * @param {string[]} disabledFields
     *
     *
     * @return {[Record<string, GridField>, GridValue]}
     */
    static generateFieldsFromRequirements = (
        requirements: DeclarationPropertyRequirements[],
        fieldFiltering?: (requirement: DeclarationPropertyRequirements) => boolean,
        predefinedDefaultValues?: Record<string, FieldValueType>,
        visibilityHandler?: (requirement: DeclarationPropertyRequirements) => boolean,
        fieldTypeHandler?: (requirement: DeclarationPropertyRequirements) => DYNAMIC_FIELD_TYPE,
        predefinedValidationRules?: Record<string, string>,
        fieldPostHandler?: (field: GridField) => GridField,
        fieldLabelHandler?: (requirement: DeclarationPropertyRequirements) => string,
        disabledFields?: string[]
    ): [Record<string, GridField>, GridValue] => {
        const fields: Record<string, GridField> = {};
        const fieldValues: GridValue = {};

        // @ts-expect-error Ignore the error 'Not all code path return at all'
        requirements.forEach(requiredProperty => {
            if (fieldFiltering && fieldFiltering(requiredProperty) === false) {
                return false;
            }

            let value = CatchDeclarationRequirementHelper.getDefaultValueForType(requiredProperty.dataType);
            if (predefinedDefaultValues && predefinedDefaultValues[requiredProperty.name]) {
                value = predefinedDefaultValues[requiredProperty.name];
            }

            fieldValues[requiredProperty.name] = value;
            if (visibilityHandler && visibilityHandler(requiredProperty) === false) {
                return false;
            }

            let fieldType: string = requiredProperty.dataType;

            if (fieldTypeHandler) {
                fieldType = fieldTypeHandler(requiredProperty) as string;
            }

            let validationRule: string = this.getValidationRuleForType(requiredProperty.dataType);

            if (predefinedValidationRules && predefinedValidationRules[requiredProperty.name]) {
                validationRule = predefinedValidationRules[requiredProperty.name];
            }

            let label: string = requiredProperty.name;

            if (fieldLabelHandler) {
                label = fieldLabelHandler(requiredProperty);
            }

            let disabled = false;

            if (disabledFields && disabledFields.includes(requiredProperty.name)) {
                disabled = true;
            }

            let field: GridField = {
                id: requiredProperty.id as number,
                type: fieldType as DYNAMIC_FIELD_TYPE,
                containerSize: 6,
                inputSize: '98%',
                name: requiredProperty.name,
                label,
                required: true,
                unit: requiredProperty.unit,
                disabled: disabled,
                value,
                validationRule,
            };

            /**
             * For some property we need to use different unit configuration
             * For more information check LOK-4557
             */
            if (requiredProperty.unit !== null) {
                field.unitConfiguration = CatchDeclarationPropertyUnitConfiguration[
                    requiredProperty.name as DeclarationSpeciesRequirementPropertyName
                ] as UnitConfiguration;
            }

            if (fieldPostHandler) {
                field = fieldPostHandler(field);
            }

            fields[requiredProperty.name] = field;
        });

        return [fields, fieldValues];
    };
}
export default CatchDeclarationRequirementHelper;

export type DeclarationPropertyRequirements = DeclarationSpeciesPropertyRequirementDetails | DeclarationFisherRequirementPropertyDetails;
