import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getCurrentLanguage } from 'app/logic/LanguageService';
import enTranslations from 'resources/translations/en';
import fiTranslations from 'resources/translations/fi';
import seTranslations from 'resources/translations/se';
import svTranslations from 'resources/translations/sv';

i18next.use(initReactI18next).init({
    resources: {
        en: enTranslations,
        sv: svTranslations,
        fi: fiTranslations,
        se: seTranslations,
    },
    lng: getCurrentLanguage(),
    fallbackLng: 'en',

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.',
});

// usage inside a component:
// import { useTranslation } from "react-i18next";
// const { t } = useTranslation();
// t("some.translation.path")
export default i18next;
