import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material';
import { UserData } from '@oma-kala-shared/core/model';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserData } from 'app/state/user/userSlice';

interface OpenDialog {
    isOpen: boolean;
    onClose: () => void;
}

/**
 * @param {OpenDialog} props
 * @return {JSX.Element}
 */
export function CookieDialog(props: OpenDialog) {
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie] = useCookies(['userId']);
    const { i18n, t } = useTranslation();
    const userData: UserData | null = useSelector(selectUserData);

    const createCookie = () => {
        const date = new Date();
        date.setHours(8760);

        setCookie('userId', userData?.id, {
            path: '/',
            maxAge: 3153600000,
        });
    };

    const handleSaveSettings = () => {
        createCookie();
        props.onClose();
    };

    const setPrivacyLink = (): string => {
        if (i18n.language === 'en' || i18n.language === 'sv') {
            return 'https://www.luke.fi/en/luke-3/data-protection-and-privacy/';
        } else {
            return 'https://www.luke.fi/tietosuoja-ja-yksityisyys_/';
        }
    };

    return (
        <Dialog open={props.isOpen}>
            <DialogTitle>{t('cookieDialog.cookieConfiguration')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('cookieDialog.description')}{' '}
                    <a target="_blank" rel="noreferrer" href={setPrivacyLink()}>
                        {t('cookieDialog.privacyLink')}
                    </a>
                </DialogContentText>
                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
                <FormGroup>
                    <FormControlLabel control={<Switch disabled defaultChecked />} label={t('cookieDialog.essentialCookie') as string} />
                    <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
                </FormGroup>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleSaveSettings()} color="primary" variant="contained">
                    {t('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
