import { Box } from '@mui/material';
import React from 'react';

type MapControlButtonContainerProps = {
    children: React.ReactNode;
};

const MapControlButtonContainer = ({ children }: MapControlButtonContainerProps) => {
    return (
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1, margin: 2 }}>
            {children}
        </Box>
    );
};

export default MapControlButtonContainer;
