import { parseJwtToken } from '@oma-kala-shared/core/logic';
import { ContentResponse, TempResponse, TokenBody, TokenData } from '@oma-kala-shared/core/model';

import BrowserWebService from 'app/logic/BrowserWebService';

import { AppThunk } from '../store';
import { initializeStore } from './initializeStore';

/**
 * Tries to get a permanent token from temporary token for external idp users
 * If successful, initializeStore and login the user
 * @param {string} tempToken - Temporary token from external idp login
 * @param {onError} onError - error handler
 * @return {AppThunk}
 */

export const getPermanentTokenAndInitialize =
    (tempToken: TokenData, onError: (errorMsg: string) => void, onSuccess: () => void): AppThunk =>
    async (dispatch: any) => {
        if (!tempToken?.access_token) {
            // todo return error
            onError('common.unexpectedError');
            return;
        }

        const token = 'Bearer ' + tempToken.access_token;
        const service = new BrowserWebService(token);
        const response: ContentResponse<TempResponse> = await service.getAccessTokenFromTempToken(token);

        console.log(response);
        if (!response.successful || !response.content?.access_token) {
            onError('common.unexpectedError');
            return;
        }

        const decodedToken: TokenBody = parseJwtToken(response.content.access_token);
        const tokenData: TokenData = {
            access_token: response.content.access_token,
            refresh_token: response.content.refresh_token,
            token_type: 'Bearer',
            expires_in: '',
            expires_on: '',
            body: decodedToken,
        };
        dispatch(initializeStore(tokenData));
        onSuccess();
    };
