import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, SpatialRequirementRequest, SpatialRequirementResponse } from '@oma-kala-shared/core/model';

import { RequestStatus } from 'app/model';

import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';
import { setErrorMessage } from '../message/messageSlice';
import { t } from 'i18next';

/**
 * Updates the specified gear
 * @param {string} syncToken
 * @param {SpatialRequirementRequest} request
 * @param {string} langCode

 * @return {AppThunk}
 */
export const getCatchSpatialRequirements =
    (syncToken: string, request: SpatialRequirementRequest, langCode: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));
        const service: WebService = await createWebService(dispatch, getState);

        const response: ContentResponse<SpatialRequirementResponse> = await service.getCatchSpatialRequirements(request, langCode);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: response.content,
                    },
                })
            );
        } else {
            console.warn('getting generalized location failed with message:', response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: t('common.unexpectedError'),
                    },
                })
            );
            dispatch(setErrorMessage(t('common.unexpectedError')));
        }
    };
