import { DeclarationSearchRequest, DeclarationSearchResult, UserDeclarationSearchRequest } from '@oma-kala-shared/core/model';
import { getUserData } from 'app/logic/UserSettingService';
import { searchDeclarationsForUser, searchDeclarations } from 'app/state/thunks/searchDeclarations';
import { selectUserIsAdmin } from 'app/state/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSyncToken } from './useSyncToken';

export const useSearchDeclaration = (onSuccess: (results: DeclarationSearchResult) => void, onError: () => void) => {
    const dispatch = useDispatch();
    const userIsAdmin: boolean = useSelector(selectUserIsAdmin);
    const userData = getUserData();

    const [syncToken, loading] = useSyncToken({
        onSuccess,
        onError,
    });

    const performSearch = (params: DeclarationSearchRequest | UserDeclarationSearchRequest | null) => {
        if (!userData || !userData.id) {
            return;
        }

        if (userIsAdmin) {
            dispatch(searchDeclarations(syncToken, params as DeclarationSearchRequest));
        } else {
            dispatch(searchDeclarationsForUser(syncToken, userData.id, params as UserDeclarationSearchRequest));
        }
    };

    return { searchDeclarations: performSearch, loading };
};
