import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface PhoneNumberProps {
    label?: string;
    name: string;
    number: string;
    handleChange: (phoneNumber: string, countryCode: string) => void;
    countryCode: any;
    height?: string;
    specialLabel?: string;
}
const PhoneNumberInput = ({ label, name, number, countryCode, handleChange, height, specialLabel }: PhoneNumberProps) => {
    const [fullPhoneNumber, setFullPhoneNumber] = useState('');

    useEffect(() => {
        if (countryCode + number === fullPhoneNumber) return;
        setFullPhoneNumber((countryCode ?? '') + (number ?? ''));
    }, [number, countryCode]);

    const onFullNumberChange = (value: string, newCountryCode: string) => {
        setFullPhoneNumber(value);
        let newPhoneNumber = '';
        if (value.startsWith(newCountryCode)) {
            newPhoneNumber = value.slice(newCountryCode.length);
        }
        handleChange(newPhoneNumber, newCountryCode);
    };

    return (
        <>
            <Typography variant="caption">{label}</Typography>
            <PhoneInput
                specialLabel={specialLabel}
                country={'fi'}
                value={fullPhoneNumber}
                onChange={(value, data: any) => onFullNumberChange(value, data.dialCode)}
                inputProps={{
                    name: name,
                    label: label,
                }}
                inputStyle={{ width: '100%', height: height }}
            />
        </>
    );
};

export default PhoneNumberInput;
