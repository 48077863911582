import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

import { ProfileSection } from './ProfilePage';

interface ProfileSectionWrapperProps {
    section: ProfileSection;
    activeSection: ProfileSection | null;
    handleSectionToggle: (section: ProfileSection) => void;
    title: string;
    description: string;
    children: JSX.Element;
}

export const ProfileSectionWrapper = ({
    section,
    activeSection,
    handleSectionToggle,
    title,
    description,
    children,
}: ProfileSectionWrapperProps) => {
    const isOpen = section === activeSection;
    return (
        <Box>
            <Box display={'flex'}>
                <Box flex={1}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'}>
                        {title}
                    </Typography>
                    <Typography mb={2} variant={'body2'}>
                        {description}
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => handleSectionToggle(section)}>{isOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
                </Box>
            </Box>
            {isOpen && children}
        </Box>
    );
};
