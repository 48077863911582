import { isExternalIdentityProviderToken, refreshExternalIdentityToken } from '@oma-kala-shared/core/logic';
import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, TokenData } from '@oma-kala-shared/core/model';

import BrowserWebService from 'app/logic/BrowserWebService';
import { checkRefreshTokenValidity, refreshToken, shouldRefreshToken } from 'app/logic/LoginService';
import { RootState } from 'app/state/store';
import { selectTokenData, setTokenData } from 'app/state/user/userSlice';

/**
 * Utility function that creates a WebService using the current token in the application state.
 * @param {any} dispatch
 * @param {RootState} getState
 */
export async function createWebService(dispatch: any, getState: () => RootState): Promise<BrowserWebService> {
    const rootState: RootState = getState();
    let token: TokenData | null = selectTokenData(rootState);
    const service: WebService = new BrowserWebService();
    if (token && !checkRefreshTokenValidity(token)) {
        dispatch(setTokenData(null));
        // initializeLocalStore(dispatch, getState);
        console.debug('token has expired - setting token as null');
        token = null;
    } else if (token && shouldRefreshToken(token)) {
        const isExternalIdpToken = isExternalIdentityProviderToken(token);
        console.debug('refreshing access token');
        let updatedToken: ContentResponse<TokenData>;
        if (isExternalIdpToken) {
            updatedToken = await refreshExternalIdentityToken(token.refresh_token, service);
        } else {
            updatedToken = await refreshToken(token.refresh_token);
        }

        if (updatedToken.successful) {
            token = updatedToken.content;
            dispatch(setTokenData(updatedToken.content));
        } else {
            console.debug('refresh token request failed unexpectedly', updatedToken.message);
            // if some thunk refreshed the token at the same time, make sure we use that token instead
            token = selectTokenData(getState());
        }
    }

    return new BrowserWebService(token?.access_token);
}
