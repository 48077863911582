export interface Message {
    type: MessageType;
    text: string;
    timeout?: number;
    title?: string;
    extraContent?: MessageExtraContent[];
}

/* eslint-disable no-unused-vars */
export enum MessageType {
    INFO,
    ERROR,
    SUCCESS,
}

export interface MessageExtraContent {
    type: MessageExtraContentType;
    label: string;
    change: (val: any) => void;
}

export enum MessageExtraContentType {
    Checkbox,
}
