import { PASSWORD_MIN_LENGTH } from '@oma-kala-shared/core/logic';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

import { ExtendedPasswordValidationResult } from 'app/model/ExtendedPasswordValidationResult';

/**
 * It takes a phone number and an object of errors, and if the phone number is invalid, it adds an
 * error to the errors object and returns false.
 * @param {string} phone - The phone number to validate.
 * @param {string} phoneCountryCode - The country Code to validate.
 * @param {any} errors - This is an object that will contain the errors.
 * @return {boolean} re a boolean value.
 */
export function validatePhoneNumber(phone: string, phoneCountryCode: any) {
    let result = true;
    const phoneObject = parsePhoneNumber(phone, phoneCountryCode.toUpperCase());
    if (!phoneObject || !isValidPhoneNumber(phone)) {
        result = false;
    }
    if (phone === '') {
        result = true;
    }
    return result;
}

/**
 * It checks if the email is valid and if it is not, it adds an error message to the errors object.
 * @param {string} email - The email address to validate
 * @param {any} errors - any -&gt; this is the object that will hold the errors.
 * @return {boolean} The result of the validation.
 */
export function validateEmail(email: string) {
    let result = true;

    if (!email) {
        result = false;
    } else {
        const regex =
            /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = regex.test(String(email).toLowerCase());
    }
    return result;
}

/**
 * Validates password
 * @param {string} password - The email address to validate
 * @param {string} passwordConfirmation - any -&gt; this is the object that will hold the errors.
 * @return {boolean} The result of the validation.
 */
export function validatePassword(password: string, passwordConfirmation: string) {
    const isLengthValid = password.length >= PASSWORD_MIN_LENGTH;
    const includesLowercase = password.toUpperCase() !== password;
    const includesUppercase = password.toLowerCase() !== password;
    const includesInteger = /\d/.test(password);
    const includesSpecialCharacter = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password);
    const isPasswordConfirmationValid = password === passwordConfirmation && password.length > 0;
    const validationResult: ExtendedPasswordValidationResult = {
        isValid:
            isLengthValid &&
            includesLowercase &&
            includesUppercase &&
            includesInteger &&
            includesSpecialCharacter &&
            isPasswordConfirmationValid,
        length: isLengthValid,
        lowercase: includesLowercase,
        uppercase: includesUppercase,
        digit: includesInteger,
        special: includesSpecialCharacter,
        passwordConfirmation: isPasswordConfirmationValid,
    };
    return validationResult;
}
