import { createGearDirtyInfo, replaceLocalEntityWithDirty } from '@oma-kala-shared/core/logic';
import { Gear, GearDirtyInfo, LocalStateGear } from '@oma-kala-shared/core/model';

import { selectUserGearLocalState, setUserGear } from '../gear/gearSlice';
import { AppThunk, RootState } from '../store';

// import { syncUserGear } from './syncUserGear';

/**
 * Update a gear instance in the local state and trigger a sync with the back-end
 * @param {Gear} newVersion New version of the gear
 * @return {AppThunk} Dispatchable thunk
 */
export const updateUserGear =
    (newVersion: Gear): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const gearState: LocalStateGear[] = selectUserGearLocalState(getState());
        const oldVersion: Gear | undefined = gearState.find(e => e.entity.id === newVersion.id)?.entity;
        if (!oldVersion) {
            console.log(`Gear with ID ${newVersion.id} doesn't exist in state.`);
            return;
        }
        const dirtyInfo: GearDirtyInfo = createGearDirtyInfo(oldVersion, newVersion);
        const newGearState: LocalStateGear[] = replaceLocalEntityWithDirty(gearState, oldVersion, newVersion, dirtyInfo);
        dispatch(setUserGear(newGearState));
        // dispatch(syncUserGear());
    };
