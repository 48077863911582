import WebService from '@oma-kala-shared/core/logic/WebService';
import { SimpleResponse, UpdateUserRequest } from '@oma-kala-shared/core/model';

import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';
import { setSyncState } from 'app/state/sync/syncSlice';

import { selectTempTokenData } from '../user/userSlice';
import { fetchUserData } from './';
import { createWebService } from './thunkHelpers';

/**
 * Updates the specified user with the provided data
 * @param {string} syncToken
 * @param {string} userId
 * @param {UpdateUserRequest} request
 * @return {AppThunk}
 */
export const updateUser =
    (syncToken: string, userId: string, request: UpdateUserRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const webService: WebService = await createWebService(dispatch, getState);

        const response: SimpleResponse = await webService.updateUser(userId, request);

        if (response.successful) {
            console.debug('login completed successfully');
            dispatch(fetchUserData());
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
        } else {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Failed, message: response.message as string } }));
        }
    };
