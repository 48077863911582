import WebService from '@oma-kala-shared/core/logic/WebService';
import { SimpleResponse, UserData } from '@oma-kala-shared/core/model';
import i18n from 'i18next';

import { RequestStatus } from 'app/model';
import { setErrorMessage } from 'app/state/message/messageSlice';

import { selectUserData } from '../../state/user/userSlice';
import { setSuccessMessage } from '../message/messageSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { deleteUserGear } from './deleteUserGear';
import { createWebService } from './thunkHelpers';

/**
 * Delete the specified gear
 * @param {string} syncToken
 * @param {string} gearId
 * @return {Promise<ContentResponse<PoiResponse>>}
 */
export const deleteGear =
    (syncToken: string, gearId: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('deleting gear', gearId);
        const userData: UserData | null = selectUserData(getState());

        if (!userData) {
            console.warn('User data is not set - cannot delete gear');
            return;
        }

        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const response: SimpleResponse = await service.deleteUserGear(userData.id, gearId);

        if (response.successful) {
            dispatch(deleteUserGear(gearId));
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        message: i18n.t('gear.gearDeleted'),
                    },
                })
            );
            dispatch(setSuccessMessage(i18n.t('gear.gearDeleted')));
        } else {
            console.debug('delete gear failed with message:' + response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: i18n.t('gear.callFailed'),
                    },
                })
            );
            dispatch(setErrorMessage(i18n.t('gear.callFailed')));
        }
    };
