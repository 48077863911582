import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Message, MessageType } from 'app/model';
import { useDispatch, useSelector } from 'react-redux';
import { clearCurrentAlert, selectCurrentAlert } from 'app/state/message/messageSlice';
import { Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { appTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';
import { minWidth } from '@mui/system';

export const getIconColor = (type: MessageType) => {
    let color;
    switch (type) {
        case MessageType.ERROR:
            color = appTheme.palette.error.main;
            break;
        case MessageType.SUCCESS:
            color = appTheme.palette.success.main;
            break;
        case MessageType.INFO:
            color = appTheme.palette.info.main;
            break;
        default:
            color = appTheme.palette.info.main;
    }
    return color;
};

/**
 * @param {any} props
 * @return {JSX.Element}
 */
export function AlertDialog() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentAlert: Message | null = useSelector(selectCurrentAlert);

    // Remember previous message so we can retain the style while the message is closing.
    // Else, the snackbar will flicker with 'info' color when closing an 'error' message.

    const handleClose = () => dispatch(clearCurrentAlert());

    currentAlert?.type;
    if (!currentAlert) {
        return <></>;
    }

    return (
        <Dialog open={true} aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex' }}>
                    <ErrorIcon sx={{ color: getIconColor(currentAlert.type) }} />
                    <Typography sx={{ paddingLeft: '11px', fontWeight: 'bold' }}>
                        {t(`common.dialog.messageType.${currentAlert.type ?? currentAlert.title}`)}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent sx={{ paddingLeft: '60px', paddingBottom: 2 }}>
                <div style={{ display: 'flex', gap: 10 }}>
                    <DialogContentText id="alert-dialog-description">{currentAlert.text}</DialogContentText>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('common.close')}</Button>
            </DialogActions>
        </Dialog>
    );
}
