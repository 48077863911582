import { Action, AnyAction, combineReducers, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';

import cmsSliceReducer, { CMSState, initialState as initialCmsState } from './cms/cmsSlice';
import declarationSliceReducer, { initialState as initialDeclarationState, DeclarationState } from './declaration/declarationSlice';
import gearSliceReducer, { GearState, initialState as initialGearState } from './gear/gearSlice';
import messageSliceReducer, { initialState as initialMessageState, MessageState } from './message/messageSlice';
import newsSliceReducer, { NewsState, initialState as initialNewsState } from './news/newsSlice';
import notificationReducer, { initialState as initialNotificationState, NotificationState } from './notification/notificationSlice';
import syncSliceReducer, { initialState as initialSyncState, SyncState } from './sync/syncSlice';
import userSliceReducer, { initialState as initialUserState, UserState } from './user/userSlice';
import mapLayerSliceReducer, { initialState as initialMapLayerState, LayerState } from './mapLayer/mapLayerSlice';

export const combinedReducers: Reducer<RootState, AnyAction> = combineReducers({
    cms: cmsSliceReducer,
    message: messageSliceReducer,
    sync: syncSliceReducer,
    user: userSliceReducer,
    gear: gearSliceReducer,
    notification: notificationReducer,
    news: newsSliceReducer,
    declaration: declarationSliceReducer,
    mapLayer: mapLayerSliceReducer,
});

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const store = configureStore({
    reducer: combinedReducers,
});

export const defaultState: RootState = {
    cms: initialCmsState,
    message: initialMessageState,
    sync: initialSyncState,
    user: initialUserState,
    gear: initialGearState,
    notification: initialNotificationState,
    news: initialNewsState,
    declaration: initialDeclarationState,
    mapLayer: initialMapLayerState,
};

export interface RootState {
    cms: CMSState;
    message: MessageState;
    sync: SyncState;
    user: UserState;
    gear: GearState;
    notification: NotificationState;
    news: NewsState;
    declaration: DeclarationState;
    mapLayer: LayerState;
}
