import { CMSTag } from '@oma-kala-shared/core/model';
import React from 'react';

import { CmsElementPage } from 'app/components/layout';

/**
 * @return {JSX.Element}
 */
export function PrivacyPolicyPage() {
    return (
        <CmsElementPage
            tagset={{
                finnish: CMSTag.ELEMENT_PRIVACY_POLICY_FI,
                swedish: CMSTag.ELEMENT_PRIVACY_POLICY_SV,
                english: CMSTag.ELEMENT_PRIVACY_POLICY_EN,
                sami: CMSTag.ELEMENT_PRIVACY_POLICY_SE,
            }}
        />
    );
}
