import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { display } from '@mui/system';

export interface GeneralizedLocationInputFieldProps {
    loadingLocation: boolean;
    fieldLabel: string;
    fieldValue: string;
    valid: boolean;
    readonly: boolean;
    onValueChange?: (val: string) => void;
}

/**
 * @param {GeneralizedLocationInputFieldProps} param0
 * @return {JSX.Element}
 */
export default function GeneralizedLocationInputField({
    loadingLocation,
    fieldLabel,
    fieldValue,
    valid,
    readonly,
    onValueChange,
}: GeneralizedLocationInputFieldProps) {
    return (
        <Box sx={{ position: 'relative', display: 'flex', flex: 1 }}>
            <TextField
                label={fieldLabel}
                sx={{ m: 1, width: '100%', background: readonly ? '#EBEDF0' : '' }}
                type={'text'}
                InputProps={{
                    readOnly: readonly,
                }}
                value={fieldValue}
                onChange={e => {
                    if (onValueChange) {
                        onValueChange(e.target.value);
                    }
                }}
            />
            {loadingLocation ? (
                <CircularProgress sx={loadingIcon} />
            ) : (
                <>
                    {valid ? (
                        <CheckIcon sx={{ ...iconStyle, color: '#26DE70' }} />
                    ) : (
                        <PriorityHighIcon sx={{ ...iconStyle, color: '#E47070' }} />
                    )}
                </>
            )}
        </Box>
    );
}

const iconStyle = {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translate(0, -50%)',
    fontSize: '32px',
};

const loadingIcon = {
    ...iconStyle,
    top: '20%',
    right: 8,
};
