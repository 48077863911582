import { mapGearToCreateRequest } from '@oma-kala-shared/core/logic';
import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, Gear, GearHeader, UserData } from '@oma-kala-shared/core/model';
import i18n from 'i18next';

import { RequestStatus } from 'app/model';
import { setSuccessMessage } from 'app/state/message/messageSlice';

import { setErrorMessage } from '../message/messageSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { selectUserData } from '../user/userSlice';
import { addUserGear } from './addUserGear';
import { createWebService } from './thunkHelpers';

/**
 * Save the specified gear for the currently logged in user
 * @param {string} syncToken
 * @param {Gear} gear
 * @return {AppThunk}
 */
export const saveGear =
    (syncToken: string, gear: Gear): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const userData: UserData | null = selectUserData(getState());

        if (!userData || !userData.id) {
            console.warn('user data is not set - cannot save gear. User data received: ', userData);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: i18n.t('gear.callFailed'),
                    },
                })
            );
            dispatch(setErrorMessage(i18n.t('gear.callFailed')));
            return;
        }

        gear.userId = userData.id;

        const service: WebService = await createWebService(dispatch, getState);
        const request = mapGearToCreateRequest(gear);
        const response: ContentResponse<GearHeader> = await service.addUserGear(gear.userId, request);

        if (response.successful) {
            const updatedGear: Gear = {
                ...response.content,
                properties: gear.properties,
            };
            dispatch(addUserGear(updatedGear));
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        message: i18n.t('gear.gearSaved'),
                    },
                })
            );
            dispatch(setSuccessMessage(i18n.t('gear.gearSaved')));
        } else {
            console.warn('save gear request failed with message:', response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: i18n.t('gear.callFailed'),
                    },
                })
            );
            dispatch(setErrorMessage(i18n.t('gear.callFailed')));
        }
    };
