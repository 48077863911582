import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { CMSItem, LocalizedTags } from '@oma-kala-shared/core/model';
import { first } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { filterCmsItemsByTag, getLocalizedLinkTag } from 'app/logic/CmsService';
import { getLanguageFromParameter } from 'app/logic/LanguageService';
import LandingWrapper from 'app/pages/landing/LandingWrapper';
import { selectCmsElements } from 'app/state/cms/cmsSlice';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

import { ContentPage } from './';

interface Props {
    tagset: LocalizedTags;
}

/**
 * @param {Props} param0
 * @return {JSX.Element}
 */
export function CmsElementPage({ tagset }: Props) {
    const elements: CMSItem[] = useSelector(selectCmsElements);
    const language = getLanguageFromParameter();
    const cmsItem: CMSItem | undefined = first(filterCmsItemsByTag(elements, getLocalizedLinkTag(language, tagset)));
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    return (
        <>
            {cmsItem && (
                <div>
                    {isLoggedIn ? (
                        <ContentPage title={cmsItem.title.rendered}>
                            <div dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }} />
                        </ContentPage>
                    ) : (
                        <LandingWrapper
                            visibleLoginButton={true}
                            visibleBackButton={true}
                            main={
                                <ContentWrapper>
                                    <Typography variant="h4">{cmsItem.title.rendered}</Typography>
                                    <CmsWrapper dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }} />
                                </ContentWrapper>
                            }
                        />
                    )}
                </div>
            )}
        </>
    );
}

const ContentWrapper = styled('div')({
    height: '100%',
    color: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(30, 41, 59, 0) 0%, #1E293B 100%)',
});
const CmsWrapper = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 80,
    minHeight: '60vh',
});
