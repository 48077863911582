import WebService from '@oma-kala-shared/core/logic/WebService';
import { CMSItem, CMSRequest, ContentResponse, GetCmsPostsRequest } from '@oma-kala-shared/core/model';

import { getCmsPageCategory } from 'app/logic/CmsService';
import { RequestStatus } from 'app/model';
import { setCmsPages } from 'app/state/cms/cmsSlice';
import { AppThunk, RootState } from 'app/state/store';

import { createWebService } from './thunkHelpers';

/**
 * Fetch elements from our CMS for the given language and load them into the application state.
 * @param {string} language
 * @param {string} slug
 * @return {AppThunk}
 */
export const fetchCmsPages =
    (language: string, slug: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const request: GetCmsPostsRequest = {
            fields: 'id,title,content,slug,tags',
            categories: getCmsPageCategory(language).toString(),
            slug: slug,
            itemPerPage: 99,
        };
        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<CMSItem[]> = await service.fetchCmsPosts(request);

        dispatch(setCmsPages({ status: RequestStatus.Loading }));

        if (response.successful) {
            dispatch(setCmsPages({ status: RequestStatus.Successful, resource: response.content }));
        } else {
            dispatch(setCmsPages({ status: RequestStatus.Failed, message: response.message as string }));
        }
    };
