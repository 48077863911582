import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Box,
    Collapse,
    IconButton,
    SortDirection,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultFormatFullDate } from 'app/logic/DateService';
import { PushNotification } from 'app/model/Notification';

export interface PushNotificationTableProps {
    notifications: PushNotification[];
}

export const PushNotificationTable = ({ notifications }: PushNotificationTableProps) => {
    const [sortedNotifications, setSortedNotifications] = useState<PushNotification[][]>([]);
    const [sortOrder, setSortOrder] = useState<SortDirection>('desc');
    const { t } = useTranslation();

    useEffect(() => {
        const sorted = notifications
            .map((n: PushNotification) => {
                return {
                    ...n,
                    // we do this in order to be able to group notifications based on the same minute they were
                    // created, since there is currently no possible way to do this otherwise.
                    // Different news articles for different languages have different IDs, so we will end up
                    // in situations where the same push notification is redirecting to different
                    // IDs, depending on the language.
                    // If/when we implement some "real" grouping of notificiations, this can be removed.
                    created: moment(n.created).seconds(0).milliseconds(0).toISOString(),
                };
            })
            .sort((a: PushNotification, b: PushNotification) => {
                return moment(a.created).diff(moment(b.created)) * (sortOrder === 'asc' ? 1 : -1);
            });
        // create groups of notifications created during the same minute,
        // i.e. a "naive" grouping
        const groups = _.groupBy(sorted, (n: PushNotification) => n.created);
        setSortedNotifications(Object.values(groups));
    }, [sortOrder, notifications]);

    return (
        <TableContainer sx={{ height: '100%' }}>
            <Table size="small" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell sortDirection={sortOrder}>
                            <TableSortLabel
                                active={true}
                                direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                                onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                                {t('admin.notifications.browse.columns.createdAt')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">{t('admin.notifications.browse.columns.redirectView')}</TableCell>
                        <TableCell align="right">{t('admin.notifications.browse.columns.redirectId')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedNotifications.map((row: PushNotification[]) => (
                        <NotificationRow key={row[0].created} rows={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface NotificationRowProps {
    rows: PushNotification[];
}

const NotificationRow = ({ rows }: NotificationRowProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{defaultFormatFullDate(new Date(rows[0].created))}</TableCell>
                <TableCell align="right">
                    {!!rows[0].redirectView && t(`admin.notifications.redirectView.${rows[0].redirectView}`)}
                </TableCell>
                <TableCell align="right">{rows[0].redirectId}</TableCell>
                <TableCell align="right">
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto">
                        <Box sx={{ margin: 1 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('admin.notifications.browse.columns.status')}</TableCell>
                                        <TableCell>{t('admin.notifications.browse.columns.language')}</TableCell>
                                        <TableCell>{t('admin.notifications.browse.columns.title')}</TableCell>
                                        <TableCell>{t('admin.notifications.browse.columns.message')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row: PushNotification) => {
                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell>{t(`admin.notifications.status.${row.status}`)}</TableCell>
                                                <TableCell>{t(`common.languages.${row.language.toLowerCase()}`)}</TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>{row.message}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
