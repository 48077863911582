import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress, DialogTitle, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSyncToken } from 'app/hooks';
import { deleteDeclaration } from 'app/state/thunks/deleteDeclaration';

/**
 * @param {any} props
 * @return {JSX.Element}
 */
export function DeleteDeclarationModal(props: { declarationId: string; onSuccess: (id: string) => void; onClose: () => void }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [input, setInput] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const dispatch = useDispatch();

    const [syncToken, isLoading] = useSyncToken({
        onSuccess: () => {
            props.onSuccess(props.declarationId);
            props.onClose();
        },
        onError: () => {},
    });

    const onChange = (val: string) => {
        setInput(val);
        setError(val?.trim().length < 5);
    };

    const proceed = () => {
        dispatch(deleteDeclaration(syncToken, props.declarationId, input));
    };

    return (
        <Dialog
            open={true}
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { width: '600px', maxWidth: '600px', padding: '16px 8px' } }}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        >
            <DialogTitle>
                <Typography>{t('catchDeclaration.deletionReason')}</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={input}
                    label={t('catchDeclaration.reason')}
                    fullWidth
                    variant="standard"
                    required
                    onChange={e => onChange(e.target.value)}
                    error={error}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'start', padding: '8px 18px' }}>
                <Button
                    variant="contained"
                    onClick={() => proceed()}
                    sx={{ width: '100px', marginRight: '8px' }}
                    disabled={error || !input || isLoading}
                >
                    {t('common.delete')} &nbsp;
                    {isLoading && <CircularProgress size={16} />}
                </Button>
                <Button variant="outlined" onClick={props.onClose} sx={{ width: '100px', marginRight: '8px' }}>
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
