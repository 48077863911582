import { ThemeProvider } from '@emotion/react';
import { responsiveFontSizes } from '@mui/material';
import { TokenData, UserData } from '@oma-kala-shared/core/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { MessageSnackbar } from './components/display';
import { ProtectedRoute } from './components/helpers/ProtectedRoute';
import { NavigationWrapper } from './components/layout/NavigationWrapper';
import { getToken } from './logic/LoginService';
import { getRoutes } from './logic/Routes';
import { UserRole } from './model';
import { LoginPage } from './pages';
import * as Pages from './pages';
import LandingPage from './pages/landing/LandingPage';
import { fetchCmsElements, fetchCmsLinks, fetchCmsMessages, initializeStore } from './state/thunks';
import { selectIsLoggedIn, selectLoggedInUserData, selectUserRoles } from './state/user/userSlice';
import { appTheme } from './theme';
import { AlertDialog } from './pages/home/AlertDialog';
import history from './history';
import CustomRouter from './components/common/CustomRouter';

export const App = (): JSX.Element => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const userRoles: UserRole[] | null = useSelector(selectUserRoles);
    const userData: UserData | null = useSelector(selectLoggedInUserData);
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const [mapUrlSet, setMapUrlSet] = useState<boolean>(false);

    useEffect(() => {
        let mapBaseUrl = process.env.REACT_APP_BASE_URL!;
        if (mapBaseUrl.includes('proxy')) {
            mapBaseUrl = 'https://dev-oma-kala.westeurope.cloudapp.azure.com';
        }
        // @oma-kala/map uses meta tag to determine which map server base url to use
        if (!mapUrlSet) {
            const metaTag = document.createElement('meta');
            metaTag.name = 'omakala-service-base';
            metaTag.content = JSON.stringify({ mapBaseUrl: mapBaseUrl });
            document.getElementsByTagName('head')[0].appendChild(metaTag);
            setMapUrlSet(true);
        }
    }, [mapUrlSet]);

    useEffect(() => {
        const token: TokenData | null = getToken();
        dispatch(initializeStore(token));
    }, []);

    useEffect(() => {
        dispatch(fetchCmsMessages(i18n.language));
        dispatch(fetchCmsElements(i18n.language));
        dispatch(fetchCmsLinks(i18n.language, userData));
    }, [i18n.language, userData]);

    return (
        <ThemeProvider theme={responsiveFontSizes(appTheme)}>
            <AlertDialog />
            <MessageSnackbar />
            <CustomRouter history={history}>
                {!isLoggedIn ? (
                    <Routes>
                        <Route path={'/'} element={<LandingPage />} />
                        <Route path={'/auth'} element={<LoginPage />} />
                        <Route path={'/auth/registration'} element={<Pages.RegistrationPage />} />
                        <Route path={'/news/:newsId'} element={<Pages.NewsItemPage />} />
                        <Route path="/technical-requirements" element={<Pages.TechnicalRequirementsPage />} />
                        <Route path="/terms-and-conditions" element={<Pages.TermsAndConditionsPage />} />
                        <Route path="/privacy-policy" element={<Pages.PrivacyPolicyPage />} />
                        <Route path="/about" element={<Pages.AboutPage />} />
                        <Route path="/faq" element={<Pages.FaqPage />} />
                        <Route path="/resetpassword" element={<Pages.ResetPasswordPage />} />
                    </Routes>
                ) : (
                    <NavigationWrapper>
                        <Routes>
                            {getRoutes().map((route, key) => {
                                const { path, component, allowedRoles, hideForLoggedIn } = route;
                                return (
                                    <Route
                                        key={key}
                                        path={path}
                                        element={
                                            <ProtectedRoute
                                                allowedRoles={allowedRoles}
                                                userRoles={userRoles}
                                                isHiddenForLoggedIn={hideForLoggedIn}
                                            >
                                                {component}
                                            </ProtectedRoute>
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </NavigationWrapper>
                )}
            </CustomRouter>
        </ThemeProvider>
    );
};

export default App;
