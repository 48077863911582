import { ContentResponse } from '@oma-kala-shared/core/model';

import BrowserWebService from 'app/logic/BrowserWebService';
import { RequestStatus } from 'app/model';
import { PushNotification } from 'app/model/Notification';
import { AppThunk, RootState } from 'app/state/store';

import { setNotifications } from '../notification/notificationSlice';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';

/**
 * Fetches push notifications from the backend service.
 *
 * @param {string} syncToken
 * @return {AppThunk}
 */
export const fetchPushNotifications =
    (syncToken: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('fetching push notifications');

        dispatch(
            setSyncState({
                syncToken,
                value: {
                    status: RequestStatus.Loading,
                },
            })
        );

        const webService: BrowserWebService = await createWebService(dispatch, getState);
        const response: ContentResponse<PushNotification[]> = await webService.fetchPushNotifications();

        if (response.successful) {
            dispatch(setNotifications(response.content));
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                    },
                })
            );
        } else {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: response.message as string,
                    },
                })
            );
        }
        console.debug('finished fetching push notifications');
    };
