import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { IconButton, AppBarProps as MuiAppBarProps, Toolbar, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { appTheme } from 'app/theme';
import omakalaLogo from 'resources/images/omakala-logo-with-background.png';
import { LanguageMenu } from 'app/components/common/language/LanguageMenu';
import { RouteName } from 'app/model/Route';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface HeaderProps {
    isOpen: boolean;
    handleDrawerStateChanged: (value: boolean) => void;
}

export const Header = ({ isOpen, handleDrawerStateChanged }: HeaderProps) => {
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
    const { i18n } = useTranslation();

    const { AppBar, LogoLink } = headerStyledComponents;

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    return (
        <AppBar position="fixed" open={isOpen}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerStateChanged(!isOpen)}
                        edge="start"
                        sx={{
                            marginRight: 3,
                        }}
                    >
                        {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                    <LogoLink to={RouteName.HOME}>
                        <img src={omakalaLogo} height="50" alt={'omakala-logo'} />
                        <Box ml={1}>
                            <Typography variant="h6" pb={0.5} lineHeight={1}>
                                Omakala
                            </Typography>
                            <Typography variant="body1" lineHeight={1}>
                                Web
                            </Typography>
                        </Box>
                    </LogoLink>
                </Box>
                <Box display={'flex'}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={handleMenuClick}>
                        <p>{i18n.language.toUpperCase()}</p>
                        <ArrowDropDownIcon />
                    </Box>
                </Box>
                <LanguageMenu anchorEl={menuAnchor} onClose={handleMenuClose} />
            </Toolbar>
        </AppBar>
    );
};

const headerStyledComponents = {
    AppBar: styled(MuiAppBar, {
        shouldForwardProp: prop => prop !== 'open',
    })<AppBarProps>(({ theme }) => ({
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: appTheme.palette.primary.main,
    })),
    LogoLink: styled(Link)({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textDecoration: 'none',
    }),
};
