import { KnownTaxonomyType } from '@oma-kala-shared/core/model';

export const defaultFishIcon: any = require('resources/images/icons/default-fish-icon.png');
export const rodIcon = require('resources/images/icons/rod-icon.png');
export const netIcon = require('resources/images/icons/net-fishing-icon.png');
export const katiskaIcon = require('resources/images/icons/katiska-icon.png');
export const fykeIcon = require('resources/images/icons/fyke-icon.png');
export const spinningRodIcon = require('resources/images/icons/spinning-rod-icon.png');
export const flyRodIcon = require('resources/images/icons/lure-fishing-icon.png');
export const trollingRodIcon = require('resources/images/icons/trolling-rod-icon.png');
export const seineIcon = require('resources/images/icons/seine-icon.png');
export const singleHookAndLineIcon = require('resources/images/icons/single-hook-line-icon.png');
export const springLoadedFishHookIcon = require('resources/images/icons/spring-loaded-hook-icon.png');
export const otherHookGearIcon = require('resources/images/icons/single-hook-line-icon.png');
export const longLineIcon = require('resources/images/icons/longline-icon.png');
export const iceRodIcon = require('resources/images/icons/ice-fishing-icon.png');
export const otherIcon = require('resources/images/icons/rod-icon.png');
export const anglingIcon = require('resources/images/icons/rod-icon.png');
export const trollingIcon = require('resources/images/icons/trolling-icon.png');
export const hookAndLineIcon = require('resources/images/icons/hook-and-line-icon.png');

export const FISH_ICONS: { [key in KnownTaxonomyType]: any } = {
    [KnownTaxonomyType.ALLIS_SHAD]: defaultFishIcon,
    [KnownTaxonomyType.OTHER]: defaultFishIcon,
    [KnownTaxonomyType.ALPINE_BULLHEAD]: require('resources/images/icons/fish/53207.png'),
    [KnownTaxonomyType.ARCTIC_CHAR]: require('resources/images/icons/fish/53137.png'),
    [KnownTaxonomyType.ASP]: require('resources/images/icons/fish/53304.png'),
    [KnownTaxonomyType.ATLANTIC_SALMON]: require('resources/images/icons/fish/53121.png'),
    [KnownTaxonomyType.ATLANTIC_STURGEON]: defaultFishIcon,
    [KnownTaxonomyType.BALTIC_HERRING]: require('resources/images/icons/fish/53102.png'),
    [KnownTaxonomyType.BELICA]: require('resources/images/icons/fish/53305.png'),
    [KnownTaxonomyType.BLACK_GOBY]: require('resources/images/icons/fish/53286.png'),
    [KnownTaxonomyType.BLEAK]: require('resources/images/icons/fish/53159.png'),
    [KnownTaxonomyType.BRILL]: defaultFishIcon,
    [KnownTaxonomyType.BROADNOSED_PIPEFISH]: require('resources/images/icons/fish/53263.png'),
    [KnownTaxonomyType.BROOK_LAMPREY]: require('resources/images/icons/fish/53256.png'),
    [KnownTaxonomyType.BROOK_STICKLEBACK]: require('resources/images/icons/fish/53201.png'),
    [KnownTaxonomyType.BROOK_TROUT]: require('resources/images/icons/fish/52994.png'),
    [KnownTaxonomyType.BROWN_BULLHEAD]: require('resources/images/icons/fish/53181.png'),
    [KnownTaxonomyType.BROWN_TROUT]: require('resources/images/icons/fish/53123.png'),
    [KnownTaxonomyType.BURBOT]: require('resources/images/icons/fish/53186.png'),
    [KnownTaxonomyType.CHINESE_SLEEPER]: defaultFishIcon,
    [KnownTaxonomyType.CHUB]: require('resources/images/icons/fish/53260.png'),
    [KnownTaxonomyType.COD]: defaultFishIcon,
    [KnownTaxonomyType.COMMON_BREAM]: require('resources/images/icons/fish/53162.png'),
    [KnownTaxonomyType.COMMON_CARP]: require('resources/images/icons/fish/52997.png'),
    [KnownTaxonomyType.COMMON_DAB]: defaultFishIcon,
    [KnownTaxonomyType.COMMON_GOBY]: require('resources/images/icons/fish/53320.png'),
    [KnownTaxonomyType.COMMON_NASE]: defaultFishIcon,
    [KnownTaxonomyType.COMMON_RUDD]: require('resources/images/icons/fish/53151.png'),
    [KnownTaxonomyType.COMMON_SEASNAIL]: require('resources/images/icons/fish/53216.png'),
    [KnownTaxonomyType.COMMON_WHITEFISH]: require('resources/images/icons/fish/53132.png'),
    [KnownTaxonomyType.CRUCIAN_CARP]: require('resources/images/icons/fish/53168.png'),
    [KnownTaxonomyType.DACE]: require('resources/images/icons/fish/53146.png'),
    [KnownTaxonomyType.EELPOUT]: require('resources/images/icons/fish/53190.png'),
    [KnownTaxonomyType.EURASIAN_MINNOW]: require('resources/images/icons/fish/53149.png'),
    [KnownTaxonomyType.EUROPEAN_ANCHOVY]: defaultFishIcon,
    [KnownTaxonomyType.EUROPEAN_BITTERLING]: defaultFishIcon,
    [KnownTaxonomyType.EUROPEAN_BULLHEAD]: require('resources/images/icons/fish/53206.png'),
    [KnownTaxonomyType.EUROPEAN_EEL]: require('resources/images/icons/fish/53112.png'),
    [KnownTaxonomyType.EUROPEAN_SEABASS]: defaultFishIcon,
    [KnownTaxonomyType.FIFTEENSPINED_STICKLEBACK]: require('resources/images/icons/fish/53203.png'),
    [KnownTaxonomyType.FLOUNDER]: require('resources/images/icons/fish/53249.png'),
    [KnownTaxonomyType.FOURBEARD_ROCKLING]: defaultFishIcon,
    [KnownTaxonomyType.FOURHORN_SCULPIN]: require('resources/images/icons/fish/53315.png'),
    [KnownTaxonomyType.GARFISH]: require('resources/images/icons/fish/53192.png'),
    [KnownTaxonomyType.GOLDFISH]: defaultFishIcon,
    [KnownTaxonomyType.GRAYLING]: require('resources/images/icons/fish/53141.png'),
    [KnownTaxonomyType.GREAT_SANDEEL]: require('resources/images/icons/fish/53234.png'),
    [KnownTaxonomyType.GUDGEON]: require('resources/images/icons/fish/53157.png'),
    [KnownTaxonomyType.IDE]: require('resources/images/icons/fish/53273.png'),
    [KnownTaxonomyType.LAKE_TROUT]: require('resources/images/icons/fish/52995.png'),
    [KnownTaxonomyType.LAND_LOCKED_ATLANTIC_SALMON]: require('resources/images/icons/fish/53121.png'),
    [KnownTaxonomyType.LESSER_SANDEEL]: require('resources/images/icons/fish/53236.png'),
    [KnownTaxonomyType.LONGSPINED_BULLHEAD]: require('resources/images/icons/fish/53208.png'),
    [KnownTaxonomyType.LUMPSUCKER]: require('resources/images/icons/fish/53264.png'),
    [KnownTaxonomyType.MACKEREL]: defaultFishIcon,
    [KnownTaxonomyType.NINESPINE_STICKLEBACK]: require('resources/images/icons/fish/53200.png'),
    [KnownTaxonomyType.PELED]: require('resources/images/icons/fish/53001.png'),
    [KnownTaxonomyType.PERCH]: require('resources/images/icons/fish/53219.png'),
    [KnownTaxonomyType.PIKE]: require('resources/images/icons/fish/53118.png'),
    [KnownTaxonomyType.PIKE_PERCH]: require('resources/images/icons/fish/53220.png'),
    [KnownTaxonomyType.PINK_SALMON]: require('resources/images/icons/fish/52999.png'),
    [KnownTaxonomyType.PRUSSIAN_CARP]: require('resources/images/icons/fish/52996.png'),
    [KnownTaxonomyType.PUMPKINSEED]: require('resources/images/icons/fish/206832.png'),
    [KnownTaxonomyType.RAINBOW_TROUT]: require('resources/images/icons/fish/52998.png'),
    [KnownTaxonomyType.RIVER_LAMPREY]: require('resources/images/icons/fish/53084.png'),
    [KnownTaxonomyType.ROACH]: require('resources/images/icons/fish/53145.png'),
    [KnownTaxonomyType.ROCK_GUNNEL]: require('resources/images/icons/fish/53265.png'),
    [KnownTaxonomyType.ROUND_GOBY]: require('resources/images/icons/fish/53000.png'),
    [KnownTaxonomyType.RUFFE]: require('resources/images/icons/fish/53317.png'),
    [KnownTaxonomyType.RUSSIAN_STURGEON]: defaultFishIcon,
    [KnownTaxonomyType.SAND_GOBY]: require('resources/images/icons/fish/53238.png'),
    [KnownTaxonomyType.SEA_LAMPREY]: defaultFishIcon,
    [KnownTaxonomyType.SHORTHORN_SCULPIN]: require('resources/images/icons/fish/53210.png'),
    [KnownTaxonomyType.SIBERIAN_STURGEON]: defaultFishIcon,
    [KnownTaxonomyType.SICHEL]: require('resources/images/icons/fish/53166.png'),
    [KnownTaxonomyType.SILVER_BREAM]: require('resources/images/icons/fish/53276.png'),
    [KnownTaxonomyType.SMELT]: require('resources/images/icons/fish/53142.png'),
    [KnownTaxonomyType.SNAKE_BLENNY]: require('resources/images/icons/fish/53319.png'),
    [KnownTaxonomyType.SPINED_LOACH]: require('resources/images/icons/fish/53262.png'),
    [KnownTaxonomyType.SPRAT]: require('resources/images/icons/fish/53105.png'),
    [KnownTaxonomyType.STONE_LOACH]: require('resources/images/icons/fish/53308.png'),
    [KnownTaxonomyType.STRAIGHTNOSE_PIPEFISH]: require('resources/images/icons/fish/53312.png'),
    [KnownTaxonomyType.STURGEON]: defaultFishIcon,
    [KnownTaxonomyType.TENCH]: require('resources/images/icons/fish/53155.png'),
    [KnownTaxonomyType.THREESPINE_STICKLEBACK]: require('resources/images/icons/fish/53198.png'),
    [KnownTaxonomyType.TURBOT]: require('resources/images/icons/fish/53246.png'),
    [KnownTaxonomyType.TWAITE_SHAD]: defaultFishIcon,
    [KnownTaxonomyType.TWO_SPOTTED_GOBY]: require('resources/images/icons/fish/53240.png'),
    [KnownTaxonomyType.VENDACE]: require('resources/images/icons/fish/53300.png'),
    [KnownTaxonomyType.VIMBA_BREAM]: require('resources/images/icons/fish/53165.png'),
    [KnownTaxonomyType.WELS_CATFISH]: defaultFishIcon,
    [KnownTaxonomyType.WHITE_FINNED_GUDGEON]: require('resources/images/icons/fish/206676.png'),
    [KnownTaxonomyType.ZOPE]: require('resources/images/icons/fish/53261.png'),
};
