/* eslint-disable no-unused-vars */
export enum RequestStatus {
    NotInitiated = 0,
    Loading = 1,
    Successful = 2,
    Failed = 3,
}

export type LoadableResource<T = undefined> = NotInitiatedLoadableResource | LoadingResource | LoadedResource<T> | FailedLoadableResource;

export interface NotInitiatedLoadableResource {
    status: RequestStatus.NotInitiated;
}

export interface LoadingResource {
    status: RequestStatus.Loading;
}

export interface LoadedResource<T> {
    status: RequestStatus.Successful;
    resource: T;
    reloading?: boolean;
}

export interface FailedLoadableResource {
    status: RequestStatus.Failed;
    message: string;
}

export type RequestState = NotInitiatedRequestState | LoadingRequestState | SuccessfulRequestState | FailedRequestState;

export interface NotInitiatedRequestState {
    status: RequestStatus.NotInitiated;
}

export interface LoadingRequestState {
    status: RequestStatus.Loading;
}

export interface SuccessfulRequestState {
    status: RequestStatus.Successful;
    message?: string;
    resource?: any;
}

export interface FailedRequestState {
    status: RequestStatus.Failed;
    message: string;
}
