import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

interface CollapseSectionWrapperProps {
    isOpen: boolean;
    handleSectionToggle: (isOpen: boolean) => void;
    title: string;
    children?: JSX.Element;
    background?: string;
}

export const CollapseSectionWrapper = ({ isOpen, handleSectionToggle, title, children, background }: CollapseSectionWrapperProps) => {
    return (
        <Box sx={{ padding: '10px 80px', backgroundColor: background, height: '100%', minHeight: 100, textAlign: 'center' }}>
            <Box display={'flex'} marginTop={'20px'}>
                <Typography variant={'h4'} color={'white'}>
                    {title}
                </Typography>
                <IconButton onClick={() => handleSectionToggle(isOpen)}>
                    {isOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </IconButton>
            </Box>
            {isOpen && children}
        </Box>
    );
};
