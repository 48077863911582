import { Button, Link, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { ContentPage } from 'app/components/layout';
import { selectNewsDetails } from 'app/state/news/newsSlice';
import moment from 'moment';
import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import omakalaLogo from 'resources/images/icons/omakala_logo.png';
import { selectIsLoggedIn } from 'app/state/user/userSlice';
import LandingWrapper from '../landing/LandingWrapper';
import { News } from '@oma-kala-shared/core/model/News';
import BrowserWebService from 'app/logic/BrowserWebService';
import { store } from 'app/state/store';
import { createWebService } from 'app/state/thunks';

/**
 * @return {JSX.Element}
 */
export function NewsItemPage() {
    const { newsId } = useParams();
    const { t } = useTranslation();
    const newsDetails = useSelector(selectNewsDetails);
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [html, setHtml] = useState<string>('');

    const Wrapper = isLoggedIn ? ContentPage : LandingWrapper;
    const extraProps = isLoggedIn ? { width: '100%' } : {};

    useEffect(() => {
        if (html.length > 0 || !newsDetails) {
            return;
        }

        // eslint-disable-next-line require-jsdoc
        async function getHtml(newsDetails: News) {
            const webService: BrowserWebService = await createWebService(store.dispatch, store.getState);

            const response = await webService.getNewsContent(parseInt(newsDetails ? newsDetails.id! : newsId!));

            if (response.successful) {
                setHtml(response.content);
                if (iframeRef.current !== null) {
                    iframeRef.current.onload = () => {
                        const body = iframeRef.current!.contentDocument!.body;
                        const html = iframeRef.current!.contentDocument!.documentElement;

                        const height = Math.max(
                            body.getBoundingClientRect().height,
                            html.getBoundingClientRect().height,
                            html.scrollHeight,
                            body.scrollHeight
                        );

                        // For some reason on dev we need to add 50 px to be able show full height, on local it work fine
                        const minimumAdditionalHeight = 50;
                        iframeRef.current!.style.height = height + minimumAdditionalHeight + 'px';
                    };
                }
            }
        }
        getHtml(newsDetails);
    }, [html]);

    const getNewsContentElement = (newsDetails: News) => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h4" style={{ fontWeight: 700 }}>
                        {newsDetails.title}
                    </Typography>
                    <img src={omakalaLogo} alt="omakala-placeholder" style={{ width: 35, height: 35 }} />
                </div>
                <Typography variant="subtitle1" style={{ color: '#0033A0', fontWeight: 700 }}>
                    {newsDetails.source} &#x2022; {moment(newsDetails.publishDate).format('DD MMM YYYY')}
                </Typography>
                <iframe ref={iframeRef} style={{ width: '100%' }} srcDoc={html} />
            </>
        );
    };

    return (
        <>
            {newsDetails !== null && (
                <Wrapper
                    {...extraProps}
                    visibleLoginButton={true}
                    visibleBackButton={true}
                    main={
                        <div style={{ backgroundColor: 'white', padding: 20, margin: '0 80px', borderRadius: 10 }}>
                            {isLoggedIn && (
                                <Button sx={{ border: '1px solid #0033A0', marginBottom: '20px' }}>
                                    <Link
                                        component={RouterLink}
                                        style={{
                                            fontSize: 18,
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            columnGap: '5px',
                                        }}
                                        to="/news"
                                    >
                                        <ArrowBackIcon />
                                        <Typography>{t('news.moreNews')}</Typography>
                                    </Link>
                                </Button>
                            )}
                            {getNewsContentElement(newsDetails)}
                        </div>
                    }
                >
                    <Button sx={{ border: '1px solid #0033A0', marginBottom: '20px' }}>
                        <Link
                            component={RouterLink}
                            style={{ fontSize: 18, display: 'flex', alignItems: 'center', textDecoration: 'none', columnGap: '5px' }}
                            to="/news"
                        >
                            <ArrowBackIcon />
                            <Typography>{t('news.moreNews')}</Typography>
                        </Link>
                    </Button>
                    {getNewsContentElement(newsDetails)}
                </Wrapper>
            )}
            {!newsDetails && (
                <Wrapper
                    visibleLoginButton={true}
                    visibleBackButton={true}
                    main={
                        <>
                            <Typography>News does not exist.</Typography>
                            {isLoggedIn && (
                                <Button sx={{ border: '1px solid #0033A0', marginBottom: '20px' }}>
                                    <Link
                                        component={RouterLink}
                                        style={{
                                            fontSize: 18,
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            columnGap: '5px',
                                        }}
                                        to="/news"
                                    >
                                        <ArrowBackIcon />
                                        <Typography>{t('news.moreNews')}</Typography>
                                    </Link>
                                </Button>
                            )}
                        </>
                    }
                >
                    <Typography>News does not exist.</Typography>
                    <Button sx={{ border: '1px solid #0033A0', marginBottom: '20px' }}>
                        <Link
                            component={RouterLink}
                            style={{ fontSize: 18, display: 'flex', alignItems: 'center', textDecoration: 'none', columnGap: '5px' }}
                            to="/news"
                        >
                            <ArrowBackIcon />
                            <Typography>{t('news.moreNews')}</Typography>
                        </Link>
                    </Button>
                </Wrapper>
            )}
        </>
    );
}

const BackButton = styled(Button)({
    border: '1px solid ##0033A0',
});
