import { Button, CircularProgress, Grid, Typography, styled } from '@mui/material';
import { CMSItem } from '@oma-kala-shared/core/model';
import { News } from '@oma-kala-shared/core/model/News';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { NewsItemPreview } from 'app/components/display';
import { ContentPage } from 'app/components/layout';
import { isLinkTypeLoggedInOnly } from 'app/logic/CmsService';
import { useSyncToken } from 'app/hooks';
import {
    reSetOffset,
    selectHasMoreNews,
    selectLoadingNext,
    selectNewsList,
    selectOffset,
    selectUnreadNewsStats,
    setOffset,
    setUnreadMessageCount,
} from 'app/state/news/newsSlice';
import { fetchNews, fetchNextNews } from 'app/state/thunks/fecthNews';
import { selectIsLoggedIn } from 'app/state/user/userSlice';
import moment, { Moment } from 'moment';

/**
 * @return {JSX.Element}
 */
export function NewsListPage() {
    const { t, i18n } = useTranslation();
    const [initialSyncToken, isInitialLoading] = useSyncToken({
        onSuccess: () => {
            setNeedRemoveUnreadNewsStats(true);
        },
        onError: () => {},
    });
    const [syncToken, isLoading] = useSyncToken({ onSuccess: () => {}, onError: () => {} });
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const [needRemoveUnreadNewsStats, setNeedRemoveUnreadNewsStats] = useState<boolean>(false);
    const newsList: News[] = useSelector(selectNewsList);
    const offset: number = useSelector(selectOffset);
    const hasMoreNews: boolean = useSelector(selectHasMoreNews);
    const loadingNext: boolean = useSelector(selectLoadingNext);
    const unreadCountStats = useSelector(selectUnreadNewsStats);
    const needMarkUnreadNews = unreadCountStats !== null && unreadCountStats.count > 0 && unreadCountStats.fromTimestamp !== null;

    const dispatch = useDispatch();
    const newsPerPage = 8;

    const isItemHidden = (linkItem: CMSItem) => {
        return !isLoggedIn && isLinkTypeLoggedInOnly(linkItem);
    };
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(fetchNews(initialSyncToken, 0, newsPerPage));
    }, []);

    useEffect(() => {
        return () => {
            // Remove the unread news stats after the component is unmounted
            if (needRemoveUnreadNewsStats) {
                dispatch(setUnreadMessageCount(null));
            }
        };
    }, [needRemoveUnreadNewsStats]);

    useEffect(() => {
        dispatch(reSetOffset());
        dispatch(fetchNews(initialSyncToken, 0, newsPerPage));
    }, [i18n.language]);

    const handleFetchMoreNews = () => {
        dispatch(fetchNextNews(syncToken, offset, newsPerPage));
    };

    const isUnreadNews = (news: News, fromDate: Moment) => {
        return needMarkUnreadNews === true && moment(news.publishDate).isAfter(fromDate);
    };

    return (
        <ContentPage width="100vw" padding={'0px'}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {isInitialLoading ? (
                    <CircularProgress style={{ margin: 20 }} />
                ) : (
                    <Grid
                        container
                        spacing={5}
                        ref={scrollContainerRef}
                        style={{ height: '100%', justifyContent: 'center', marginBottom: '200px' }}
                    >
                        {newsList.map((news: News) => (
                            <>
                                <Grid
                                    key={news.id}
                                    item
                                    xs={'auto'}
                                    lg={'auto'}
                                    xl={'auto'}
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <NewsItemPreview
                                        item={news}
                                        isLoading={false}
                                        isUnreadNews={
                                            unreadCountStats !== null && isUnreadNews(news, moment(unreadCountStats!.fromTimestamp))
                                        }
                                    />
                                </Grid>
                            </>
                        ))}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {loadingNext && <CircularProgress style={{ margin: 20 }} />}
                            {newsList.length > 0 && hasMoreNews && !loadingNext && (
                                <MoreButton onClick={handleFetchMoreNews}>{t('news.moreNews')}</MoreButton>
                            )}
                            {!hasMoreNews && !loadingNext && <Typography style={{ margin: 20 }}>{t('news.noMoreNews')}</Typography>}
                        </Grid>
                    </Grid>
                )}
            </div>
        </ContentPage>
    );
}

const MoreButton = styled(Button)({
    backgroundColor: '#0033A0',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#00B5E2',
    },
    margin: 20,
});
