import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Skeleton, Typography } from '@mui/material';
import {
    DeclarationRequirementDetails,
    DeclarationDetails,
    DeclarationCatchDetails,
    DeclarationFisherDetails,
    OmaKalaLanguageCode,
    ConsentIdentifier,
} from '@oma-kala-shared/core/model';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setErrorMessageWithTimeout } from 'app/state/message/messageSlice';
import { DeclarationFisherDetail, DeclarationFisherDetailRef } from './fisher/DeclarationFisherDetail';

import { DynamicGridRef } from '../../common/dynamic-grid';
import { useDispatch, useSelector } from 'react-redux';
import { DeclarationCatchItem } from 'app/components/declaration/display/DeclarationCatchItem';
import { useSyncToken } from 'app/hooks';
import { selectDeclarationRequirements } from 'app/state/declaration/declarationSlice';
import { fetchDeclarationRequirements } from 'app/state/thunks/fetchDeclarationRequirements';
import { DeleteDeclarationCatchModal } from '../modal/DeleteDeclarationCatchModal';
import { saveDeclaration } from 'app/state/thunks/saveDeclaration';
import { DeclarationCatchDetail } from './catch/DeclarationCatchDetail';
import usePrompt from 'app/hooks/router/usePrompt';
import DeclarationCatchList from '../display/DeclarationCatchList';
import { selectUserData, selectUserIsAdmin, selectUserIsEditor } from 'app/state/user/userSlice';
import { store } from 'app/state/store';
import i18n from 'i18n';
import { getConsentDisplayText } from '@oma-kala-shared/core/logic';
import SkeletonLoader from './SkeletonLoader';
import AddIcon from '@mui/icons-material/Add';
import { appTheme } from 'app/theme';
import SaveIcon from '@mui/icons-material/Save';
export type EditDeclarationFormProps = {
    declarationDetails: DeclarationDetails;
    onSubmit?: (newDetails: DeclarationDetails) => void;
    isGroupDeclaration: boolean;
    onCatchFormClose?: () => void;
    onCatchFormShow?: () => void;
};

export type EditDeclarationFormRef = {
    isDirty: () => boolean;
    openCatchForm: (type: 'group' | 'personal') => void;
};

const EditDeclarationForm = React.forwardRef<EditDeclarationFormRef, EditDeclarationFormProps>(
    ({ declarationDetails, onSubmit, isGroupDeclaration, onCatchFormClose = () => {}, onCatchFormShow = () => {} }, ref) => {
        const declarationRequirements: DeclarationRequirementDetails[] = useSelector(selectDeclarationRequirements);

        const declarationRequirement: DeclarationRequirementDetails | null =
            declarationRequirements.length > 0 ? declarationRequirements[0] : null;

        const { t } = useTranslation();
        const [isDirty, setIsDirty] = useState<boolean>(false);
        usePrompt(t('common.form.leavingNotification'), isDirty);

        const [getRequirementSyncToken] = useSyncToken({
            onSuccess: () => {},
            onError: () => {},
        });

        const [syncToken, isProcessing] = useSyncToken({
            onSuccess: (savedDeclaration, message) => {
                if (onSubmit) {
                    onSubmit(savedDeclaration);
                }
            },
            onError: () => {},
        });

        useEffect(() => {
            if (declarationRequirements.length === 0) {
                dispatch(fetchDeclarationRequirements(getRequirementSyncToken));
            }
        }, []);

        const [currentDeclaration, setCurrentDeclaration] = useState<DeclarationDetails>(declarationDetails);
        const [isCatchFormOpen, setIsCatchFormOpen] = useState<boolean>(false);
        const [editingCatch, setEditingCatch] = useState<DeclarationCatchDetails | null>(null);
        const [deletingCatch, setDeletingCatch] = useState<DeclarationCatchDetails | null>(null);
        const fisherFormRef = useRef<DeclarationFisherDetailRef>(null);
        const catchFormRef = useRef<DynamicGridRef>(null);
        const dispatch = useDispatch();
        const isAdminOrEditor = useSelector(selectUserIsAdmin) === true || useSelector(selectUserIsEditor) === true;
        const userData = selectUserData(store.getState());
        const exactLocationConsent = userData?.consentData?.find(
            consent => consent.consentId === 'ALLOW_EXACT_LOCATION_FOR_RESEARCH_USAGE'
        );

        useImperativeHandle(ref, () => ({
            openCatchForm: () => {
                if (!isCatchFormOpen) {
                    setIsCatchFormOpen(true);
                    setEditingCatch(null);
                }
            },
            isDirty: () => {
                return isDirty;
            },
        }));

        /**
         * We show the catch form by default if there is no catch added or when user click new catch button
         */
        const showCatchForm = isCatchFormOpen === true;

        useEffect(() => {
            if (!showCatchForm) {
                onCatchFormClose();
            } else {
                onCatchFormShow();
            }
        }, [showCatchForm]);

        /**
         * Submit declaration to the backend
         */
        const handleSaveDeclaration = () => {
            if (!fisherFormRef.current?.validate() || currentDeclaration.catches.length === 0) {
                dispatch(
                    setErrorMessageWithTimeout({ text: t('catchDeclaration.newDeclaration.messages.validateErrorMessage'), timeout: 3000 })
                );

                return;
            }

            const fisherDetails = fisherFormRef.current?.getDeclarationFisherDetails();
            const latestNewDeclaration: DeclarationDetails = {
                ...currentDeclaration,
            };

            latestNewDeclaration.fishers = [fisherDetails!];
            setCurrentDeclaration(latestNewDeclaration);
            dispatch(saveDeclaration(syncToken, latestNewDeclaration));
            setIsDirty(false);
        };

        const handleFisherDetailsChange = (latestFisherProperties: DeclarationFisherDetails | null) => {
            const latestNewDeclaration: DeclarationDetails = {
                ...currentDeclaration,
            };

            if (latestFisherProperties) {
                latestNewDeclaration.fishers = [latestFisherProperties];
            }

            fisherFormRef.current?.validate();
            setCurrentDeclaration(latestNewDeclaration);
            setIsDirty(true);
        };

        const handleCatchDetailsChange = (latestCatchDetail: DeclarationCatchDetails | null) => {
            const latestNewDeclaration: DeclarationDetails = {
                ...currentDeclaration,
            };

            if (latestCatchDetail) {
                latestNewDeclaration.catches = [latestCatchDetail];
            }
            catchFormRef.current?.validate();
            // setCurrentDeclaration(latestNewDeclaration);
            setEditingCatch(latestCatchDetail);
            setIsDirty(true);
        };

        /**
         * When we save the catch info it can be edit or create new catch for current declaration
         * we need to also include the fisher's details because this function will make this component
         * rerender so we need to let the fisher form render with latest data otherwise we will face
         * problem that all required fields is empty although in the UI user filled in the input
         *
         * TODO: Need to check the description again, do we need to mention why fisher details added here
         *
         * @param {DeclarationCatchDetails} catchInfo The saved catch info
         */
        const handleCreateDeclarationCatchFormSave = (catchInfo: DeclarationCatchDetails) => {
            // TODO: Need to handle follow generalized location not geometry
            let latestNewDeclaration;
            if (editingCatch === null) {
                latestNewDeclaration = {
                    ...currentDeclaration,
                    catches: [...currentDeclaration.catches, catchInfo],
                };
            } else {
                let exist = false;
                const latestCatches = currentDeclaration.catches.map(catchItem => {
                    if (
                        (catchInfo.tempId !== undefined && catchItem.tempId === editingCatch.tempId) ||
                        (catchInfo.id !== undefined && catchItem.id === editingCatch.id)
                    ) {
                        exist = true;
                        return catchInfo;
                    }

                    return catchItem;
                });

                // TODO: Need to recheck do we still need it because with editing it mean that it always exist
                if (exist === false) {
                    latestCatches.push(catchInfo);
                }

                latestNewDeclaration = {
                    ...currentDeclaration,
                    catches: latestCatches,
                };
            }

            setCurrentDeclaration(latestNewDeclaration);
            setIsCatchFormOpen(false);
            setEditingCatch(null);
        };

        const handleCreateDeclarationCatchFormCancel = () => {
            setEditingCatch(null);
            setIsCatchFormOpen(false);
        };

        const handleEditCatch = (editItem: DeclarationCatchDetails) => {
            let editCatch;
            // Edit already published catch
            if (editItem.id) {
                editCatch = currentDeclaration.catches.find((catchDetail: DeclarationCatchDetails) => editItem.id === catchDetail.id);
            } else {
                // Edit catch added while editing and it is not published
                editCatch = currentDeclaration.catches.find(
                    (catchDetail: DeclarationCatchDetails) => editItem.tempId !== undefined && editItem.tempId === catchDetail.tempId
                );
            }

            setEditingCatch(editCatch!);
            setIsCatchFormOpen(true);
        };

        const handleRemoveCatch = (removeItem: DeclarationCatchDetails) => {
            let newCatches;
            // Remove already published catch
            if (removeItem.id) {
                newCatches = currentDeclaration.catches.map((catchDetail: DeclarationCatchDetails) => {
                    if (removeItem.id === catchDetail.id) {
                        return removeItem;
                    }

                    return catchDetail;
                });
            } else {
                // Remove catch added while editing and it is not published
                newCatches = currentDeclaration.catches.filter(
                    (catchDetail: DeclarationCatchDetails) => removeItem.tempId !== undefined && removeItem.tempId !== catchDetail.tempId
                );
            }

            const latestNewDeclaration = {
                ...currentDeclaration,
                catches: newCatches,
            };

            setCurrentDeclaration(latestNewDeclaration);

            if (latestNewDeclaration.catches.length === 0) {
                setIsCatchFormOpen(true);
            } else {
                setIsCatchFormOpen(false);
            }
        };

        const handleUndoRemoveCatch = (undoItem: DeclarationCatchDetails) => {
            const newCatches = currentDeclaration.catches.map((catchDetail: DeclarationCatchDetails) => {
                if (catchDetail.id === undoItem.id) {
                    const newCatchInfo = { ...catchDetail };
                    delete newCatchInfo.deletionReason;
                    return newCatchInfo;
                }

                return catchDetail;
            });

            const latestNewDeclaration = {
                ...currentDeclaration,
                catches: newCatches,
            };

            setCurrentDeclaration(latestNewDeclaration);

            if (latestNewDeclaration.catches.length === 0) {
                setIsCatchFormOpen(true);
            } else {
                setIsCatchFormOpen(false);
            }
        };

        const handleCopyCatch = (copyItem: DeclarationCatchDetails) => {
            const newCatch = {
                ...copyItem,
                geometry: copyItem.geometry ?? copyItem.generalizedLocation!.geometry,
                generalizedLocation: {
                    ...copyItem.generalizedLocation!,
                    id: null,
                },
                tempId: `catchDetails_${new Date().getTime().toString()}` as string,
            };

            // Need to remove id to be able to create new catch
            if (newCatch.id) {
                delete newCatch.id;
                delete newCatch.sequentialId;

                // Inside the catch property we also need to remove the id out of it
                const pureProperties = newCatch.catchProperties.map(property => {
                    const { id, ...pureProperty } = property;
                    return pureProperty;
                });

                newCatch.catchProperties = pureProperties;
            }

            const latestNewDeclaration = {
                ...currentDeclaration,
                catches: [...currentDeclaration.catches, newCatch],
            };
            setCurrentDeclaration(latestNewDeclaration);
        };

        const openDeleteModal = (deletingCatch: DeclarationCatchDetails) => {
            setDeletingCatch(deletingCatch);
        };

        const closeDeleteModal = () => {
            setDeletingCatch(null);
        };

        const handleOnDeleteModalSubmit = (deletingCatch: DeclarationCatchDetails) => {
            handleRemoveCatch(deletingCatch);
            closeDeleteModal();
        };

        if (declarationRequirement === null) {
            return <SkeletonLoader />;
        }

        return (
            <>
                {deletingCatch && (
                    <DeleteDeclarationCatchModal
                        catchDetail={deletingCatch}
                        onClose={closeDeleteModal}
                        onSubmit={handleOnDeleteModalSubmit}
                    />
                )}

                <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 2 }}>
                    {t('catchDeclaration.newDeclaration.fisherInfoTitle')}
                </Typography>

                <DeclarationFisherDetail
                    ref={fisherFormRef}
                    declaration={currentDeclaration}
                    declarationFisherRequirementProperties={declarationRequirement.requiredFisherProperties}
                    onValueChange={handleFisherDetailsChange}
                    isGroupDeclaration={isGroupDeclaration}
                />
                {isAdminOrEditor && exactLocationConsent && (
                    <FormControlLabel
                        control={<Checkbox checked={currentDeclaration.saveExactLocation} />}
                        label={getConsentDisplayText(exactLocationConsent, i18n.language as OmaKalaLanguageCode)}
                        disabled={true}
                    />
                )}

                {showCatchForm && (
                    <>
                        <DeclarationCatchDetail
                            ref={catchFormRef}
                            declaration={currentDeclaration}
                            catchDetail={editingCatch}
                            declarationTaxonomyRequirement={declarationRequirement.requiredTaxonomies}
                            onCancel={handleCreateDeclarationCatchFormCancel}
                            onSave={handleCreateDeclarationCatchFormSave}
                            allowCancel={currentDeclaration.catches.length > 0}
                            onValueChange={handleCatchDetailsChange}
                        />
                    </>
                )}

                {!showCatchForm && (
                    <>
                        <Box>
                            <Box sx={{ marginTop: 6, display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h5" component="span" sx={{ marginBottom: 2, marginTop: 2 }}>
                                    {t('catchDeclaration.newDeclaration.catchInfoTitle')}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => setIsCatchFormOpen(true)}
                                        sx={actionButtonStyle}
                                        variant="contained"
                                        color="primary"
                                    >
                                        <AddIcon fontSize={'small'} sx={{ marginRight: 1 }} />
                                        <Typography variant="body2">
                                            {t('catchDeclaration.newDeclaration.createNewCatch') as string}
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveDeclaration}
                                        disabled={isProcessing}
                                        sx={actionButtonStyle}
                                    >
                                        {isProcessing ? (
                                            <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />
                                        ) : (
                                            <SaveIcon fontSize={'small'} sx={{ marginRight: 1 }} />
                                        )}
                                        <Typography variant="body2">
                                            {t('catchDeclaration.editDeclaration.saveDeclaration') as string}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <DeclarationCatchList
                                catches={currentDeclaration.catches}
                                sorter={DeclarationCatchList.sortByDate}
                                itemRenderer={(catchDetail: DeclarationCatchDetails) => {
                                    return (
                                        <DeclarationCatchItem
                                            key={catchDetail.id ? catchDetail.id : catchDetail.tempId}
                                            catchItem={catchDetail}
                                            onCopy={() => {
                                                handleCopyCatch(catchDetail);
                                            }}
                                            onDelete={() => {
                                                catchDetail.tempId ? handleRemoveCatch(catchDetail) : openDeleteModal(catchDetail);
                                            }}
                                            onUndoDelete={() => {
                                                handleUndoRemoveCatch(catchDetail);
                                            }}
                                            onEdit={() => {
                                                handleEditCatch(catchDetail);
                                            }}
                                            showUnSavedNotification={true}
                                            isListView={false}
                                        />
                                    );
                                }}
                            />
                        </Box>
                    </>
                )}
            </>
        );
    }
);

EditDeclarationForm.displayName = 'CreateDeclarationForm';

export default EditDeclarationForm;

const actionButtonStyle = {
    height: 35,
    marginLeft: 1.5,
    backgroundColor: appTheme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: 'rgba(0,83,166,0.75)',
    },
} as const;
