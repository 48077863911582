import React, { useImperativeHandle, useRef, useState } from 'react';
import {
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    FishingType,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import {
    GridValue,
    GridField,
    DYNAMIC_FIELD_TYPE,
    DynamicGridRef,
    SelectFieldItem,
    GridSelectField,
} from 'app/components/common/dynamic-grid';
import DynamicGrid from 'app/components/common/dynamic-grid/DynamicGrid';
import CatchDeclarationRequirementHelper, { DeclarationPropertyRequirements } from '../../../helpers/CatchDeclarationRequirementHelper';
import moment from 'moment';

type CatchDetailsProps = {
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomies: SelectFieldItem[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    onTaxonomyChanged: (taxonomy: DeclarationSpeciesRequirementDetails) => void;
};

export type CatchDetailsRef = Omit<DynamicGridRef, 'setError'>;

export const SpeciesFieldName = 'Species';

const CatchDetails = React.forwardRef<CatchDetailsRef, CatchDetailsProps>(
    ({ catchProperties, declarationTaxonomyRequirement, taxonomies, taxonomyRequirement, onTaxonomyChanged }: CatchDetailsProps, ref) => {
        const gridValue = useRef<GridValue | null>({});
        const dynamicGridRef = useRef<DynamicGridRef | null>(null);
        const { t } = useTranslation();
        const requirementProperties = taxonomyRequirement.requiredTaxonomyProperties;

        const handleValueChange = (newGridValue: GridValue) => {
            gridValue.current = {
                ...(gridValue.current ?? {}),
                ...newGridValue,
            };

            const selectedSpecied = gridValue.current[SpeciesFieldName];
            const selectedTaxonomy = declarationTaxonomyRequirement.find(taxonomy => taxonomy.idTaxonomy === selectedSpecied);
            onTaxonomyChanged(selectedTaxonomy!);
        };

        useImperativeHandle(ref, () => ({
            validate: () => {
                return dynamicGridRef.current?.validate() as boolean;
            },
            getValue: () => {
                return gridValue.current ?? null;
            },
        }));

        /**
         * For now fisher form only render when we have fisher requirements
         */
        if (declarationTaxonomyRequirement.length === 0) {
            return <></>;
        }

        const getFieldTypeForProperty = (property: DeclarationPropertyRequirements): DYNAMIC_FIELD_TYPE => {
            if (property.name === (DeclarationSpeciesRequirementPropertyName.CATCH_GEAR as string)) {
                return DYNAMIC_FIELD_TYPE.SELECT;
            }

            return property.dataType as DYNAMIC_FIELD_TYPE;
        };

        const fieldFiltering = (property: DeclarationPropertyRequirements): boolean => {
            const detailsRequirements: string[] = [
                DeclarationSpeciesRequirementPropertyName.CATCH_DATE,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH,
            ];

            return detailsRequirements.includes(property.name);
        };

        const visibilityHandler =
            (catchProperties: DeclarationCatchProperty[]) =>
            (property: DeclarationPropertyRequirements): boolean => {
                if (
                    property.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING ||
                    property.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT ||
                    property.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH ||
                    property.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH
                ) {
                    const gearProperty = catchProperties.find(
                        property => property.propertyName === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR
                    );

                    return (gearProperty !== undefined && gearProperty.value === FishingType.GILLNETTING) as boolean;
                }

                return true;
            };

        const preDefinedValues = {
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING]: 50,
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT]: 1.5,
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH]: 10,
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH]: 0.12,
            [DeclarationSpeciesRequirementPropertyName.CATCH_DATE]: moment().startOf('day').utc().toISOString(),
        };

        const fieldPostHandler = (field: GridField): GridField => {
            if (field.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR) {
                const items = Object.values(FishingType).map(value => {
                    return {
                        value: value,
                        label: t(`common.fishingType.${value}`),
                    };
                });
                (field as GridSelectField).items = items;
                field.sx = {
                    marginTop: 2,
                };
            }

            if (field.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING) {
                field.sx = {
                    marginTop: 2,
                };
            }

            return field;
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            return t(`catchDeclaration.catchProperties.${property.name}`);
        };

        const predefinedValidationRules: Record<string, string> = {
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING]: 'numeric|min:1',
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH]: 'numeric|min:1',
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT]: 'numeric|min:0.1',
            [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH]: 'numeric|min:0.1',
        };

        // Need to create fields from requiredFields
        const [fields, fieldValues] = CatchDeclarationRequirementHelper.generateFieldsFromRequirements(
            requirementProperties,
            fieldFiltering,
            preDefinedValues,
            visibilityHandler(catchProperties),
            getFieldTypeForProperty,
            predefinedValidationRules,
            fieldPostHandler,
            fieldLabelHandler
        );

        /**
         * Update the ref value
         */
        gridValue.current = {
            ...(gridValue.current ?? {}),
            ...fieldValues,
        };

        // When we have the catch properties, need to set value to field and fieldValue ref
        if (catchProperties.length > 0) {
            catchProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;
                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    gridValue.current = {
                        ...(gridValue.current ?? {}),
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        // Need to stimulate the species select for dynamic grid
        const speciesField = {
            id: 0,
            type: DYNAMIC_FIELD_TYPE.SELECT,
            containerSize: 6,
            inputSize: '98%',
            name: SpeciesFieldName,
            label: t('catchDeclaration.catchProperties.SPECIES'),
            required: true,
            value: taxonomyRequirement.idTaxonomy,
            // disabled: disabledFields.includes(requireField.name),
            items: taxonomies,
            validationRule: CatchDeclarationRequirementHelper.getValidationRuleForType('select'),
            sx: {
                marginTop: 2,
            },
        };

        const gridFields: GridField[] = [speciesField, ...Object.values(fields)];

        return <DynamicGrid ref={dynamicGridRef} gridFields={[gridFields]} onValueChange={handleValueChange} />;
    }
);

CatchDetails.displayName = 'CatchDetails';

export default CatchDetails;
