import { Typography, CardContent } from '@mui/material';
import { CMSItem, LocalizedTags } from '@oma-kala-shared/core/model';
import { first } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { filterCmsItemsByTag, getLocalizedLinkTag } from 'app/logic/CmsService';
import { getLanguageFromParameter } from 'app/logic/LanguageService';
import { selectCmsElements } from 'app/state/cms/cmsSlice';

interface Props {
    tagset: LocalizedTags;
}

/**
 * @param {Props} param0
 * @return {JSX.Element}
 */
export function TermsAndConditionsCard({ tagset }: Props) {
    const elements: CMSItem[] = useSelector(selectCmsElements);
    const language = getLanguageFromParameter();
    const cmsItem: CMSItem | undefined = first(filterCmsItemsByTag(elements, getLocalizedLinkTag(language, tagset)));

    return (
        <>
            {cmsItem && (
                <CardContent>
                    <Typography variant="h4">{cmsItem.title.rendered}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }}></div>
                </CardContent>
            )}
        </>
    );
}
