import { Card, CardContent, CardHeader, Grid, IconButton, Paper, Typography } from '@mui/material';
import { GeocodingFeature, GeocodingProperties } from '@oma-kala-shared/core/model';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { getGeocodingFeatureLabel } from '@oma-kala-shared/core/logic';
import { useTranslation } from 'react-i18next';

type GeocodingPropertyKey = keyof GeocodingProperties;

type MapSearchDataProps = {
    feature: GeocodingFeature;
    onClose: () => void;
};

export const MapSearchData = ({ feature, onClose }: MapSearchDataProps) => {
    const featureLabel = getGeocodingFeatureLabel(feature);
    const {t} = useTranslation();

    const labelProperties: GeocodingPropertyKey[] = Object.keys(feature.properties)
        .filter((p: string) => p.startsWith('label:'))
        .map((s: string) => s as GeocodingPropertyKey);
    return (
        <Paper
            sx={{
                margin: 'auto',
                marginTop: '10px',
                width: '90%',
            }}
        >
            <Card
                sx={{
                    marginTop: '10px',
                    maxHeight: '38vh',
                    overflowY: 'auto',
                    width: '100%',
                }}
            >
                <CardHeader
                    sx={{
                        paddingBottom: '4px',
                    }}
                    action={
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title={
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            sx={{
                                '&': {
                                    fontSize: '1em',
                                    textAlign: 'center',
                                },
                            }}
                        >
                            {featureLabel}
                        </Typography>
                    }
                />
                <CardContent
                    sx={{
                        padding: '0px',
                        '&:last-child': {
                            paddingBottom: '0px',
                        },
                    }}
                >
                    <Grid container>
                        {labelProperties.map((labelProperty: GeocodingPropertyKey) => {
                            const propertyValue: string | number | null = feature.properties[labelProperty];
                            if (!propertyValue) {
                                return; // don't display empty properties
                            }

                            return (
                                <Grid
                                    key={labelProperty}
                                    item
                                    container
                                    sx={{
                                        padding: '6px',
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <span>{t(`map.searchBar.result.${labelProperty.replace('label:', '').toString()}`)}</span>
                                    </Grid>
                                    <Grid item xs={8} textAlign="right">
                                        <span>{propertyValue}</span>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
    );
};
