import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, DateDiffResult, FailedResponse, TokenBody, TokenData } from '@oma-kala-shared/core/model';
import { Buffer } from 'buffer';
import moment from 'moment';

import BrowserWebService from './BrowserWebService';
import { dateDiff } from './DateService';
import { clearSetting, getSetting, storeSetting } from './StorageService';

/**
 * Get the cached authentication token from local storage
 * @return {TokenData | null} The token data (as JSON) if it exists - else null
 */
export function getToken(): TokenData | null {
    const tokenString: string | null = getSetting('tokenData');
    if (tokenString === null) {
        return null;
    }
    return JSON.parse(tokenString);
}

/**
 * Clears the token from local storage
 */
export function clearToken() {
    clearSetting('tokenData');
}

/**
 * Stores the specified token in local storage
 * @param {string} token The token to store
 */
export function storeToken(token: TokenData) {
    storeSetting('tokenData', JSON.stringify(token));
}

/**
 * Checks if the provided token is valid
 * @param {TokenData} token The token to check
 * @return {Promise<boolean>} Whether or not the token is valid
 */
export function checkRefreshTokenValidity(token: TokenData): boolean {
    try {
        if (!token) {
            return false;
        }
        // Currently, the refresh token expiry date is set to 90 days
        // each time we refresh it. If the user doesn't use the app in
        // 90 days, we have to re-authenticate
        const now: number = moment().unix();
        const tokenIssuedAt: moment.Moment = moment.unix(token.body.iat);
        const refreshTokenExpiryDate: number = tokenIssuedAt.clone().add({ days: 90 }).unix().valueOf();

        // if the refresh token expiry date is in the future, this will be negative
        const diff: number = now - refreshTokenExpiryDate;
        return diff < 0;
    } catch (e) {
        console.debug('check token validity threw an exception', e);
        return false;
    }
}

/**
 * Checks if the current token should be refreshed or not.
 *
 * Currently, returns true if token is issued more than 1 hour ago.
 * @param {tokenData} token
 * @return {boolean}
 */
export function shouldRefreshToken(token: TokenData): boolean {
    const now = moment();
    const issuedAt = moment.unix(token.body.iat);

    const diff: DateDiffResult = dateDiff(issuedAt.toDate(), now.toDate());

    if (diff.hours < 1) {
        return false;
    }

    return true;
}

/**
 * Refreshes the provided token and returns the refresh response.
 * @param {string} refreshToken
 * @return {Promise<ContentResponse<TokenData>>}
 */
export async function refreshToken(refreshToken: string): Promise<ContentResponse<TokenData> | FailedResponse> {
    const service: WebService = new BrowserWebService();
    const response: ContentResponse<TokenData> | FailedResponse = await service.refreshToken(refreshToken);

    if (response.successful) {
        const tokenData: TokenData = addExpirationTimeAndBody(response.content);
        return { successful: true, content: tokenData };
    }
    return response;
}

/**
 * Decodes and parses the body of a JWT token
 * @param {string} token The token to decode and parse
 * @return {any} The parsed JWT token body
 */
export function parseJwtToken(token: string): TokenBody {
    const base64Url: string = token.split('.')[1];
    const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload: string = decodeURIComponent(
        Buffer.from(base64, 'base64')
            .toString()
            .split('')
            .map((c: string) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

/**
 * Enrich a TokenData received from the back-end with expiration time and body.
 * @param {TokenData} tokenData Token
 * @return {TokenData} Token with expiration time and body.
 */
export function addExpirationTimeAndBody(tokenData: TokenData): TokenData {
    const expiryDate: Date = new Date(Date.now() + Number(tokenData.expires_in) * 1000);
    return {
        ...tokenData,
        expires_on: expiryDate.toISOString(),
        body: parseJwtToken(tokenData.access_token),
    };
}
