import { t } from 'i18next';

import BrowserWebService from 'app/logic/BrowserWebService';
import { PushNotificationRequest, RequestStatus, SimpleResponse } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';
import { setSyncState } from 'app/state/sync/syncSlice';

import { createWebService } from './thunkHelpers';

/**
 * Send a push notification request
 * @param {string} syncToken
 * @param {PushNotificationRequest} request
 * @return {AppThunk}
 */
export const sendPushNotification =
    (syncToken: string, request: PushNotificationRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: BrowserWebService = await createWebService(dispatch, getState);
        const response: SimpleResponse = await service.sendPushNotification(request);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        message: t('admin.notifications.send.notificationSent'),
                    },
                })
            );
        } else {
            console.error('Error sending push notification: ', response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: t('admin.notifications.send.notificationSendingError'),
                    },
                })
            );
        }
    };
