import { defaultFormatDate } from '@oma-kala-shared/core/logic';
import {
    DeclarationCatchProperty,
    DeclarationFisherProperty,
    DeclarationFisherRequirementPropertyName,
    DeclarationSpeciesRequirementPropertyName,
} from '@oma-kala-shared/core/model';
import moment from 'moment';

// eslint-disable-next-line require-jsdoc, @typescript-eslint/no-explicit-any
export function clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export const convertCommaToDot = (value: string) => {
    let newValue = value;
    console.log(typeof value);
    // Convert comma to dot if exists. Example: 0,4 to 0.4
    newValue = newValue.replace(',', '.');

    return newValue;
};

export const displayDecimalValue = (value: number | string) => {
    let newValue: string;

    if (typeof value === 'string') {
        newValue = parseFloat(value).toString();
    } else {
        newValue = value.toString();
    }

    // Convert dot to comma if exists. Example: 0.4 to 0,4
    if (newValue.includes('.')) {
        newValue = newValue.replace('.', ',');
    }

    return newValue;
};

// eslint-disable-next-line require-jsdoc
export function formatDynamicProperty(property: DeclarationCatchProperty | DeclarationFisherProperty, lang: string) {
    try {
        let val = property.value;
        switch (property.dataType) {
            case 'float':
                val = displayDecimalValue(property.value);
                break;
            case 'date':
                val = defaultFormatDate(moment(property.value).toDate(), lang);
                break;
        }
        if (property.unit && property.unit.length > 0 && !property.unit.toLowerCase().includes('count')) {
            val += ' ' + property.unit;
        }
        return val;
    } catch (e) {
        console.warn('Failed to format dynamic property value - ' + property?.propertyName);
    }
    return property.value;
}

// eslint-disable-next-line require-jsdoc
export function sortFisherProperties(properties: DeclarationFisherProperty[]) {
    const sortOrder = [
        DeclarationFisherRequirementPropertyName.FIRST_NAME.toString(),
        DeclarationFisherRequirementPropertyName.LAST_NAME.toString(),
        DeclarationFisherRequirementPropertyName.BIRTH_DATE.toString(),
        DeclarationFisherRequirementPropertyName.STREET_ADDRESS.toString(),
        DeclarationFisherRequirementPropertyName.MUNICIPALITY.toString(),
        DeclarationFisherRequirementPropertyName.COUNT.toString(),
    ];

    const sortFunction = (a: DeclarationFisherProperty, b: DeclarationFisherProperty) => {
        if (!a.propertyName || !b.propertyName) return 1;
        const indexA = sortOrder.indexOf(a.propertyName);
        const indexB = sortOrder.indexOf(b.propertyName);
        // If either value is not in the sortOrder array, it should come later if (indexA === -1) return 1;
        if (indexB === -1) return 1;
        return indexA - indexB;
    };
    return properties.sort(sortFunction);
}

// eslint-disable-next-line require-jsdoc
export function sortCatchProperties(properties: DeclarationCatchProperty[]) {
    const sortOrder = [
        DeclarationSpeciesRequirementPropertyName.WEIGHT.toString(),
        DeclarationSpeciesRequirementPropertyName.LENGTH.toString(),
        DeclarationSpeciesRequirementPropertyName.CATCH_DATE.toString(),
        DeclarationSpeciesRequirementPropertyName.CATCH_GEAR.toString(),
        DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING.toString(),
        DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH.toString(),
        DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT.toString(),
        DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH.toString(),
        DeclarationSpeciesRequirementPropertyName.COUNT.toString(),
        DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT.toString(),
        DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT.toString(),
    ];

    const sortFunction = (a: DeclarationCatchProperty, b: DeclarationCatchProperty) => {
        if (!a.propertyName || !b.propertyName) return 1;
        const indexA = sortOrder.indexOf(a.propertyName);
        const indexB = sortOrder.indexOf(b.propertyName);
        // If either value is not in the sortOrder array, it should come later if (indexA === -1) return 1;
        if (indexB === -1) return 1;
        return indexA - indexB;
    };
    return properties.sort(sortFunction);
}
