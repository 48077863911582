import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import omakalaLogo from 'resources/images/omakala-logo-with-background.png';
import { changeLanguage } from 'app/logic/LanguageService';
import { LanguageMenu } from 'app/components/common/language/LanguageMenu';

/**
 * Header for landing page
 * @return {JSX.Element}
 */
export default function LandingHeader({ visibleLoginButton }: { visibleLoginButton?: boolean }) {
    const { t, i18n } = useTranslation();
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    return (
        <Header>
            <Box style={{ display: 'flex' }}>
                <LogoLink to={'/'}>
                    <img src={omakalaLogo} height="50" alt={'omakala-logo'} />
                    <Box ml={1}>
                        <Typography fontSize={700} variant="h5" pb={0.5} lineHeight={1}>
                            Omakala
                        </Typography>
                        <Typography variant="body1" lineHeight={1}>
                            Web
                        </Typography>
                    </Box>
                </LogoLink>
            </Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box display={'flex'} sx={{ color: 'white', margin: '0 20px' }}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={handleMenuClick}>
                        <p>{i18n.language.toUpperCase()}</p>
                        <ArrowDropDownIcon />
                    </Box>
                </Box>
                <LanguageMenu anchorEl={menuAnchor} onClose={handleMenuClose} />
                {visibleLoginButton && (
                    <Link to={'/auth'} style={{ textDecoration: 'none' }}>
                        <LoginButton>{t('common.login')}</LoginButton>
                    </Link>
                )}
            </div>
        </Header>
    );
}
const Header = styled('div')({
    height: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 80px',
});

const LogoLink = styled(Link)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textDecoration: 'none',
});

const LoginButton = styled(Button)({
    height: 40,
    width: 200,
    border: '1px solid white',
    borderRadius: 10,
    color: 'white',
});
