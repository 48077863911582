import WebService from '@oma-kala-shared/core/logic/WebService';
import { CMSItem, CMSRequest, ContentResponse, GetCmsPostsRequest, UserData } from '@oma-kala-shared/core/model';

import { getCmsLinkCategory } from 'app/logic/CmsService';
import { RequestStatus } from 'app/model';
import { setCmsLinks } from 'app/state/cms/cmsSlice';
import { AppThunk, RootState } from 'app/state/store';

import { createWebService } from './thunkHelpers';

/**
 * Fetch links from our CMS for the given language and load them into the application state.
 * @param {string} language
 * @param {UserData} userData
 * @return {AppThunk}
 */
export const fetchCmsLinks =
    (language: string, userData: UserData | null): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const request: GetCmsPostsRequest = {
            fields: 'id,excerpt,title,tags,link,categories',
            categories: getCmsLinkCategory(language).toString(),
            itemPerPage: 99,
        };
        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<CMSItem[]> = await service.fetchCmsPosts(request);

        dispatch(setCmsLinks({ status: RequestStatus.Loading }));

        if (response.successful) {
            const resource: CMSItem[] = userData
                ? response.content
                : response.content.filter((c: CMSItem) => {
                      return c.excerpt.rendered !== '<p>/terms-and-conditions</p>\n' && c.excerpt.rendered !== '<p>/feedback</p>\n';
                  });
            dispatch(setCmsLinks({ status: RequestStatus.Successful, resource: resource }));
        } else {
            dispatch(setCmsLinks({ status: RequestStatus.Failed, message: response.message as string }));
        }
    };
