import i18n from 'i18next';

/**
 *
 * @param {string} translatedKey
 * @param {boolean} isMandatory
 * @return {string}
 */
export function getMandatoryOrOptionalTranslation(translatedKey: string, isMandatory: boolean | undefined) {
    const postfix: string = ' (' + (isMandatory ? i18n.t('common.mandatory') : i18n.t('common.optional')) + ')';
    const translatedMandatoryOrOptionalKey: string = translatedKey + postfix;
    return translatedMandatoryOrOptionalKey;
}
