import { DeclarationDetails, DeclarationRequirementDetails } from '@oma-kala-shared/core/model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/state/store';

export interface DeclarationState {
    declarationRequirements: DeclarationRequirementDetails[];
    declarations: DeclarationDetails[];
}

export const initialState: DeclarationState = {
    declarationRequirements: [],
    declarations: [],
};

const declarationSlice = createSlice({
    name: 'declaration',
    initialState: initialState,
    reducers: {
        setDeclarationRequirements: (state: DeclarationState, action: PayloadAction<DeclarationRequirementDetails[]>) => {
            state.declarationRequirements = action.payload;
        },
        setDeclarations: (state: DeclarationState, action: PayloadAction<DeclarationDetails[]>) => {
            state.declarations = action.payload;
        },
    },
});

export const { setDeclarationRequirements, setDeclarations } = declarationSlice.actions;

export const selectDeclarationRequirements = (state: RootState): DeclarationRequirementDetails[] =>
    state.declaration.declarationRequirements;
export const selectDeclarations = (state: RootState): DeclarationDetails[] => state.declaration.declarations;

export default declarationSlice.reducer;
