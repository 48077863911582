import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import MapControlButtonContainer from './MapControlButtonContainer';
import MapControlButton from './MapControlButton';
import LayersIcon from '@mui/icons-material/Layers';
import MapSettingContainer from './map-setting/MapSettingContainer';
import MapContext from '../../MapContext';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
enum AvailableSetting {
    LAYER_SETTING = 'layer-setting',
}

const MapControlPanel = () => {
    const [showingSetting, setShowingSetting] = useState<AvailableSetting | null>(null);
    const map = useContext(MapContext);

    const handleSettingButtonSelected = (settingName: AvailableSetting) => {
        setShowingSetting(prev => (prev === settingName ? null : settingName));
    };

    const handleZoom = (isZoomIn: boolean) => {
        map?.getView().setZoom(map!.getView()!.getZoom()! + (isZoomIn ? 1 : -1));
    };

    return (
        <>
            <Box
                component="div"
                sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    zIndex: 2,
                }}
            >
                <MapControlButtonContainer>
                    <MapControlButton
                        label="layersetting-open-button"
                        indicateOnSelected={true}
                        onSelected={() => handleSettingButtonSelected(AvailableSetting.LAYER_SETTING)}
                    >
                        <LayersIcon />
                    </MapControlButton>
                    <MapControlButton label="zoomin-button" onSelected={() => handleZoom(true)}>
                        <ZoomInIcon />
                    </MapControlButton>
                    <MapControlButton label="zoomin-button" onSelected={() => handleZoom(false)}>
                        <ZoomOutIcon />
                    </MapControlButton>
                </MapControlButtonContainer>
            </Box>
            {showingSetting && <MapSettingContainer settingName={showingSetting} />}
        </>
    );
};

export default MapControlPanel;
