import WebService from '@oma-kala-shared/core/logic/WebService';
import { FeedbackRequest, SimpleResponse } from '@oma-kala-shared/core/model';

import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';
import { setSyncState } from 'app/state/sync/syncSlice';

import { createWebService } from './thunkHelpers';

/**
 * Send a feedback request
 * @param {string} syncToken
 * @param {FeedbackRequest} request
 * @return {AppThunk}
 */
export const sendFeedback =
    (syncToken: string, request: FeedbackRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const response: SimpleResponse = await service.sendFeedback(request);

        if (response.successful) {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
        } else {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Failed, message: response.message as string } }));
        }
    };
