import { createTheme } from '@mui/material/styles';

// Luke colors:
// Primary: #ff8200 (orange)
// Secondary: #00B5E2 (light blue)
// #54585a (dark gray)
// Assisting colors (use sparingly):
// #0033a0 (blue)
// #e13c98 (pink)
// #78be20 (green)
// #7f3f98 (purple)
// Example color distributions:
// 1. Primary: 25%; Secondary: 20%; Dark Gray: 25%; White: 25%; Green: 5%;
// 2. Secondary: 25%; Primary: 20%; Dark Gray: 25%; White: 25%; Pink: 5%;
// 3. Purple: 25%; Dark Gray: 25%; White: 25%; Primary: 20%, Secondary: 5%;
export const lukeOrange = '#ff8200';
export const lukeLightBlue = '#00B5E2';
export const lukeGray = '#54585a';
// The colors below are accents and should be used sparingly
// See above for color distribution examples
export const lukeBlue = '#0033a0';
export const lukePink = '#e13c98';
export const lukeGreen = '#78be20';
export const lukePurple = '#7f3f98';
export const landingPurple = '#1E293B';

export const appTheme = createTheme({
    palette: {
        primary: {
            main: 'rgba(0, 84, 166, 1.0)',
        },
        secondary: {
            main: '#00B5E2',
            light: '#5AB3E0',
        },
        error: {
            main: '#c62107',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'Mukta, sans-serif',
    },
});
