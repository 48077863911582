import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { GeocodingFeature } from '@oma-kala-shared/core/model';
import { getGeocodingFeatureLabel, getGeocodingFeatureGrouping } from '@oma-kala-shared/core/logic';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';

type MapSearchResultProps = {
    searchResult: GeocodingFeature[] | null;
    isFetchingResult: boolean;
    onClose: () => void;
    onResultSelected: (selectedResult: GeocodingFeature) => void;
};

const MapSearchResult = ({ searchResult, isFetchingResult, onClose, onResultSelected }: MapSearchResultProps) => {
    const { t } = useTranslation();

    return (
        <Card
            sx={{
                margin: 'auto',
                marginTop: '10px',
                maxHeight: '40vh',
                overflowY: 'auto',
                width: '90%',
            }}
        >
            <CardHeader
                sx={{
                    paddingBottom: '4px',
                    position: 'relative',
                    '& .MuiCardHeader-action': {
                        position: 'absolute',
                        right: '12px',
                        top: '12px',
                    },
                }}
                action={
                    isFetchingResult ? null : (
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )
                }
                title={
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        sx={{
                            '&': {
                                fontSize: '1em',
                                textAlign: 'center',
                            },
                        }}
                    >
                        {isFetchingResult ? (
                            <CircularProgress size={36} />
                        ) : (
                            <>
                                {(!searchResult || searchResult.length === 0) && t('map.searchBar.noResultsFound')}
                                {searchResult && searchResult.length > 0 && t('map.searchBar.resultsFound', { count: searchResult.length })}
                            </>
                        )}
                    </Typography>
                }
            />
            <CardContent
                sx={{
                    padding: '0px',
                    '&:last-child': {
                        paddingBottom: '0px',
                    },
                }}
            >
                <List
                    sx={{
                        padding: '0px',
                    }}
                >
                    <Divider component="li" />

                    {searchResult &&
                        searchResult.length > 0 &&
                        searchResult.map((feature: GeocodingFeature) => {
                            const featureLabel = getGeocodingFeatureLabel(feature);
                            const featureGrouping = getGeocodingFeatureGrouping(feature);

                            return (
                                <>
                                    <ListItem key={feature.id} disablePadding>
                                        <ListItemButton onClick={() => onResultSelected(feature)}>
                                            <Stack>
                                                <ListItemText primary={featureLabel} />
                                                <ListItemText secondary={featureGrouping} />
                                            </Stack>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider component="li" />
                                </>
                            );
                        })}
                </List>
            </CardContent>
        </Card>
    );
};

export default MapSearchResult;
