import { Box } from '@mui/material';
import React from 'react';
import MapControlPanel from './control-panel/MapControlPanel';
import MapSearchPanel from './search-panel/MapSearchPanel';

const MapPanel = () => {
    return (
        <>
            <MapSearchPanel />
            <MapControlPanel />
        </>
    );
};

export default MapPanel;
