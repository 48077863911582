import { Gear, GearProperty, GearType, KnownGearType } from '@oma-kala-shared/core/model';
import { TFunction } from 'i18next';
import { capitalize } from 'lodash';

import * as AppIcons from '../../resources/AppIcon';

/**
 * Return an icon appropriate for the gear.
 * @param {Gear} gear
 * @param {GearType[]} gearTypes All available gear types
 * @return {IconSource}
 */
export function getGearIcon(gear: Gear, gearTypes: GearType[]) {
    const gearType: GearType | undefined = gearTypes.find((gt: GearType) => gt.id === gear.idGearType);
    return getGearTypeIcon(gearType?.name);
}

/**
 * Return an icon appropriate for the gear type.
 * @param {string} gearTypeName
 * @return {IconSource}
 */
export function getGearTypeIcon(gearTypeName: string | undefined) {
    switch (gearTypeName) {
        case KnownGearType.NET:
            return AppIcons.netIcon;
        case KnownGearType.ROD:
            return AppIcons.rodIcon;
        case KnownGearType.FYKE:
            return AppIcons.fykeIcon;
        case KnownGearType.SPINNING_ROD:
            return AppIcons.spinningRodIcon;
        case KnownGearType.FLY_ROD:
            return AppIcons.flyRodIcon;
        case KnownGearType.TROLLING_ROD:
            return AppIcons.trollingRodIcon;
        case KnownGearType.SEINE:
            return AppIcons.seineIcon;
        case KnownGearType.SINGLE_HOOK_AND_LINE:
            return AppIcons.singleHookAndLineIcon;
        case KnownGearType.SPRING_LOADED_FISH_HOOK:
            return AppIcons.springLoadedFishHookIcon;
        case KnownGearType.OTHER_HOOK_GEAR:
            return AppIcons.otherHookGearIcon;
        case KnownGearType.LONG_LINE:
            return AppIcons.longLineIcon;
        case KnownGearType.ICE_ROD:
            return AppIcons.iceRodIcon;
        case KnownGearType.KATISKA:
            return AppIcons.katiskaIcon;
        case KnownGearType.OTHER:
            return AppIcons.otherIcon;
        default:
            return AppIcons.otherIcon;
    }
}

/**
 * Generate a short description for a gear instance
 * @param {Gear} gear Gear
 * @param {GearType[]} gearTypes All available gear types
 * @param {TFunction} t Translate function
 * @return {string} Short description of the gear instance
 */
export function createGearDescription(gear: Gear, gearTypes: GearType[], t: TFunction): string {
    const gearType: GearType | undefined = gearTypes.find((gt: GearType) => gt.id === gear.idGearType);
    if (!gearType) return 'Unknown gear type';
    const parts: string[] = [];
    parts.push(capitalize(t(`gear.gearType.${gearType.name}.name`)));
    for (const propType of gearType.properties) {
        const prop: GearProperty | undefined = gear.properties.find((gp: GearProperty) => gp.gearTypePropertyId === propType.id);
        if (prop) {
            const propName: string = capitalize(t(`gear.gearTypeProperty.${propType.name}.name`));
            parts.push(`${propName}: ${prop.value} ${propType.unit ?? ''}`);
        }
    }
    return parts.join(' • ');
}
