import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { CatchGeneralizedLocation as CatchGeneralizedLocationType, DeclarationCatchDetails, GeoJsonPoint, KnownTaxonomyType, SpatialRequirementResponse } from '@oma-kala-shared/core/model';
import { useTranslation } from 'react-i18next';
import { DeclarationMapHandler } from 'app/logic/DeclarationMapHandler';
import useMapLayer from 'app/hooks/useMapLayer';
import { Map } from 'ol';
import { useSyncToken } from 'app/hooks';
import { useDispatch } from 'react-redux';
import GeneralizedLocationInputField from './GeneralizedLocationInputField';
import { isEmpty } from 'lodash';
import { getCatchSpatialRequirements } from 'app/state/thunks/getCatchSpatialRequirements';

type CatchGeneralizedLocationProps = {
    generalizedLocation: CatchGeneralizedLocationType | null;
    onGeometrySet: (geometry: GeoJsonPoint | null) => void;
    onGeneralizedLocationSet: (newGeneralizedLocation: CatchGeneralizedLocationType | null) => void;
    onSpatialRequirementsUpdated: (spatialReq: SpatialRequirementResponse | null) => void;
    showCircleOnMap: boolean;
    showExactLocation: boolean;
    catchDetails: DeclarationCatchDetails | null | undefined;
};

/**
 * @param {CatchGeneralizedLocationProps} param0
 * @return {JSX.Element}
 */
export default function CatchGeneralizedLocation({
    generalizedLocation: generalizedLocationInput,
    onGeometrySet,
    onGeneralizedLocationSet,
    onSpatialRequirementsUpdated,
    showCircleOnMap,
    showExactLocation,
    catchDetails,
}: CatchGeneralizedLocationProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [hasErrors, setHasErrors] = useState(false);
    const [generalizedLocation, setGeneralizedLocation] = useState<CatchGeneralizedLocationType | null>(generalizedLocationInput);
    const [geom, setGeom] = useState<GeoJsonPoint>();
    const [isInitialMount, setIsInitialMount] = useState(true);

    useEffect(() => {
        onGeneralizedLocationSet(generalizedLocation);
    }, [generalizedLocation]);

    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }
        if (!generalizedLocation) {
            onGeneralizedLocationSet(null);
            setGeneralizedLocation(null);
            return;
        }
        getGeneralizedLocation(generalizedLocation.geometry);
    }, [catchDetails?.idTaxonomyRequiredTaxonomy]);

    const [syncToken, gettingLocation] = useSyncToken({
        onSuccess: (response: SpatialRequirementResponse) => {
            onSpatialRequirementsUpdated(response);
            setGeneralizedLocation(response.generalizedLocationDetails);
            setHasErrors(false);
        },
        onError: message => {
            setHasErrors(true);
        },
    });

    const getGeneralizedLocation = (geometry: GeoJsonPoint) => {
        if (!catchDetails?.idTaxonomyRequiredTaxonomy) {
            return;
        }
        setGeneralizedLocation(null);
        onGeometrySet(geometry);
        dispatch(
            getCatchSpatialRequirements(
                syncToken,
                {
                    specie: catchDetails?.idTaxonomyRequiredTaxonomy,
                    location: geometry,
                },
                i18n.language
            )
        );
    };

    useEffect(() => {
        if (geom) {
            getGeneralizedLocation(geom);
        }
    }, [geom]);

    const mapHandlerInitializer = useCallback((map: Map) => {
        return new DeclarationMapHandler(map, location => {
            const geometry: GeoJsonPoint = {
                type: 'Point',
                coordinates: location.getCoordinates(),
            };

            setGeom(geometry);
        });
    }, []);

    const mapHandler = useMapLayer(mapHandlerInitializer);

    useEffect(() => {
        if (!mapHandler) return;

        if (generalizedLocation) {
            // 2500000 is the scale value to make sure not show the exact location on map
            const scaleValue = showExactLocation === true ? undefined : 2500000;
            mapHandler.setLocation(generalizedLocation!.geometry!.coordinates, true, showCircleOnMap, scaleValue);
        }

        return () => {
            mapHandler.destroy();
        };
    }, [mapHandler]);

    const onSecondaryLocationValueChange = (val: string) => {
        if (!generalizedLocation) return;
        setGeneralizedLocation({
            ...generalizedLocation,
            featureLabelSecondary: val,
        });
    };

    // Need to show generalize text
    const getTextfieldVal = () => {
        if (hasErrors) {
            return t('catchDeclaration.generalizedLocation.generalizedError');
        }
        if (gettingLocation) {
            return t('catchDeclaration.generalizedLocation.generalizing');
        }
        if (!generalizedLocation || isEmpty(generalizedLocation?.featureLabel)) {
            return t('catchDeclaration.noLocationSetInfo');
        }
        return generalizedLocation.featureLabel;
    };

    return (
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
            <GeneralizedLocationInputField
                loadingLocation={gettingLocation}
                fieldLabel={t('catchDeclaration.location')}
                fieldValue={getTextfieldVal()}
                valid={!isEmpty(generalizedLocation?.featureLabel)}
                readonly={true}
            />
            {generalizedLocation && (
                <GeneralizedLocationInputField
                    loadingLocation={gettingLocation}
                    fieldLabel={t('catchDeclaration.waterRegion')}
                    fieldValue={generalizedLocation?.featureLabelSecondary ?? ''}
                    valid={!isEmpty(generalizedLocation?.featureLabelSecondary)}
                    readonly={!generalizedLocation || generalizedLocation?.secondaryValid === true}
                    onValueChange={onSecondaryLocationValueChange}
                />
            )}
        </Box>
    );
}
