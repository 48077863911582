import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { Gear, GearProperty, GearType } from '@oma-kala-shared/core/model';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getMandatoryOrOptionalTranslation } from 'app/logic/TranslationService';
import { appTheme } from 'app/theme';

export interface GearFormProps {
    gearState: Gear;
    gearNameValue: string;
    properties: GearProperty[];
    gearType: GearType;
    handleChangePropertyValue: (e: any) => void;
    handleChangeGearValue: (e: any) => void;
    handleSaveGear: (gear: Gear) => void;
    loading?: boolean;
    errors?: any;
}

const GearForm = ({
    gearState,
    gearNameValue,
    properties,
    gearType,
    handleChangePropertyValue,
    handleChangeGearValue,
    handleSaveGear,
    loading,
    errors,
}: GearFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [disableSave, setDisableSave] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (gearNameValue === '') {
            setDisableSave(true);
        } else setDisableSave(false);
    }, [gearNameValue]);

    useEffect(() => {
        const noErrors = Object.values(errors).every(value => value === '') && gearNameValue !== '';
        setDisableSave(!noErrors);
    }, [errors, gearNameValue]);

    const isRequired = (gearType: GearType, gearTypePropertyId: number): boolean => {
        const checkGear = gearType.properties.find(val => val.id === gearTypePropertyId);
        if (checkGear?.mandatory) {
            return checkGear?.mandatory;
        }
        return false;
    };

    const getPropertyName = (gearType: GearType, gearTypePropertyId: number): string => {
        const gear = gearType.properties.find(val => val.id === gearTypePropertyId);
        if (gear?.name) {
            return gear.name;
        }
        return '';
    };

    const getPropertyType = (gearType: GearType, gearTypePropertyId: number): string | undefined => {
        const gear = gearType.properties.find(val => val.id === gearTypePropertyId);
        if (gear?.dataType && gear.dataType === 'string') {
            return 'text';
        }
        return 'number';
    };

    const isIntegerType = (gearType: GearType, gearTypePropertyId: number): boolean => {
        const gear = gearType.properties.find(val => val.id === gearTypePropertyId);
        return (gear?.dataType && gear.dataType === 'integer') as boolean;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        handleSaveGear(gearState);
        setDisableSave(true);
        setTimeout(() => {
            navigate('/gear');
        }, 1000);
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                name="name"
                value={gearNameValue}
                label={getMandatoryOrOptionalTranslation(t('common.name'), true)}
                sx={{ m: 1, width: '100%' }}
                required={true}
                error={!gearNameValue || gearNameValue === ''}
                helperText={!gearNameValue || gearNameValue === '' ? t('common.requiredHelpText') : ''}
                onChange={handleChangeGearValue}
            />
            <TextField
                style={{ marginTop: 30 }}
                disabled
                label={t('gear.titleGearType')}
                sx={{ m: 1, width: '100%' }}
                value={capitalize(t(`gear.gearType.${gearType.name}.name`))}
                type={'text'}
                InputProps={{
                    readOnly: true,
                }}
            />
            {properties?.map((property, idx) => {
                const type = getPropertyType(gearType, property.gearTypePropertyId);
                return (
                    <div key={idx} style={{ paddingTop: 30 }}>
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <TextField
                                name={getPropertyName(gearType, property.gearTypePropertyId)}
                                type={type}
                                required={isRequired(gearType, property.gearTypePropertyId)}
                                value={property.value}
                                id={property.id}
                                error={
                                    errors[`${getPropertyName(gearType, property.gearTypePropertyId)}`] !== '' &&
                                    isRequired(gearType, property.gearTypePropertyId)
                                }
                                helperText={errors[`${getPropertyName(gearType, property.gearTypePropertyId)}`]}
                                onChange={handleChangePropertyValue}
                                inputProps={{
                                    endAdornment: <InputAdornment position="end">{property.unit}</InputAdornment>,
                                }}
                                label={getMandatoryOrOptionalTranslation(
                                    t('gear.editPropertyHeader', {
                                        gearType: gearType.name,
                                        propertyName: getPropertyName(gearType, property.gearTypePropertyId),
                                    }),
                                    isRequired(gearType, property.gearTypePropertyId)
                                )}
                                InputProps={
                                    type === 'number' && property.unit !== null
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <Typography>{property.unit}</Typography>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : undefined
                                }
                                onKeyDown={ev => {
                                    if (!isIntegerType(gearType, property.gearTypePropertyId)) {
                                        return;
                                    }
                                    if (ev.key === '.' || ev.key === ',') {
                                        ev.preventDefault();
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
                );
            })}
            <Box sx={buttonGroup}>
                <Button
                    onClick={() => {
                        navigate('/gear');
                    }}
                >
                    <ArrowBackIosIcon fontSize={'small'} />
                    {t('gear.allGear')}
                </Button>
                <Button disabled={disableSave} type={'submit'}>
                    {t('common.save')}
                </Button>
            </Box>
        </form>
    );
};

export default GearForm;

const buttonGroup = {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'space-between',
    '& > button': {
        backgroundColor: appTheme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(0,83,166,0.75)',
        },
    },
};
