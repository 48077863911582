import WebService from '@oma-kala-shared/core/logic/WebService';
import { CMSItem, CMSRequest, ContentResponse, GetCmsPostsRequest } from '@oma-kala-shared/core/model';
import i18n from 'i18next';

import { getCmsMessageCategory } from 'app/logic/CmsService';
import { RequestStatus } from 'app/model';
import { setCmsMessages } from 'app/state/cms/cmsSlice';
import { setErrorMessageWithTimeout } from 'app/state/message/messageSlice';
import { AppThunk, RootState } from 'app/state/store';

import { createWebService } from './thunkHelpers';

/**
 * Fetch elements from our CMS for the given language and load them into the application state.
 * @param {string} language
 * @return {AppThunk}
 */
export const fetchCmsMessages =
    (language: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const request: GetCmsPostsRequest = {
            fields: 'id,title,date,excerpt,content,tags',
            categories: getCmsMessageCategory(language).toString(),
            itemPerPage: 99,
        };
        // If server is accessable
        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<CMSItem[]> = await service.fetchCmsPosts(request);

        dispatch(setCmsMessages({ status: RequestStatus.Loading }));

        if (response.successful) {
            dispatch(setCmsMessages({ status: RequestStatus.Successful, resource: response.content }));
        } else {
            dispatch(setCmsMessages({ status: RequestStatus.Failed, message: response.message as string }));
            dispatch(setErrorMessageWithTimeout({ text: i18n.t('feedback.backendUnavailableMessage'), timeout: 1000000 }));
        }
    };
