import { IconButton } from '@mui/material';
import { appTheme } from 'app/theme';
import React, { useState } from 'react';

type MapControlButtonProps = {
    children: React.ReactNode;
    label: string;
    indicateOnSelected?: boolean;
    onSelected?: () => void;
};

const MapControlButton = ({ children, label, indicateOnSelected, onSelected }: MapControlButtonProps) => {
    const [selected, setSelected] = useState<boolean>(false);

    const handleOnSelect = () => {
        if (indicateOnSelected === true) {
            setSelected(previous => !previous);
        }

        if (onSelected) {
            onSelected();
        }
    };

    return (
        <IconButton
            style={{
                background: selected === true ? appTheme.palette.primary.main : 'white',
                color: selected === true ? 'white' : 'inherit',
                boxShadow: '5px 5px 10px -2px rgba(0,0,0,0.32)',
            }}
            aria-label={label}
            size="large"
            onClick={handleOnSelect}
        >
            {children}
        </IconButton>
    );
};

export default MapControlButton;
