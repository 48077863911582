import { ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { CMSItem } from '@oma-kala-shared/core/model';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandMoreButton } from 'app/components/common/ExpandMoreButton';
import { DrawerMenuItem } from 'app/model/Route';
import { appTheme } from 'app/theme';

import { DrawerItem } from './DrawerItem';
import { DrawerLink } from './DrawerLink';
import { DrawerFolderName } from './types';

interface DrawerFolderProps {
    title: string;
    allLinks: { [key in DrawerFolderName]: CMSItem[] };
    links: CMSItem[];
    menuItems: DrawerMenuItem[];
}

export const DrawerFolder = ({ title, menuItems, allLinks, links }: DrawerFolderProps) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <ListItem key={title} disablePadding sx={{ display: 'block', color: appTheme.palette.text.primary }}>
                <ListItemButton
                    onClick={handleExpandClick}
                    sx={{
                        minHeight: 48,
                        justifyContent: isDrawerOpen ? 'initial' : 'center',
                        alignItems: 'center',
                        px: 2.5,
                    }}
                >
                    <ExpandMoreButton
                        expand={expanded}
                        onClick={handleExpandClick}
                        sx={{
                            minWidth: 0,
                            mr: isDrawerOpen ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <ExpandMore />
                    </ExpandMoreButton>
                    <ListItemText primary={title} sx={{ opacity: isDrawerOpen ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ paddingLeft: 14 }}>
                    {menuItems.map((submenu, i) => {
                        if (submenu.subMenu && submenu.subMenu.length > 0) {
                            return (
                                <DrawerFolder
                                    title={submenu.name}
                                    menuItems={submenu.subMenu}
                                    key={i}
                                    allLinks={allLinks}
                                    links={allLinks[submenu.cmsLink as DrawerFolderName]}
                                />
                            );
                        } else {
                            return (
                                <DrawerItem
                                    key={i}
                                    isOpen={isDrawerOpen}
                                    path={submenu.path}
                                    title={submenu.name}
                                    icon={submenu.icon}
                                    dangerousHtmlAllowed={submenu.dangerousHtmlAllowed ?? false}
                                />
                            );
                        }
                    })}
                    {links &&
                        links.length > 0 &&
                        links.map((l, i) => {
                            return <DrawerLink key={i} isOpen={isDrawerOpen} link={l} />;
                        })}
                </List>
            </Collapse>
            <Divider />
        </>
    );
};
