import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { PASSWORD_MIN_LENGTH } from '@oma-kala-shared/core/logic';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedPasswordValidationResult } from 'app/model/ExtendedPasswordValidationResult';

interface PasswordValidationDisplayProps {
    passwordValidation: ExtendedPasswordValidationResult;
}

const PasswordValidationDisplay = ({ passwordValidation }: PasswordValidationDisplayProps) => {
    const { t } = useTranslation();
    const getValidationStyle = (conditionalValue: boolean) => {
        return conditionalValue ? successItem : errorItem;
    };
    const getValidationIndicator = (conditionalValue: boolean): JSX.Element => {
        return conditionalValue ? <CheckIcon /> : <CloseIcon />;
    };
    return (
        <Box sx={root}>
            <Typography sx={getValidationStyle(passwordValidation.length)}>
                {getValidationIndicator(passwordValidation.length)}
                {t('passwordProperties.validation.length', { minLength: PASSWORD_MIN_LENGTH })}
            </Typography>
            <Typography sx={getValidationStyle(passwordValidation.uppercase)}>
                {getValidationIndicator(passwordValidation.uppercase)} {t('passwordProperties.validation.uppercase')}
            </Typography>
            <Typography sx={getValidationStyle(passwordValidation.lowercase)}>
                {getValidationIndicator(passwordValidation.lowercase)} {t('passwordProperties.validation.lowercase')}
            </Typography>
            <Typography sx={getValidationStyle(passwordValidation.digit)}>
                {getValidationIndicator(passwordValidation.digit)} {t('passwordProperties.validation.digit')}
            </Typography>
            <Typography sx={getValidationStyle(passwordValidation.special)}>
                {getValidationIndicator(passwordValidation.special)} {t('passwordProperties.validation.special')}
            </Typography>
            <Typography sx={getValidationStyle(passwordValidation.passwordConfirmation)}>
                {getValidationIndicator(passwordValidation.passwordConfirmation)} {t('passwordProperties.validation.passwordConfirm')}
            </Typography>
            {passwordValidation.isNewPasswordDifferentThanNewOne !== undefined && (
                <Typography sx={getValidationStyle(passwordValidation.isNewPasswordDifferentThanNewOne)}>
                    {getValidationIndicator(passwordValidation.isNewPasswordDifferentThanNewOne)}{' '}
                    {t('passwordProperties.validation.isNewPasswordDifferentThanNewOne')}
                </Typography>
            )}
        </Box>
    );
};

export default PasswordValidationDisplay;

const root = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
};

const successItem = {
    color: 'green',
    textAlign: 'center',
};

const errorItem = {
    color: '#c62107',
    textAlign: 'center',
};
