import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import React from 'react';

import { Footer } from 'app/components/layout/footer/Footer';

import { LazyBackground } from '../home/LazyBackground';
import LandingHeader from './LandingHeader';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface LandingWrapperProps {
    visibleLoginButton: boolean;
    visibleBackButton: boolean;
    main?: JSX.Element;
    content?: JSX.Element;
}

/**
 * @return {JSX.Element}
 */
export default function LandingWrapper({ visibleLoginButton, main, content, visibleBackButton }: LandingWrapperProps) {
    const { t, i18n } = useTranslation();
    return (
        <div style={{ height: '100%' }}>
            <LazyBackground>
                <LandingHeader visibleLoginButton={visibleLoginButton} />
                {visibleBackButton && (
                    <LogoLink to={'/'}>
                        <Button
                            sx={{ color: 'white', fontSize: 20, fontWeight: 700 }}
                            startIcon={
                                <ArrowBackIcon
                                    sx={{
                                        fontSize: 30,
                                        fontWeight: 700,
                                        background: 'rgba(255, 255, 255, 0.12)',
                                        borderRadius: '50%',
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            }
                        >
                            {t('common.back')}
                        </Button>
                    </LogoLink>
                )}
                {main}
            </LazyBackground>
            {content}
            <Footer />
        </div>
    );
}

const LogoLink = styled(Link)({
    textDecoration: 'none',
    paddingLeft: '80px',
    paddingTop: '20px',
});
