import { isExternalIdentityProviderToken } from '@oma-kala-shared/core/logic';
import { TokenData, UserData } from '@oma-kala-shared/core/model';
import { selectLoggedInUserData, selectTokenData } from 'app/state/user/userSlice';
import { useSelector } from 'react-redux';

// Define a type for the return value of the hook
type AuthData = {
    token: TokenData | null;
    userData: UserData | null;
    isExternalIdpToken: boolean;
};

// Define a custom hook that returns the auth data from the store
const useAuthData = (): AuthData => {
    // Use the useSelector hook to access the store state
    const token: TokenData | null = useSelector(selectTokenData);
    const userData: UserData | null = useSelector(selectLoggedInUserData);
    const isExternalIdpToken = token ? isExternalIdentityProviderToken(token) : false;

    // Return the auth data as an object
    return { token, userData, isExternalIdpToken };
};

export default useAuthData;
