import { Box } from '@mui/material';
import { Map } from '@oma-kala/map';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import * as ol from 'ol';
import MapPanel from './panel/MapPanel';
import BaseLayer from 'ol/layer/Base';
import { useSelector } from 'react-redux';
import { selectMapLayerSettings } from 'app/state/mapLayer/mapLayerSlice';
import MapContext from './MapContext';

export const MapWrapper = (props: { setMap: Dispatch<SetStateAction<ol.Map | null>> }) => {
    const mapRef = useRef();
    const mapLayerSettings = useSelector(selectMapLayerSettings);
    const globalMap = useContext(MapContext);
    const mapWrapperRef = useRef();

    useEffect(() => {
        if (mapRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const instance = (mapRef.current as any).getMap();
            props.setMap(instance);
        }
        return () => {
            props.setMap(null);
        };
    }, [props.setMap]);

    useEffect(() => {
        if (!globalMap) {
            return;
        }

        Object.entries(mapLayerSettings).forEach(([layer, setting]) => {
            const mapLayer = findLayer(globalMap, layer);

            if (mapLayer) {
                mapLayer.setVisible(setting.visible);
            }
        });
    }, [mapLayerSettings, globalMap]);

    return (
        <Box
            ref={mapWrapperRef}
            flex={1}
            sx={{
                position: 'relative',
                containerName: 'map-wrapper',
                containerType: 'size',
                '@container map-wrapper (min-width: 560px)': {
                    '.search-panel': {
                        width: 'calc(100% - 275px) !important',
                    },
                    '.setting-container': {
                        width: '230px',
                        right: 0,
                        left: 'unset',
                        margin: 'unset',
                        marginRight: '15px',
                        marginTop: '10px',
                    },
                },
                '@container map-wrapper (min-width: 700px)': {
                    '.search-panel': {
                        width: '380px !important',
                    },
                },
            }}
        >
            <MapPanel />
            <Map ref={mapRef} initialZoom={6} />
        </Box>
    );
};

/**
 * This function is used to find the map layer based on the id
 * Later if we need to move it into the omakala-map repo, we can
 * refactor also the native-map repo to use the same function.
 * Right now it is enough to put it here
 *
 * @param {ol.Map} map
 * @param {string} layerId
 *
 * @return  {?ol.layer.Base} the found layer or null
 */
const findLayer = (map: ol.Map, layerId: string): BaseLayer | null => {
    const layers: BaseLayer[] = map.getLayers().getArray();
    let layer: BaseLayer | null = null;
    for (let i = 0; i < layers.length; i++) {
        const layerProperties = layers[i].getProperties();
        if (layerProperties.id === layerId) {
            layer = layers[i];
            break;
        }
    }
    return layer;
};
