import { DeclarationCatchDetails } from '@oma-kala-shared/core/model';
import React from 'react';

export type DeclarationCatchListProps = {
    catches: DeclarationCatchDetails[];
    sorter: (a: DeclarationCatchDetails, b: DeclarationCatchDetails) => number;
    itemRenderer: (catchDetail: DeclarationCatchDetails, index: number) => React.ReactElement;
};
const DeclarationCatchList = ({ catches, sorter, itemRenderer }: DeclarationCatchListProps) => {
    let sortedCatches = [...catches];
    if (sorter && catches.length > 1) {
        sortedCatches = sortedCatches.sort(sorter);
    }

    return (
        <>
            {sortedCatches.map((catchDetail: DeclarationCatchDetails, index: number) => {
                return itemRenderer(catchDetail, index);
            })}
        </>
    );
};

DeclarationCatchList.sortByDate = (a: DeclarationCatchDetails, b: DeclarationCatchDetails) => {
    const dateA = new Date(a.catchTime).getTime();
    const dateB = new Date(b.catchTime).getTime();
    return dateB - dateA;
};

export default DeclarationCatchList;
