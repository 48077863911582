import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PushNotification } from 'app/model/Notification';
import { RootState } from 'app/state/store';

export interface NotificationState {
    notifications: PushNotification[];
}

export const initialState: NotificationState = {
    notifications: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setNotifications: (state: NotificationState, action: PayloadAction<PushNotification[]>) => {
            state.notifications = action.payload;
        },
    },
});

export const { setNotifications } = notificationSlice.actions;

export const selectNotifications = (state: RootState): PushNotification[] => state.notification.notifications;

export default notificationSlice.reducer;
