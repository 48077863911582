import { Box, Paper } from '@mui/material';
import React from 'react';
import MapLayerSetting from './layer-setting/MapLayerSettingContainer';

type MapControlButtonProps = {
    settingName: string;
};

const MapSettingContainer = ({ settingName }: MapControlButtonProps) => {
    const getSettingComponent = (settingName: string) => {
        return <MapLayerSetting />;
    };

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                width: '90%',
                maxHeight: '40vh',
                padding: '12px',
                boxShadow: '-8px 8px 6px 8px rgba(0,0,0,0.2)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                marginTop: '10px',
                zIndex: 3,
            }}
            className="setting-container"
        >
            {getSettingComponent(settingName)}
        </Box>
    );
};

export default MapSettingContainer;
