/* eslint-disable no-unused-vars */
export enum OmaKalaUserRole {
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR',
    RECREATIONAL_FISHER = 'RECREATIONAL_FISHER',
    SVK_USER = 'SVK_USER',
    ORGANIZER = 'ORGANIZER',
}

/**
 * Represents the data we get as user roles. The code is a constant
 */
export interface UserRole {
    code: OmaKalaUserRole;
    roleId: string;
    name: string;
}
