import { Box, Divider, Typography } from '@mui/material';
import { LayerSettings, MapLayer } from '@oma-kala-shared/core/model';
import React from 'react';
import { MapLayerSetting } from './MapLayerSetting';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMapLayerSettings } from 'app/state/mapLayer/mapLayerSlice';

const MapLayerSettingContainer = () => {
    const mapLayerSettings = useSelector(selectMapLayerSettings);
    const { t } = useTranslation();

    const allowLayers = [MapLayer.TERRAIN_LAYER, MapLayer.VECTOR_LAYER, MapLayer.ORTHOPHOTO_LAYER, MapLayer.DEPTH_CURVES];

    const baseLayers: Record<string, LayerSettings> = {};
    const normalLayers: Record<string, LayerSettings> = {};

    for (const [layer, setting] of Object.entries(mapLayerSettings)) {
        if (setting.isBaseLayer) {
            baseLayers[layer] = setting;
        } else if (allowLayers.includes(layer as MapLayer)) {
            normalLayers[layer] = setting;
        }
    }

    return (
        <Box>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {t('map.settings.mapLayer.title')}
            </Typography>

            {Object.entries(normalLayers).map(([layer, layerSetting]) => (
                <MapLayerSetting key={layer} layer={layer as MapLayer} layerSettings={layerSetting} />
            ))}

            <Divider />

            {Object.entries(baseLayers).map(([layer, layerSetting]) => (
                <MapLayerSetting key={layer} layer={layer as MapLayer} layerSettings={layerSetting} />
            ))}
        </Box>
    );
};

export default MapLayerSettingContainer;
