import { CMSCategory, CMSItem, CMSTag, LocalizedTags, OmaKalaLanguageCode } from '@oma-kala-shared/core/model';
import { intersection } from 'lodash';

/**
 * Get the corresponding CMSCategory for a language
 * @param {string} language
 * @return {CMSCategory}
 */
export function getCmsLinkCategory(language: string): CMSCategory {
    switch (language) {
        case OmaKalaLanguageCode.EN:
            return CMSCategory.LINKKI_EN;
        case OmaKalaLanguageCode.FI:
            return CMSCategory.LINKKI_FI;
        case OmaKalaLanguageCode.SV:
            return CMSCategory.LINKKI_SV;
        case OmaKalaLanguageCode.SE:
            return CMSCategory.LINKKI_SE;
        default:
            return CMSCategory.LINKKI_FI;
    }
}

/**
 * Get the corresponding CMSCategory for a language
 * @param {string} language
 * @return {CMSCategory}
 */
export function getCmsElementCategory(language: string): CMSCategory {
    switch (language) {
        case OmaKalaLanguageCode.EN:
            return CMSCategory.ELEMENTTI_EN;
        case OmaKalaLanguageCode.FI:
            return CMSCategory.ELEMENTTI_FI;
        case OmaKalaLanguageCode.SV:
            return CMSCategory.ELEMENTTI_SV;
        case OmaKalaLanguageCode.SE:
            return CMSCategory.ELEMENTTI_SE;
        default:
            return CMSCategory.ELEMENTTI_FI;
    }
}

/**
 * Get the corresponding CMSCategory for a language
 * @param {string} language
 * @return {CMSCategory}
 */
export function getCmsMessageCategory(language: string): CMSCategory {
    switch (language) {
        case OmaKalaLanguageCode.EN:
            return CMSCategory.TIEDOTE_EN;
        case OmaKalaLanguageCode.FI:
            return CMSCategory.TIEDOTE_FI;
        case OmaKalaLanguageCode.SV:
            return CMSCategory.TIEDOTE_SV;
        case OmaKalaLanguageCode.SE:
            return CMSCategory.TIEDOTE_SE;
        default:
            return CMSCategory.TIEDOTE_FI;
    }
}

/**
 * Get the corresponding CMSCategory for a language
 * @param {string} language
 * @return {CMSCategory}
 */
export function getCmsPageCategory(language: string): CMSCategory {
    switch (language) {
        case OmaKalaLanguageCode.EN:
            return CMSCategory.SIVU_EN;
        case OmaKalaLanguageCode.FI:
            return CMSCategory.SIVU_FI;
        case OmaKalaLanguageCode.SV:
            return CMSCategory.SIVU_SV;
        case OmaKalaLanguageCode.SE:
            return CMSCategory.SIVU_SE;
        default:
            return CMSCategory.SIVU_FI;
    }
}

/**
 * Get the CMS tag value for a specific language. Finnish is fallback
 * @param {string} language
 * @param {LocalizedTags} tagset
 * @return {CMSTag}
 */
export function getLocalizedLinkTag(language: string, tagset: LocalizedTags): CMSTag {
    switch (language) {
        case OmaKalaLanguageCode.EN:
            return tagset.english || tagset.finnish;
        case OmaKalaLanguageCode.SV:
            return tagset.swedish || tagset.finnish;
        case OmaKalaLanguageCode.SE:
            return tagset.sami || tagset.finnish;
        default:
            return tagset.finnish;
    }
}

/**
 * Filters CMS items by the given tag.
 * @param {CMSItem[]} items
 * @param {CMSTag} tags
 * @return {CMSItem[]}
 */
export function filterCmsItemsByTag(items: CMSItem[], ...tags: CMSTag[]): CMSItem[] {
    return items.filter((i: CMSItem) => intersection(i.tags ?? [], tags).length > 0);
}

/**
 * Get default tagsets for cms
 *
 * @return {LocalizedTags} Default tagsets
 */
export function getDefaultTagsets(): LocalizedTags {
    return {
        finnish: CMSTag.ELEMENT_HOME_PAGE_FI,
        swedish: CMSTag.ELEMENT_HOME_PAGE_SV,
        english: CMSTag.ELEMENT_HOME_PAGE_EN,
        sami: CMSTag.ELEMENT_HOME_PAGE_SE,
    };
}

/**
 * Filters CMS items by the given tag.
 * @param {CMSItem} item
 * @return {boolean}
 */
export function isLinkTypeLoggedInOnly(item: CMSItem): boolean {
    return (
        intersection(item.tags ?? [], [
            CMSTag.FLAG_SIGNEDIN_ONLY_FI,
            CMSTag.FLAG_SIGNEDIN_ONLY_SV,
            CMSTag.FLAG_SIGNEDIN_ONLY_EN,
            CMSTag.FLAG_SIGNEDIN_ONLY_SE,
        ]).length > 0
    );
}
