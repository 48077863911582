import { Box, Grid, styled } from '@mui/material';
import React from 'react';

const BottomBarWrapper = styled('div')({
    backgroundColor: '#000',
    color: '#fff',
    padding: '12px 24px',
});

/**
 * @return {JSX.Element}
 */
export function BottomBar() {
    return (
        <BottomBarWrapper>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <div>© Luonnonvarakeskus (Luke)</div>
                    </Grid>
                </Grid>
            </Box>
        </BottomBarWrapper>
    );
}
