import { createGearDirtyInfo, mapGearToUpdateRequest } from '@oma-kala-shared/core/logic';
import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, Gear, GearHeader } from '@oma-kala-shared/core/model';
import i18n from 'i18next';

import { RequestStatus } from 'app/model';
import { setSuccessMessage } from 'app/state/message/messageSlice';

import { setErrorMessage } from '../message/messageSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';
import { updateUserGear } from './updateUserGear';

/**
 * Updates the specified gear
 * @param {string} syncToken
 * @param {Gear} old
 * @param {Gear} current
 * @return {AppThunk}
 */
export const updateGear =
    (syncToken: string, old: Gear, current: Gear): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.log('updating gear', syncToken);
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const dirtyInfo = createGearDirtyInfo(old, current);
        const request = mapGearToUpdateRequest(current, dirtyInfo);
        const response: ContentResponse<GearHeader> = await service.updateUserGear(current.userId, request);

        if (response.successful) {
            const updatedGear: Gear = {
                ...response.content,
                properties: current.properties,
            };
            dispatch(updateUserGear(updatedGear));
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        message: i18n.t('gear.gearUpdated'),
                    },
                })
            );
            dispatch(setSuccessMessage(i18n.t('gear.gearUpdated')));
        } else {
            console.warn('update gear request failed with message:', response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: i18n.t('gear.callFailed'),
                    },
                })
            );
            dispatch(setErrorMessage(i18n.t('gear.callFailed')));
        }
    };
