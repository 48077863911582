/* eslint-disable new-cap */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, CircularProgress, Divider, IconButton, InputAdornment, Link, Modal, TextField, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import { CreateTokenDataFromExternalTokenResponse } from '@oma-kala-shared/core/logic';
import { ExternalTokenResponse, TokenData } from '@oma-kala-shared/core/model';
import { SvkErrorCode } from '@oma-kala-shared/core/model/Svk';
import { getSetting, storeSetting } from 'app/logic/StorageService';
import { useSyncToken } from 'app/hooks';
import { RoutePath } from 'app/model/Route';
import { setAlert, setErrorMessage } from 'app/state/message/messageSlice';
import { loginUser, loginUserWithSvk } from 'app/state/thunks';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import svkLogo from 'resources/images/svk-logo.png';
import fishIcon from 'resources/images/omakala-logo-black-no-text.png';
import { CookieDialog } from '../home/CookieDialog';
import { MessageType } from 'app/model';

const LoginFormWrapper = styled('div')({
    display: 'flex',
    width: 600,
    justifyContent: 'center',
    alignItems: 'center',
});

const FormInput = styled('div')({
    marginBottom: '12px',
    width: '100%',
});

interface LoginState {
    password: string;
    email: string;
    showPassword: boolean;
}

/**
 * @return {JSX.Element}
 */
export function LoginCard() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // This is used when  user is already registered, because in that case the registration page redirects here
    const showSvkMessageOnLoad = location?.state?.showSvkMessage ?? false;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const { i18n } = useTranslation();

    const [cookies] = useCookies(['userId', 'analyticsSelectCookie']);
    const forgottenPasswordIdentificationUrl =
        process.env.REACT_APP_BASE_URL +
        `/v1/identification?RelayState=${window.location.origin + RoutePath.RESET_PASSWORD}&lang=${i18n.language}`;
    const registrationIdentificationUrl =
        process.env.REACT_APP_BASE_URL +
        `/v1/identification?RelayState=${window.location.origin + RoutePath.REGISTRATION}&lang=${i18n.language}`;

    const loginSvkRoute =
        process.env.REACT_APP_BASE_URL + `/v1/auth/provider/vitec/login?returnUrl=${window.location.origin + RoutePath.LOGIN}`;

    // Create a sync token that can be used to track async tasks.
    // 'loading' signals if the task is currently in progress
    const [syncToken, loading] = useSyncToken({
        onSuccess: () => {
            setState({
                password: '',
                email: '',
                showPassword: false,
            }),
                setCookieDialogState(cookies.userId === undefined);
        },
        onError: message => {
            console.log(message);
            dispatch(setErrorMessage(t('common.loginError', { message: message })));
        },
    });

    const [svkSyncToken, svkLoading] = useSyncToken({
        onSuccess: () => {},
        onError: message => {
            console.log(message);
            dispatch(setErrorMessage(t('common.loginError', { message: message })));
        },
    });

    const [state, setState] = useState<LoginState>({
        password: '',
        email: '',
        showPassword: false,
    });

    const [cookieDialogState, setCookieDialogState] = useState(false);
    const [loadingSvk, setLoadingSvk] = useState<boolean>(false);
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramArray = Array.from(queryParams.keys());
        if (paramArray.length === 0) {
            return;
        }
        const accessToken = queryParams.get('access_token');
        const refreshToken = queryParams.get('refresh_token');
        const expiresIn = queryParams.get('expires_in');
        const success = queryParams.get('success');
        const error = queryParams.get('error_code');

        if (success === 'false') {
            let errorMsg = '';
            switch (error) {
                case SvkErrorCode.SVK_VALIDATION_FAILED:
                    errorMsg = t('errorDuringSvkLogin.title');
                    break;
                default:
                    errorMsg = t('unknownSvkLoginError.title');
            }
            // Add errorMsg in message/dialog box
            dispatch(
                setAlert({
                    text: errorMsg,
                    type: MessageType.ERROR,
                })
            );
            return;
        }

        if (accessToken !== 'null' && accessToken !== null && success !== 'false') {
            const tokenResponse: ExternalTokenResponse = {
                access_token: accessToken,
                refresh_token: refreshToken ?? '',
                expires_in: expiresIn ?? '',
                success: true,
            };

            const tokenData: TokenData = CreateTokenDataFromExternalTokenResponse(tokenResponse);
            setLoadingSvk(true);
            dispatch(loginUserWithSvk(svkSyncToken, tokenData));
        } else {
            console.error('Invalid SVK token');
            dispatch(setErrorMessage(t('common.loginError')));
        }
    }, [location.search]);

    useEffect(() => {
        if (showSvkMessageOnLoad) {
            setIsModalVisible(true);
        }
    }, []);

    const onValueChanged = (key: keyof LoginState) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState(prev => ({ ...prev, [key]: e.target.value }));
    };

    const handleClickShowPassword = () => {
        setState(prev => ({ ...prev, showPassword: !prev.showPassword }));
    };

    const doLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(loginUser(syncToken, state.email, state.password));
    };

    const handleLoginWithSvk = () => {
        storeSvkTempErrorShownValue();
        setLoadingSvk(true);
        window.location.href = loginSvkRoute;
    };

    const hideSvkModal = () => {
        setIsModalVisible(false);
    };

    const showSvkModal = async () => {
        const svkTempErrorShown = await readSvkTempErrorShownValue();
        if (!svkTempErrorShown) {
            setIsModalVisible(true);
            return;
        }
        handleLoginWithSvk();
    };

    const storeSvkTempErrorShownValue = async () => {
        await storeSetting('SvkTempErrorShown', 'true');
    };

    const readSvkTempErrorShownValue = async () => {
        return await getSetting('SvkTempErrorShown');
    };

    return (
        <LoginFormWrapper>
            <Modal
                open={isModalVisible}
                onClose={hideSvkModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('common.svkTempIssue.title')}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {t('common.svkTempIssue.content')}
                    </Typography>
                    <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', mt: 2 }}>
                        {!showSvkMessageOnLoad ? (
                            <>
                                <Button variant="outlined" sx={{ width: '100%' }} onClick={hideSvkModal}>
                                    {t('common.svkTempIssue.cancel')}
                                </Button>
                                <Button variant="contained" sx={{ width: '100%' }} onClick={handleLoginWithSvk}>
                                    {t('common.svkTempIssue.continue')}
                                </Button>
                            </>
                        ) : (
                            <Button variant="contained" sx={{ width: '100%' }} onClick={hideSvkModal}>
                                {t('common.svkTempIssue.ok')}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>
            <Box width={'100%'} sx={{ background: 'white', padding: 5, borderRadius: 2 }}>
                <form onSubmit={doLogin}>
                    <FormInput>
                        <TextField
                            id="email"
                            label={t('common.email')}
                            variant="outlined"
                            fullWidth
                            value={state.email}
                            onChange={onValueChanged('email')}
                        />
                    </FormInput>
                    <FormInput>
                        <TextField
                            id="password"
                            label={t('common.password')}
                            variant="outlined"
                            type={state.showPassword ? 'text' : 'password'}
                            fullWidth
                            value={state.password}
                            onChange={onValueChanged('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormInput>
                    <FormInput>
                        <Button
                            id="submit"
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth
                            disabled={loading || state.email.length < 1 || state.password.length < 1}
                        >
                            <Typography>{t('common.login')}</Typography>
                            {loading && <CircularProgress color="secondary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                        </Button>
                    </FormInput>
                </form>
                <Box
                    mb={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography>{t('common.loginDelimiterText').toUpperCase()}</Typography>
                </Box>
                <Box mb={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: loadingSvk ? '#0000001f' : '#00BCE7', color: 'white', border: 'none' }}
                        type="submit"
                        color="primary"
                        onClick={showSvkModal}
                        disabled={loadingSvk}
                    >
                        <img src={svkLogo} height="25" alt={'svk-logo'} /> &nbsp;
                        <Typography>{t('common.loginWithSvk')}</Typography>
                        {loadingSvk && <CircularProgress color="secondary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                    </Button>
                </Box>
                <Box
                    mb={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Divider sx={{ backgroundColor: 'black', width: '45%' }} />
                    <img src={fishIcon} height="40" width="40" alt={'svk-logo'} />
                    <Divider sx={{ backgroundColor: 'black', width: '45%' }} />
                </Box>
                <Box mb={2}>
                    <Button fullWidth variant="outlined" href={registrationIdentificationUrl}>
                        <Typography>{t('registration.title')}</Typography>
                    </Button>
                </Box>
                <Box width={'100%'} textAlign={'center'} pt={1}>
                    <Link underline="hover" href={forgottenPasswordIdentificationUrl}>
                        <Typography>{t('passwordProperties.forgotPassword')}</Typography>
                    </Link>
                </Box>
            </Box>
            <CookieDialog onClose={() => setCookieDialogState(false)} isOpen={cookieDialogState} />
        </LoginFormWrapper>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: '#fff',
    p: 4,
};
