import { addNewEntity } from '@oma-kala-shared/core/logic';
import { Gear, LocalStateGear } from '@oma-kala-shared/core/model';

import { selectUserGearLocalState, setUserGear } from '../../state/gear/gearSlice';
import { AppThunk, RootState } from '../store';

// import { syncUserGear } from './syncUserGear';

/**
 * Add a new gear to state and trigger a sync with the back-end
 * @param {Gear} newGear New gear
 * @return {AppThunk} Dispatchable thunk
 */
export const addUserGear =
    (newGear: Gear): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const gearState: LocalStateGear[] = selectUserGearLocalState(getState());
        const newGearState: LocalStateGear[] = addNewEntity(gearState, newGear);
        dispatch(setUserGear(newGearState));
        // TODO: Implement sync after MVP
        // dispatch(syncUserGear());
    };
