import { SxProps } from '@mui/system';
import { UnitConfiguration } from '@oma-kala-shared/core/model';
import { Moment } from 'moment';

export type FieldValueType = string | number | Moment | boolean;

export type SelectFieldItem = {
    value: string | number;
    label: string;
};

export enum DYNAMIC_FIELD_TYPE {
    STRING = 'string',
    INTEGER = 'integer',
    DATE = 'date',
    SELECT = 'select',
    FLOAT = 'float',
    CHECKBOX = 'checkbox',
}

export type UnitType = string[];

// export type UnitType = WEIGHT_UNIT_TYPE | LENGTH_UNIT_TYPE;

type GridFieldBase = {
    id: number;
    name: string;
    type: DYNAMIC_FIELD_TYPE;
    containerSize?: number;
    inputSize?: string;
    value: FieldValueType;
    required?: boolean;
    label?: string;
    sx?: SxProps;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    validationRule?: string;
    warningText?: string;
};

export type GridSelectField = GridFieldBase & {
    type: DYNAMIC_FIELD_TYPE.SELECT;
    items: SelectFieldItem[];
};

export type GridUnitField = GridFieldBase & {
    unit: string | null;
    unitConfiguration?: UnitConfiguration;
};

export type GridField = GridFieldBase | GridSelectField | GridUnitField;

export type BaseFieldProps = {
    field: GridField;
    onChange: (fieldName: string, newValue: FieldValueType) => void;
    error?: boolean;
    helperText?: React.ReactNode | string | null;
};

export type GridValue = Record<string, FieldValueType> | null;

export type GridFields = GridField[] | GridSelectField[];

export type DynamicFieldRef = {
    validate: () => void;
    getValue: () => FieldValueType | null;
    setError: (errors: string[] | null) => void;
};

export type DynamicGridError = { [key: string]: string };

export type DynamicGridRef = {
    validate: () => boolean;
    getValue: () => GridValue | null;
    setError: (error: DynamicGridError) => void;
};
