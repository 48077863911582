import { getUnreadNewsStatsResponse, News } from '@oma-kala-shared/core/model/News';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../store';
import { OmaKalaLanguageCode } from '@oma-kala-shared/core/model';
import { clearSetting, getSetting, storeSetting } from 'app/logic/StorageService';

export interface NewsState {
    loadingNews: boolean;
    loadNextNews: boolean;
    newsList: News[];
    offset: number;
    hasMoreNews: boolean;
    newsDetails: News | null;
    unreadMessageStats: getUnreadNewsStatsResponse | null;
}

export const unreadNewsStatsKey = 'UNREAD_NEWS_STATS';

export type UnreadNewsStatsStorageKey = typeof unreadNewsStatsKey;

const unreadNewsStatsStoredInStorage = getSetting(unreadNewsStatsKey);

const unreadNewsStatsRestoredInStorage =
    unreadNewsStatsStoredInStorage === null ? null : (JSON.parse(unreadNewsStatsStoredInStorage) as getUnreadNewsStatsResponse);

export const initialState: NewsState = {
    loadingNews: false,
    loadNextNews: false,
    newsList: [],
    offset: 0,
    hasMoreNews: true,
    newsDetails: null,
    unreadMessageStats: unreadNewsStatsRestoredInStorage,
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsList: (state: NewsState, action: PayloadAction<News[]>) => {
            state.newsList = action.payload;
            state.loadingNews = false;
            state.hasMoreNews = true;
            if (action.payload.length < 6) {
                state.hasMoreNews = false;
            }
        },
        setNextNewsList: (state: NewsState, action: PayloadAction<News[]>) => {
            state.newsList = [...state.newsList, ...action.payload];
            state.hasMoreNews = true;
            if (action.payload.length < 6) {
                state.hasMoreNews = false;
            }
            state.loadNextNews = false;
        },
        setLoadingNextNews: (state: NewsState, action: PayloadAction<boolean>) => {
            state.loadNextNews = action.payload;
        },
        setOffset: (state: NewsState, action: PayloadAction<number>) => {
            state.offset = action.payload;
        },
        reSetOffset: (state: NewsState) => {
            state.offset = 0;
        },
        setNewsDetails: (state: NewsState, action: PayloadAction<News | null>) => {
            state.newsDetails = action.payload;
        },
        setUnreadMessageCount: (state: NewsState, action: PayloadAction<getUnreadNewsStatsResponse | null>) => {
            state.unreadMessageStats = action.payload;

            if (action.payload) {
                storeSetting(unreadNewsStatsKey, JSON.stringify(action.payload));
            } else {
                clearSetting(unreadNewsStatsKey);
            }
        },
    },
});

export const setNewsList =
    (newsList: News[]): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(newsSlice.actions.setNewsList(newsList));
    };

export const setNextNewsList =
    (newsList: News[]): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(newsSlice.actions.setNextNewsList(newsList));
    };

export const setOffset =
    (offset: number): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(newsSlice.actions.setOffset(offset));
    };
export const reSetOffset = (): AppThunk => async (dispatch: any, getState: () => RootState) => {
    dispatch(newsSlice.actions.reSetOffset());
};

export const setLoadingNextNews =
    (status: boolean): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(newsSlice.actions.setLoadingNextNews(status));
    };

export const setNewsDetails =
    (news: News | null): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(newsSlice.actions.setNewsDetails(news));
    };

export const { setUnreadMessageCount } = newsSlice.actions;

export const selectNewsList = (state: RootState): News[] => state.news.newsList;
export const selectOffset = (state: RootState): number => state.news.offset;
export const selectHasMoreNews = (state: RootState): boolean => state.news.hasMoreNews;
export const selectNewsDetails = (state: RootState): News | null => state.news.newsDetails;
export const selectLoadingNext = (state: RootState): boolean => state.news.loadNextNews;
export const selectUnreadNewsStats = (state: RootState): getUnreadNewsStatsResponse | null => state.news.unreadMessageStats;

export default newsSlice.reducer;
