import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { Consent, ConsentData, OmaKalaLanguageCode } from '@oma-kala-shared/core/model';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import useWebConsents from 'app/hooks/useWebConsents';
import { appTheme } from 'app/theme';
import { getConsentDisplayText } from '@oma-kala-shared/core/logic/CommonService';

/**
 * @param {any} props
 * @return {JSX.Element}
 */
export function SaveToPrivateCatchLogConsent() {
    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const { webConsents, didUserGiveConsentOpinion, setConsentStatus } = useWebConsents();

    const selectedConsentIdentifier = 'SAVE_DECLARATION_TO_CATCH_LOG';

    useEffect(() => {
        if (webConsents === null || didUserGiveConsentOpinion(selectedConsentIdentifier) || isConfirmed) {
            return;
        }

        setShowModal(true);
    }, [webConsents]);

    if (webConsents === null) {
        return <></>;
    }

    const selectedConsent = webConsents!.find((consent: ConsentData) => consent.consentId === selectedConsentIdentifier);

    const handleConfirm = (isAccepted: boolean) => {
        setShowModal(false);
        setIsConfirmed(true);
        setConsentStatus(selectedConsent!, isAccepted);
    };

    return (
        <Dialog
            open={showModal}
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { width: '600px', maxWidth: '600px', padding: '0px' } }}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        >
            <DialogContent style={{ padding: '10px' }}>
                {selectedConsent && (
                    <>
                        <Grid
                            container
                            spacing={6}
                            sx={{
                                padding: '14px',
                            }}
                        >
                            <Grid item xs={1}>
                                <WarningIcon fontSize="large" sx={{ color: appTheme.palette.secondary.light }} />
                            </Grid>
                            <Grid
                                item
                                xs={11}
                                sx={{
                                    paddingLeft: '20px',
                                }}
                            >
                                <Box>
                                    <Typography variant="h5" gutterBottom>
                                        Note
                                    </Typography>
                                </Box>
                                <Box>
                                    <p>{getConsentDisplayText(selectedConsent, i18n.language as OmaKalaLanguageCode)}</p>
                                </Box>
                                <Box
                                    sx={{
                                        marginY: '10px',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleConfirm(false)}
                                        sx={{ width: '100px', marginRight: '8px' }}
                                    >
                                        {t('common.no')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleConfirm(true)}
                                        sx={{ width: '100px', marginRight: '8px' }}
                                    >
                                        {t('common.yes')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
                {!selectedConsent && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
            </DialogContent>
        </Dialog>
    );
}
