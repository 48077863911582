import { CMSTag } from '@oma-kala-shared/core/model';
import React from 'react';

import { CmsElementPage } from 'app/components/layout';

/**
 * @return {JSX.Element}
 */
export function FaqPage() {
    return (
        <CmsElementPage
            tagset={{
                finnish: CMSTag.ELEMENT_FAQ_PAGE_FI,
                swedish: CMSTag.ELEMENT_FAQ_PAGE_SV,
                english: CMSTag.ELEMENT_FAQ_PAGE_EN,
                sami: CMSTag.ELEMENT_FAQ_PAGE_SE,
            }}
        />
    );
}
