import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse } from '@oma-kala-shared/core/model';
import { News } from '@oma-kala-shared/core/model/News';
import i18next from 'i18next';

import { RequestStatus } from 'app/model';

import { setLoadingNextNews, setNewsList, setNextNewsList, setOffset } from '../news/newsSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { selectTokenData } from '../user/userSlice';
import { createWebService } from './thunkHelpers';

/**
 * Fetch news from the back-end.
 *
 * @param {string} syncToken
 * @param {number} offset
 * @param {number} limit
 * @return {AppThunk} Dispatchable thunk
 */
export const fetchNews =
    (syncToken: string, offset: number, limit: number): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const language = i18next.language;
        if (syncToken) {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));
        }
        const webService: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<News[]> = await webService.getNews(language, offset, limit);

        if (response.successful) {
            const newsState: News[] = response.content;
            dispatch(setNewsList(newsState));
            dispatch(setOffset(offset + 6));
            if (syncToken) {
                dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
            }
        } else {
            console.log('Fetching news failed: ' + response.message);
            if (syncToken) {
                dispatch(
                    setSyncState({
                        syncToken,
                        value: { status: RequestStatus.Failed, message: response.message as string },
                    })
                );
            }
        }
    };

/**
 * Fetch news from the back-end.
 *
 * @param {string} syncToken
 * @param {number} offset
 * @param {number} limit
 * @return {AppThunk} Dispatchable thunk
 */
export const fetchNextNews =
    (syncToken: string, offset: number, limit: number): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setLoadingNextNews(true));
        const language = i18next.language;
        if (syncToken) {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));
        }
        const webService: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<News[]> = await webService.getNews(language, offset, limit);
        if (response.successful) {
            const newsState: News[] = response.content;
            dispatch(setNextNewsList(newsState));
            dispatch(setOffset(offset + 6));
            if (syncToken) {
                dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
            }
        } else {
            console.log('Fetching news failed: ' + response.message);
            dispatch(setNextNewsList([]));
            if (syncToken) {
                dispatch(
                    setSyncState({
                        syncToken,
                        value: { status: RequestStatus.Failed, message: response.message as string },
                    })
                );
            }
        }
    };
