import { i18n } from 'i18next';
import { useSearchParams } from 'react-router-dom';

import { clearSetting, getSetting, storeSetting } from './StorageService';

/**
 * Add all setting keys to this type to assist with linting and intellisense.
 */
export type LanguageSettingKey = 'currentLanguage';

const DEFAULT_LANGUAGE = 'fi';

/**
 * Stores the current language
 * @param {string} languageCode Language code
 */
export function storeCurrentLanguage(languageCode: string) {
    storeSetting('currentLanguage', languageCode);
}

/**
 * Clears the current language from local storage
 */
export function clearCurrentLanguage() {
    clearSetting('currentLanguage');
}

/**
 * Fetches current language from local storage
 * @return {string} The user data if it exists, else null
 */
export function getCurrentLanguage(): string {
    return getSetting('currentLanguage') ?? DEFAULT_LANGUAGE;
}

/**
 * Change the language of the application and remember the new language selection.
 * @param {I18N} i18n
 * @param {string} languageCode
 */
export function changeLanguage(i18n: i18n, languageCode: string) {
    storeCurrentLanguage(languageCode);
    i18n.changeLanguage(languageCode);
}

/**
 * Fetches language based on the lang parameter, fallback the current language
 * @return {string} The language code
 */
export function getLanguageFromParameter(): string {
    const [searchParams] = useSearchParams();
    return searchParams.get('lang') ? (searchParams.get('lang') as string) : getCurrentLanguage();
}
