import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

interface DrawerButtonProps {
    title: string;
    isOpen: boolean;
    onClick: () => void;
    icon: JSX.Element;
}

export const DrawerButton = ({ title, isOpen, onClick, icon }: DrawerButtonProps) => {
    return (
        <ListItem key={title} disablePadding sx={{ display: 'block' }} onClick={onClick}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    alignItems: 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: isOpen ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} sx={{ opacity: isOpen ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    );
};
