import WebService from '@oma-kala-shared/core/logic/WebService';
import {
    ContentResponse,
    DeclarationDetails,
    DeclarationSearchRequest,
    DeclarationSearchResult,
    UserDeclarationSearchRequest,
} from '@oma-kala-shared/core/model';
import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';

/**
 * Search declarations
 * @param {string} syncToken
 * @param {DeclarationSearchRequest} searchParams
 * @return {Promise<ContentResponse<DeclarationDetails>>}
 */
export const searchDeclarations =
    (syncToken: string, searchParams: DeclarationSearchRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        console.warn(searchParams);
        const response: ContentResponse<DeclarationSearchResult> = await service.searchDeclarations(searchParams);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: response.content,
                    },
                })
            );
        } else {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: 'N/A',
                    },
                })
            );
        }
    };

/**
 * Search declarations for user
 * @param {string} syncToken
 * @param {string} userId
 * @param {UserDeclarationSearchRequest} searchParams
 * @return {Promise<ContentResponse<DeclarationDetails>>}
 */
export const searchDeclarationsForUser =
    (syncToken: string, userId: string, searchParams: UserDeclarationSearchRequest): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        console.warn(searchParams);
        const response: ContentResponse<DeclarationSearchResult> = await service.searchDeclarationsForUser(userId, searchParams);

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: response.content,
                    },
                })
            );
        } else {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: 'N/A',
                    },
                })
            );
        }
    };
