export enum DrawerFolderName {
    CATCH_DECLARATION = 'CATCH_DECLARATION',
    SERVICE_INFORMATION = 'SERVICE_INFORMATION',
    LINKS = 'LINKS',
    LUKE = 'LUKE',
    ADMIN = 'ADMIN',
    SUPPORT_AND_FEEDBACK = 'SUPPORT_AND_FEEDBACK',
    PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
    ACCOUNT = 'ACCOUNT',
}
