import { ConsentData, UpdateUserRequest } from '@oma-kala-shared/core/model';
import { useSyncToken } from 'app/hooks';
import useAuthData from 'app/hooks/useAuthData';
import { updateUser } from 'app/state/thunks';
import { useDispatch } from 'react-redux';

const useWebConsents = () => {
    const { userData } = useAuthData();
    const webConsents = userData?.consentData.filter(consent => consent.showOnWeb === true);
    const userConsents = userData?.consentData;
    const dispatch = useDispatch();

    const [syncToken, loading] = useSyncToken({
        onSuccess: () => {},
        onError: message => {},
    });

    /**
     * By default, user will have consent data for all consent into user data, this function
     * will check if the user has give opinion to one specific consent or it is autogenerated
     *
     * @param {string} consentIdentifier
     * @return  {boolean} True if the user has given their consent to this particular consent
     */
    const didUserGiveConsentOpinion = (consentIdentifier: string) => {
        const found = webConsents?.find(
            (userConsent: ConsentData) =>
                userConsent.consentId === consentIdentifier && userConsent.showOnWeb && userConsent.isAutoGenerated === false
        );
        return found !== undefined && found.isAutoGenerated === false;
    };

    const setConsentStatus = (consent: ConsentData, isAccepted: boolean) => {
        if (!userData || !userData.id) {
            return;
        }

        const newUserConsents = userData.consentData.map(existConsent => {
            return {
                ...existConsent,
                accepted: existConsent.consentId === consent.consentId ? isAccepted : existConsent.accepted,
                isAutoGenerated: false,
            };
        });

        saveUserConsents(newUserConsents);
    };

    const saveUserConsents = (newUserConsents: ConsentData[]) => {
        if (!userData || !userData.id) {
            return;
        }

        const UpdateUserRequest: UpdateUserRequest = {
            consentData: newUserConsents,
        };

        dispatch(updateUser(syncToken, userData!.id, UpdateUserRequest));
    };

    return {
        webConsents,
        userConsents,
        didUserGiveConsentOpinion,
        saveUserConsents,
        setConsentStatus,
    };
};

export default useWebConsents;
