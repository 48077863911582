import { styled } from '@mui/material';
import React from 'react';

import LandingWrapper from '../landing/LandingWrapper';
import { LoginCard } from './LoginCard';

export const LoginPage = () => {
    return (
        <LandingWrapper
            visibleBackButton={true}
            visibleLoginButton={false}
            main={
                <Wrapper>
                    <LoginCard />
                </Wrapper>
            }
        />
    );
};

const Wrapper = styled('div')({
    width: '100%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    background: 'linear-gradient(180deg, rgba(30, 41, 59, 0) 0%, #1E293B 100%)',
});
