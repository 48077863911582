/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Message } from 'app/model';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { MessageExtraContentType } from 'app/model/Message';
import { getIconColor } from 'app/pages/home/AlertDialog';

/**
 * Generic alert component
 * If message is a plain serializable message
 * app/pages/home/AlertDialog may be used instead
 * @param {any} props
 * @return {JSX.Element}
 */
export const AlertDialogComponent: React.FC<{ props: Message; open: boolean; handleClose: () => void }> = ({
    props,
    open,
    handleClose,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} aria-describedby="alert-dialog-description" maxWidth="sm">
            <DialogTitle>
                <div style={{ display: 'flex' }}>
                    <ErrorIcon sx={{ color: getIconColor(props.type) }} />
                    <Typography sx={{ paddingLeft: '11px', fontWeight: 'bold' }}>
                        {t(`common.dialog.messageType.${props.type ?? props.title}`)}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent sx={{ paddingLeft: '60px', paddingBottom: 2 }}>
                <div style={{ display: 'flex', gap: 10 }}>
                    <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
                </div>
                {props.extraContent &&
                    props.extraContent.map(x => {
                        return x.type === MessageExtraContentType.Checkbox ? (
                            <FormControlLabel key={new Date().getTime()} control={<Checkbox onChange={x.change} />} label={x.label} />
                        ) : (
                            <></>
                        );
                    })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('common.close')}</Button>
            </DialogActions>
        </Dialog>
    );
};
