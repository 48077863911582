import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, Radio, Typography } from '@mui/material';
import { MapLayer, LayerSettings, LayerSettingMap } from '@oma-kala-shared/core/model';
import { useDispatch, useSelector } from 'react-redux';
import { setBaseLayer, setLayerSetting, BaseLayer, selectMapBaseLayer } from 'app/state/mapLayer/mapLayerSlice';

export interface MapLayerSettingProps {
    layer: MapLayer;
    layerSettings: LayerSettings;
    onChange?: (layerSettings: LayerSettings) => void;
}

/**
 * This component show the setting for a specific map layer.
 *
 * Due to the scope when this component is created, it just need
 * to handle the base layer (Terrain, Aerial, General) and Depth curve
 * so the layer's configuration (which is setting property inside the object) did not consider
 *
 * Later when we want to support full layers we need to care about that
 * especially for Restriction layer because it has configuration like below
 * {
        visible: true,
        editable: true,
        settings: [
            {
                name: LayerCommonSettingName.FROM_DATE,
                type: 'date',
                value: '',
            },
            {
                name: LayerCommonSettingName.TO_DATE,
                type: 'date',
                value: '',
            },
            {
                name: 'deviatingRestrictionsOnly',
                type: 'boolean',
                value: false,
            },
        ],
    },
 *
 * @param {MapLayerSettingProps} param0
 *
 * @return {JSX.Element}
 */
export const MapLayerSetting = ({ layer, layerSettings, onChange }: MapLayerSettingProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedBaseLayer = useSelector(selectMapBaseLayer);

    const updateProperty = (key: keyof LayerSettings, value: any) => {
        if (layerSettings.isBaseLayer) {
            dispatch(setBaseLayer(layer as BaseLayer));
        }

        const next = {
            ...layerSettings,
            [key]: value,
        };

        dispatch(
            setLayerSetting({
                key: layer,
                data: next as LayerSettingMap[typeof layer],
            })
        );
    };

    let buttonSelector = <Checkbox checked={layerSettings.visible} onChange={() => updateProperty('visible', !layerSettings.visible)} />;

    if (layerSettings.isBaseLayer) {
        buttonSelector = (
            <Radio
                inputProps={{
                    id: 'base-layer',
                    name: 'base-layer',
                }}
                checked={selectedBaseLayer === layer}
                onChange={() => updateProperty('visible', !layerSettings.visible)}
                value=""
            />
        );
    }

    return (
        <Box style={{ flexDirection: 'column' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ flex: 1 }}>{t(`map.layers.${layer}.name`)}</Typography>
                <Box
                // accessible={true}
                // TODO : add translation later
                // accessibilityLabel={
                //     t(`map.layers.${layer}.name`) +
                //     (layerSettings.visible
                //         ? t(`common.checkboxCheckedAccessibilityLabel`)
                //         : t(`common.checkboxUncheckedAccessibilityLabel`))
                // }
                >
                    {buttonSelector}
                </Box>
            </Box>
        </Box>
    );
};
