import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, Gear, LocalStateGear, SyncStatus } from '@oma-kala-shared/core/model';

import { RequestStatus } from 'app/model';

import { setUserGear } from '../gear/gearSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { selectTokenData } from '../user/userSlice';
import { createWebService } from './thunkHelpers';

/**
 * Fetch user gear from the back-end.
 *
 * @param {string} syncToken
 * @return {AppThunk} Dispatchable thunk
 */
export const fetchUserGear =
    (syncToken?: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.log('Fetch user gear');
        const rootState: RootState = getState();
        const tokenData = selectTokenData(rootState);
        if (!tokenData?.body?.oid) {
            console.log('User is not logged in');
            return;
        }
        const oid = tokenData.body.oid;
        if (!oid) return;
        if (syncToken) {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));
        }
        const webService: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<Gear[]> = await webService.getUserGears(oid, true);
        if (response.successful) {
            const newGearState: LocalStateGear[] = response.content.map(g => ({ entity: g, syncStatus: SyncStatus.CLEAN }));
            dispatch(setUserGear(newGearState));
            if (syncToken) {
                dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
            }
        } else {
            console.debug('Fetching user gear failed: ' + response.message);
            if (syncToken) {
                dispatch(
                    setSyncState({
                        syncToken,
                        value: { status: RequestStatus.Failed, message: response.message as string },
                    })
                );
            }
        }
    };
