import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Outlet, matchRoutes } from 'react-router-dom';

import { RouteName, RoutePath, RouteProperties } from 'app/model/Route';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

import { getRawRoutes, getRoutes } from '../../logic/Routes';
import { MapWrapper } from '../map/MapWrapper';
import { drawerStyledComponents, Drawer } from './drawer/Drawer';
import { Header } from './header/Header';
import * as ol from 'ol';
import MapContext from '../map/MapContext';

interface NavigationWrapperProps {
    children?: ReactNode;
}

// TODO: Need to make one variable for height of header space
export const NavigationWrapper = ({ children }: NavigationWrapperProps) => {
    const location = useLocation();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const routes = getRoutes();
    const rawRoutes = getRawRoutes();
    const currentRoute: RouteProperties | null = routes.find(route => route.path === location.pathname.concat(location.search)) ?? null;
    const { DrawerHeader } = drawerStyledComponents;
    const [map, setMap] = useState<ol.Map | null>(null);

    const mapVisible = () => {
        if (
            currentRoute &&
            [RoutePath.CATCH_DECLARATION, RoutePath.CATCH_DECLARATION_PERSONAL, RoutePath.CATCH_DECLARATION_GROUP].includes(
                currentRoute.path as RoutePath
            )
        ) {
            return true;
        }

        return matchRoutes([rawRoutes[RouteName.CREATE_CATCH_DECLARATION]], location);
    };

    return (
        <Box sx={{ display: 'flex', width: '100vw', height: '100vh', maxWidth: '100vw', overflowX: 'hidden' }}>
            <Header isOpen={isDrawerOpen} handleDrawerStateChanged={setIsDrawerOpen} />
            <Drawer isOpen={isDrawerOpen} />
            <Box
                component="main"
                sx={{ flexGrow: 1, flex: 1, flexDirection: 'column', display: 'flex', height: '100vh', overflow: 'hidden' }}
            >
                <DrawerHeader />
                <MapContext.Provider value={map}>
                    <Box flex={1} display={'flex'} height={'calc(100% - 64px)'} flexDirection={'row'}>
                        {children && <>{children}</>}
                        <Outlet />
                        <Box flex={1} display={mapVisible() ? 'flex' : 'none'}>
                            <Divider orientation="vertical" flexItem />
                            <MapWrapper setMap={setMap} />
                        </Box>
                    </Box>
                </MapContext.Provider>
            </Box>
        </Box>
    );
};
