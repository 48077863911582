import DeleteIcon from '@mui/icons-material/Delete';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Divider,
    IconButton,
    Typography,
    Modal,
    Box,
    Button,
    CircularProgress,
} from '@mui/material';
import { Gear, GearType } from '@oma-kala-shared/core/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSyncToken } from 'app/hooks';
import { selectGearTypes } from 'app/state/gear/gearSlice';
import { deleteGear } from 'app/state/thunks/deleteGear';

import { getGearIcon, createGearDescription } from './../../logic/GearService';

export interface GearItemProps {
    gear: Gear;
    gearType: GearType;
}

const GearItem = ({ gear, gearType }: GearItemProps) => {
    const { t } = useTranslation();
    const url = `/gear/${gear.id}`;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gearTypes: GearType[] = useSelector(selectGearTypes);
    const gearIcon = getGearIcon(gear, gearTypes);
    const gearDescription = createGearDescription(gear, gearTypes, t);
    const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);
    const [syncToken, isLoading] = useSyncToken({ onSuccess: () => {}, onError: () => {} });

    const handleOpenModalDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenModalDelete(!openModalDelete);
    };

    const handleCloseModalDelete = () => {
        setOpenModalDelete(false);
    };

    const handleDeleteGear = () => {
        dispatch(deleteGear(syncToken, gear.id));
        setOpenModalDelete(!isLoading);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem
                    onClick={() => navigate(`${url}`, { state: { gear: gear, gearType: gearType, isNewGear: false } })}
                    alignItems="flex-start"
                >
                    <ListItemButton>
                        <ListItemAvatar>
                            <Avatar alt="gear icon" src={gearIcon} />
                        </ListItemAvatar>
                        <ListItemText primary={gear.name} secondary={<React.Fragment>{gearDescription}</React.Fragment>} />
                    </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
            </List>
            <Modal
                open={openModalDelete}
                onClose={handleCloseModalDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('gear.deletegearTitle')}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t('gear.deleteGearMessage')}
                    </Typography>
                    <Box sx={buttonGroup}>
                        <Button onClick={handleDeleteGear}>
                            {t('common.delete')} &nbsp;
                            {isLoading && <CircularProgress size={12} />}
                        </Button>
                        <Button onClick={handleCloseModalDelete}>{t('common.cancel')}</Button>
                    </Box>
                </Box>
            </Modal>
            <div>
                <IconButton onClick={handleOpenModalDelete}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default GearItem;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: '#fff',
    p: 4,
};

const buttonGroup = {
    alignItems: 'flex-end',
    textAlign: 'end',
};
