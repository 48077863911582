import { AppThunk, RootState } from 'app/state/store';

import { initializeStore } from './initializeStore';

/**
 * Logs the current user out of the application.
 * @return {AppThunk}
 */
export const logoutUser = (): AppThunk => async (dispatch: any, getState: () => RootState) => {
    dispatch(initializeStore(null));
};
