import { MapLayerSettingStorageKey } from 'app/state/mapLayer/mapLayerSlice';
import { DeclarationStorageKey } from './DeclarationService';
import { LanguageSettingKey } from './LanguageService';
import { UserSettingKey } from './UserSettingService';
import { UnreadNewsStatsStorageKey } from 'app/state/news/newsSlice';

// TODO: remove when error is resolved
type SvkErrorStorageKey = 'SvkTempErrorShown';

/**
 * Add all setting keys to this type to assist with linting and intellisense.
 */
type StorageKey =
    | UserSettingKey
    | LanguageSettingKey
    | SvkErrorStorageKey
    | DeclarationStorageKey
    | MapLayerSettingStorageKey
    | UnreadNewsStatsStorageKey;

/**
 * Setting keys that contain user-specific (possibly sensitive) data, which should be cleared when the user logs out.
 */
export const userRelatedSettings: StorageKey[] = ['userData', 'tokenData', 'userRoles', 'latestLoggedInUser'];

/**
 * Store a setting using local storage.
 * The setting value is stored globally for the application.
 * @param {UserSettingKey} key
 * @param {string} value
 */
export function storeSetting(key: StorageKey, value: string) {
    localStorage.setItem(key, value);
}

/**
 * Read a setting using local storage.
 * @param {UserSettingKey} key
 * @return {string | null}
 */
export function getSetting(key: StorageKey): string | null {
    return localStorage.getItem(key);
}

/**
 * Remove a setting from local storage
 * @param {UserSettingKey} key The key to remove
 */
export function clearSetting(key: StorageKey) {
    localStorage.removeItem(key);
}
