import LaunchIcon from '@mui/icons-material/Launch';
import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CMSItem } from '@oma-kala-shared/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { appTheme } from 'app/theme';

interface DrawerLinkProps {
    isOpen: boolean;
    link: CMSItem;
}

export const DrawerLink = ({ isOpen, link }: DrawerLinkProps) => {
    const { t } = useTranslation();
    const title = link?.title?.rendered ?? null;

    const getLink = (item: CMSItem): string => {
        const regex = /<p>(.+)<\/p>/;
        if (!regex.test(item.excerpt.rendered)) {
            return item.excerpt.rendered;
        }
        const matches: RegExpExecArray | null = regex.exec(item.excerpt.rendered);
        return matches === null ? item.excerpt.rendered : matches[1];
    };

    const url: string = getLink(link);
    const isRelative: boolean = url.startsWith('/');

    return !isRelative ? (
        <Link href={url} target="_blank" style={{ color: appTheme.palette.text.primary, textDecoration: 'none', display: 'block' }}>
            <ListItem key={title} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: isOpen ? 'initial' : 'center',
                        alignItems: 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isOpen ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <LaunchIcon />
                    </ListItemIcon>
                    <ListItemText primary={title} sx={{ opacity: isOpen ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </Link>
    ) : (
        <></>
    );
};
