import { ContentResponse, TokenData, UserData } from '@oma-kala-shared/core/model';

import { clearUserData, storeUserData } from 'app/logic/UserSettingService';
import { AppThunk, RootState } from 'app/state/store';
import { selectTokenData, setUserData } from 'app/state/user/userSlice';

import { createWebService } from './thunkHelpers';

/**
 * Fetches user data for the currently logged in user.
 * If no user is logged in, does nothing
 * @return {AppThunk}
 */
export const fetchUserData = (): AppThunk => async (dispatch: any, getState: () => RootState) => {
    console.debug('fetching user data');

    const rootState: RootState = getState();
    const tokenData: TokenData | null = selectTokenData(rootState);

    if (!tokenData?.body.oid) {
        return;
    }

    const webService = await createWebService(dispatch, getState);
    const oid = tokenData.body.oid;
    if (!oid) return;
    const resp: ContentResponse<UserData> = await webService.getUserData(oid);

    if (resp.successful) {
        storeUserData(resp.content);
        dispatch(setUserData(resp.content));
    } else {
        clearUserData();
        dispatch(setUserData(null));
    }

    console.debug('finished fetching user data');
};
