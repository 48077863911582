import { ListItemIcon, ListItemIconProps, styled } from '@mui/material';
import React from 'react';

interface ExpandMoreProps extends ListItemIconProps {
    expand: boolean;
}

export const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <ListItemIcon {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme?.transitions.create('transform', {
        duration: theme?.transitions.duration.shortest,
    }),
}));
