import WebService from '@oma-kala-shared/core/logic/WebService';
import {
    ConsentIdentifier,
    ContentResponse,
    CreateDeclarationDetails,
    DeclarationDetails,
    DeclarationFisherRequirementPropertyDetails,
    DeclarationNotRequiredException,
    DeclarationSpeciesPropertyRequirementDetails,
    KnownTaxonomyType,
    SimpleResponse,
} from '@oma-kala-shared/core/model';
import i18n from 'i18next';
import { OmaKalaLanguageCode, RequestStatus } from 'app/model';
import { setErrorMessage } from 'app/state/message/messageSlice';
import { setSuccessMessage } from '../message/messageSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';
import { selectUserData } from '../user/userSlice';

/**
 * Create or update the specified declaration
 * @param {string} syncToken
 * @param {DeclarationDetails} declaration
 * @return {Promise<ContentResponse<DeclarationDetails>>}
 */
export const saveDeclaration =
    (syncToken: string, declaration: DeclarationDetails): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('Saving declaration', declaration);
        const userData = selectUserData(getState());

        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const request = declaration as CreateDeclarationDetails;
        request.syncToCatchLog =
            userData?.consentData?.find(x => x.consentId === ConsentIdentifier.SAVE_DECLARATION_TO_CATCH_LOG)?.accepted === true;
        const response: ContentResponse<DeclarationDetails> = await service.saveDeclaration(
            request,
            (userData?.applicationLanguage as OmaKalaLanguageCode) ?? OmaKalaLanguageCode.FI
        );

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        message: i18n.t('catchDeclaration.declarationSaved'),
                        resource: response.content,
                    },
                })
            );
            dispatch(setSuccessMessage(i18n.t('catchDeclaration.declarationSaved')));
        } else {
            console.debug('Save declaration failed with message:' + response.message);
            const notNeededException = typeof response === 'object' && (response.message as any)?.notNeededReasonEnum;
            let message = i18n.t('catchDeclaration.requestFailed');
            if (notNeededException) {
                const exData = response.message as DeclarationNotRequiredException;
                message = i18n
                    .t(`catchDeclaration.notNeededEx.${exData.notNeededReasonEnum}`)
                    .replace('{}', i18n.t(`taxonomyType.${exData.taxonomyId}`));
            }
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: message,
                    },
                })
            );
            dispatch(setErrorMessage(message));
        }
    };
