/* eslint-disable camelcase */

/* eslint-disable no-unused-vars */
import { CMSTag } from '@oma-kala-shared/core/model';

export interface CMSTitle {
    rendered: string;
}

export interface CMSExcerpt {
    rendered: string;
    protected: boolean;
}

export interface CMSContent {
    rendered: string;
    protected: boolean;
}

export enum CMSCategory {
    LINKKI_FI = 2,
    LINKKI_SV = 3,
    LINKKI_EN = 4,
    ELEMENTTI_FI = 5,
    ELEMENTTI_SV = 6,
    ELEMENTTI_EN = 7,
    TIEDOTE_FI = 8,
    TIEDOTE_SV = 9,
    TIEDOTE_EN = 10,
    SIVU_FI = 11,
    SIVU_SV = 12,
    SIVU_EN = 13,
}
export interface CMSRequest {
    rest_route: string;
    _fields: string;
    categories?: string;
    per_page?: number;
    slug?: string;
    tags?: string;
}
