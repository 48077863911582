import React, { useEffect, useState } from 'react';

import smallImg from 'resources/images/background-small.jpg';
import backgroundImg from 'resources/images/background.jpg';

interface LazyBackgroundProps {
    children: any;
}

/**
 * @return {JSX.Element}
 */
export function LazyBackground({ children }: LazyBackgroundProps) {
    const [source, setSource] = useState(smallImg);

    useEffect(() => {
        const img = new Image();
        img.src = backgroundImg;
        img.onload = () => setSource(backgroundImg);
    }, [backgroundImg]);

    return (
        <div
            style={{
                width: '100%',
                flexGrow: 1,
                backgroundImage: `url(${source})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#00B5E2',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            {children}
        </div>
    );
}
