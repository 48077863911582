import {
    ComponentRequestStatus,
    ContentResponse,
    GeocodingFeatureResponse,
    GeocodingSimilarTerm,
    GeocodingSimilarTermResponse,
} from '@oma-kala-shared/core/model';

import { createWebService } from './thunkHelpers';
import i18n from 'i18n';
import { AppThunk, RootState } from '../store';
import BrowserWebService from 'app/logic/BrowserWebService';
import { setSyncState } from '../sync/syncSlice';
import { RequestStatus } from 'app/model';

export const fetchMapLocation =
    (syncToken: string, searchText: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));
        const webService: BrowserWebService = await createWebService(dispatch, getState);

        const setErrorState = (error: string): void => {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: error,
                    },
                })
            );
        };

        const firstRequest: ContentResponse<GeocodingFeatureResponse> = await webService.fetchSearchResult(searchText, i18n.language);

        if (!firstRequest.successful) {
            console.debug('fetching search result failed unexpectedly', firstRequest.message);
            setErrorState(firstRequest.message as string);
            return;
        }

        if (firstRequest.content.features.length !== 0) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: firstRequest.content.features,
                    },
                })
            );
            return;
        }

        // if we find 0 results on the initial query, attempt to find similar terms,
        // which might be more suitable. This ensure that cases where the user enters
        // a typo still find a result - this isn't handled automatically by the search API,
        // so we do it here instead
        const similarTerms: ContentResponse<GeocodingSimilarTermResponse> = await webService.fetchSimilarSearchTerms(searchText);
        if (!similarTerms.successful || similarTerms.content.terms.length === 0) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        resource: [],
                    },
                })
            );
            return;
        }

        // we just pick the first term which we receive from the API -
        // they seem to be ordered by relevance (highest similarity is first in the response)
        const newTerm: GeocodingSimilarTerm = similarTerms.content.terms[0];
        const secondRequest: ContentResponse<GeocodingFeatureResponse> = await webService.fetchSearchResult(newTerm.text, i18n.language);

        if (!secondRequest.successful) {
            console.debug('second search request failed unexpectedly', secondRequest.message);
            setErrorState(secondRequest.message as string);
            return;
        }

        dispatch(
            setSyncState({
                syncToken,
                value: {
                    status: RequestStatus.Successful,
                    resource: secondRequest.content.features,
                },
            })
        );
    };
