import { MoveToInbox } from '@mui/icons-material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GavelIcon from '@mui/icons-material/Gavel';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { createSvgIcon } from '@mui/material';
import React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { DrawerFolderName } from 'app/components/layout/drawer/types';
import { DrawerMenuItem, RouteName, RoutePath, RouteProperties } from 'app/model/Route';

import * as Pages from '../pages';
import HomeMenuIcon from 'app/components/layout/drawer/icons/HomeMenuIcon';
import { OmaKalaUserRole, UserRole } from 'app/model';
import { getUserRoles } from './UserSettingService';
import i18next from 'i18next';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const FishingHookIcon = createSvgIcon(
    <path d="M19 9c0-1.3-.84-2.4-2-2.82V2h-2v4.18C13.84 6.6 13 7.7 13 9s.84 2.4 2 2.82V15c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1h3L5 9v6c0 3.31 2.69 6 6 6s6-2.69 6-6v-3.18c1.16-.42 2-1.52 2-2.82zm-3 1c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>,
    'ContentCut'
);

const validRole = (userRoles: UserRole[], allowedRoles: OmaKalaUserRole[] | null) => {
    if (allowedRoles === null) return true;
    return userRoles.find(ur => allowedRoles.includes(ur.code));
};

export const getMenuItems = (): DrawerMenuItem[] => {
    const routes = getRawRoutes();
    const menu: DrawerMenuItem[] = [];
    const userRoles = getUserRoles() ?? [];

    if (validRole(userRoles, routes[RouteName.HOME].allowedRoles)) {
        menu.push(routes[RouteName.HOME]);
    }

    if (validRole(userRoles, routes[RouteName.PROFILE].allowedRoles)) {
        menu.push({
            name: i18next.t(`drawerMenu.folders.${DrawerFolderName.ACCOUNT}`),
            subMenu: [
                routes[RouteName.PROFILE],
                routes[RouteName.GEAR],
                {
                    name: i18next.t(`drawerMenu.folders.${DrawerFolderName.SERVICE_INFORMATION}`),
                    subMenu: [
                        routes[RouteName.ABOUT],
                        routes[RouteName.TECHNICAL_REQUIREMENTS],
                        routes[RouteName.PRIVACY_POLICY],
                        routes[RouteName.TERMS_AND_CONDITION],
                    ],
                    cmsLink: DrawerFolderName.SERVICE_INFORMATION,
                },
            ],
        });
    }

    if (userRoles && validRole(userRoles, [OmaKalaUserRole.ORGANIZER, OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.SVK_USER])) {
        if (validRole(userRoles, routes[RouteName.CATCH_DECLARATION_GROUP].allowedRoles)) {
            menu.push(routes[RouteName.CATCH_DECLARATION_GROUP]);
        }

        if (validRole(userRoles, routes[RouteName.CATCH_DECLARATION_PERSONAL].allowedRoles)) {
            menu.push(routes[RouteName.CATCH_DECLARATION_PERSONAL]);
        }
    }

    if (userRoles && validRole(userRoles, [OmaKalaUserRole.ADMIN, OmaKalaUserRole.EDITOR])) {
        menu.push({
            name: i18next.t(`drawerMenu.folders.${DrawerFolderName.ADMIN}`),
            subMenu: [
                {
                    name: routes[RouteName.CATCH_DECLARATION].name,
                    subMenu: [routes[RouteName.CATCH_DECLARATION_GROUP], routes[RouteName.CATCH_DECLARATION_PERSONAL]],
                },
                {
                    name: i18next.t(`drawerMenu.folders.${DrawerFolderName.PUSH_NOTIFICATIONS}`),
                    subMenu: [routes[RouteName.SEND_NOTIFICATION], routes[RouteName.NOTIFICATIONS]],
                },
            ],
            cmsLink: DrawerFolderName.ADMIN,
        });
    }

    menu.push({
        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.SUPPORT_AND_FEEDBACK}`),
        subMenu: [routes[RouteName.SUPPORT], routes[RouteName.FEEDBACK]],
    });

    menu.push({
        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.LINKS}`),
        subMenu: [],
        cmsLink: DrawerFolderName.LINKS,
    });

    menu.push({
        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.LUKE}`),
        subMenu: [],
        cmsLink: DrawerFolderName.LUKE,
    });

    return menu;
};

export const getRawRoutes = (): Record<RouteName, RouteProperties> => {
    return {
        [RouteName.HOME]: {
            name: i18next.t(`routes.${RouteName.HOME}`),
            path: RoutePath.HOME,
            component: <Pages.NewsListPage />,
            allowedRoles: null,
            icon: <HomeMenuIcon />,
        },
        [RouteName.NEWS]: {
            name: i18next.t(`routes.${RouteName.NEWS}`),
            path: RoutePath.NEWS,
            component: <Pages.NewsListPage />,
            allowedRoles: null,
        },
        [RouteName.NEWS_ITEM]: {
            name: i18next.t(`routes.${RouteName.NEWS_ITEM}`),
            path: RoutePath.NEWS_ITEM,
            component: <Pages.NewsItemPage />,
            allowedRoles: null,
        },
        [RouteName.CMS_ITEM]: {
            name: i18next.t(`routes.${RouteName.CMS_ITEM}`),
            path: RoutePath.CMS_ITEM,
            component: <Pages.CmsPostingPage />,
            allowedRoles: null,
        },
        [RouteName.PROFILE]: {
            name: i18next.t(`routes.${RouteName.PROFILE}`),
            path: RoutePath.PROFILE,
            component: <Pages.ProfilePage />,
            allowedRoles: [OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER, OmaKalaUserRole.SVK_USER],
            icon: <AccountBoxIcon />,
        },
        [RouteName.FEEDBACK]: {
            name: i18next.t(`routes.${RouteName.FEEDBACK}`),
            path: RoutePath.FEEDBACK,
            component: <Pages.FeedbackPage />,
            allowedRoles: null,
            icon: <FeedbackIcon />,
        },
        [RouteName.TECHNICAL_REQUIREMENTS]: {
            name: i18next.t(`routes.${RouteName.TECHNICAL_REQUIREMENTS}`),
            path: RoutePath.TECHNICAL_REQUIREMENTS,
            component: <Pages.TechnicalRequirementsPage />,
            allowedRoles: null,
            icon: <IndeterminateCheckBoxIcon />,
        },
        [RouteName.TERMS_AND_CONDITION]: {
            name: i18next.t(`routes.${RouteName.TERMS_AND_CONDITION}`),
            path: RoutePath.TERMS_AND_CONDITION,
            component: <Pages.TermsAndConditionsPage />,
            allowedRoles: null,
            icon: <GavelIcon />,
        },
        [RouteName.ABOUT]: {
            name: i18next.t(`routes.${RouteName.ABOUT}`),
            path: RoutePath.ABOUT,
            component: <Pages.AboutPage />,
            allowedRoles: null,
            icon: <InfoIcon />,
        },
        [RouteName.NOTIFICATIONS]: {
            name: i18next.t(`routes.${RouteName.NOTIFICATIONS}`),
            path: RoutePath.NOTIFICATIONS,
            component: <Pages.BrowsePushNotification />,
            allowedRoles: [OmaKalaUserRole.ADMIN],
            icon: <NotificationsIcon />,
        },
        [RouteName.SEND_NOTIFICATION]: {
            name: i18next.t(`routes.${RouteName.SEND_NOTIFICATION}`),
            path: RoutePath.SEND_NOTIFICATION,
            component: <Pages.SendPushNotification />,
            allowedRoles: [OmaKalaUserRole.ADMIN],
            icon: <NotificationsActiveIcon />,
        },
        [RouteName.LOGIN]: {
            name: i18next.t(`routes.${RouteName.LOGIN}`),
            path: RoutePath.LOGIN,
            component: <Pages.LoginPage />,
            allowedRoles: null,
            hideForLoggedIn: true,
        },
        [RouteName.RESET_PASSWORD]: {
            name: i18next.t(`routes.${RouteName.RESET_PASSWORD}`),
            path: RoutePath.RESET_PASSWORD,
            component: <Pages.ResetPasswordPage />,
            allowedRoles: null,
        },
        [RouteName.REGISTRATION]: {
            name: i18next.t(`routes.${RouteName.REGISTRATION}`),
            path: RoutePath.REGISTRATION,
            component: <Pages.RegistrationPage />,
            allowedRoles: null,
        },
        [RouteName.GEAR]: {
            name: i18next.t(`routes.${RouteName.GEAR}`),
            path: RoutePath.GEAR,
            component: <Pages.GearPage />,
            allowedRoles: [OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER],
            icon: <FishingHookIcon />,
        },
        [RouteName.GEAR_ITEM]: {
            name: i18next.t(`routes.${RouteName.GEAR_ITEM}`),
            path: RoutePath.GEAR_ITEM,
            component: <Pages.GearDetailsPage />,
            allowedRoles: [OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER],
        },
        [RouteName.PRIVACY_POLICY]: {
            name: i18next.t(`routes.${RouteName.PRIVACY_POLICY}`),
            path: '/privacy-policy',
            component: <Pages.PrivacyPolicyPage />,
            allowedRoles: null,
            icon: <IndeterminateCheckBoxIcon />,
        },
        [RouteName.SUPPORT]: {
            name: i18next.t(`routes.${RouteName.SUPPORT}`),
            path: RoutePath.SUPPORT,
            component: <Pages.SupportPage />,
            allowedRoles: null,
            icon: <GavelIcon />,
        },
        [RouteName.CREATE_CATCH_DECLARATION]: {
            name: i18next.t(`routes.${RouteName.CREATE_CATCH_DECLARATION}`),
            path: RoutePath.CREATE_CATCH_DECLARATION,
            component: <Pages.CreateDeclarationPage />,
            allowedRoles: [
                OmaKalaUserRole.EDITOR,
                OmaKalaUserRole.ADMIN,
                OmaKalaUserRole.RECREATIONAL_FISHER,
                OmaKalaUserRole.SVK_USER,
                OmaKalaUserRole.ORGANIZER,
            ],
        },
        [RouteName.CATCH_DECLARATION]: {
            name: i18next.t(`routes.${RouteName.CATCH_DECLARATION}`),
            path: RoutePath.CATCH_DECLARATION,
            component: <Pages.DeclarationListPage />,
            allowedRoles: [
                OmaKalaUserRole.EDITOR,
                OmaKalaUserRole.ADMIN,
                OmaKalaUserRole.RECREATIONAL_FISHER,
                OmaKalaUserRole.SVK_USER,
                OmaKalaUserRole.ORGANIZER,
            ],
        },
        [RouteName.CATCH_DECLARATION_PERSONAL]: {
            name: i18next.t(`routes.${RouteName.CATCH_DECLARATION_PERSONAL}`, { newline: '<br/>' }),
            path: RoutePath.CATCH_DECLARATION_PERSONAL,
            component: <Pages.DeclarationListPage />,
            allowedRoles: [
                OmaKalaUserRole.EDITOR,
                OmaKalaUserRole.ADMIN,
                OmaKalaUserRole.RECREATIONAL_FISHER,
                OmaKalaUserRole.SVK_USER,
                OmaKalaUserRole.ORGANIZER,
            ],
            dangerousHtmlAllowed: true,
            icon: <MoveToInbox />,
        },
        [RouteName.CATCH_DECLARATION_GROUP]: {
            name: i18next.t(`routes.${RouteName.CATCH_DECLARATION_GROUP}`, { newline: '<br/>' }),
            path: RoutePath.CATCH_DECLARATION_GROUP,
            component: <Pages.DeclarationListPage />,
            allowedRoles: [OmaKalaUserRole.EDITOR, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER],
            dangerousHtmlAllowed: true,
            icon: <MoveToInbox />,
        },
    };
};

export const getRoutes = (): RouteProperties[] => {
    return Object.values(getRawRoutes());
};
