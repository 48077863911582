import {
    DeclarationSpeciesRequirementPropertyName,
    DeclarationSpeciesRequirementDetails,
    KnownTaxonomyType,
    CatchGeneralizedLocation as CatchGeneralizedLocationType,
    DeclarationCatchProperty,
    DeclarationDetails,
    DeclarationCatchDetails,
    GeoJsonPoint,
    SpatialRequirementResponse,
    SpatialRequirementTypeEnum,
} from '@oma-kala-shared/core/model';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress } from '@mui/material';
import { DynamicGridRef, GridValue, SelectFieldItem } from '../../../common/dynamic-grid';
import CatchDetails, { CatchDetailsRef, SpeciesFieldName } from './details/CatchDetails';
import StatisticsDetails, { StatisticsDetailsRef } from './details/StatisticsDetails';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { validateDeclarationCatch } from '@oma-kala-shared/core/logic/DeclarationService';
import { setErrorMessage } from 'app/state/message/messageSlice';
import { useDispatch } from 'react-redux';
import CatchGeneralizedLocation from '../../display/CatchGeneralizedLocation';
import { isEmpty } from 'lodash';
import { MessageType } from 'app/model';
import { Message } from 'app/model/Message';
import { AlertDialogComponent } from 'app/components/common/AlertDialogComponent';

export interface DeclarationCatchDetailProps {
    declaration: DeclarationDetails;
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    onCancel: () => void;
    onSave: (catchDetail: DeclarationCatchDetails) => void;
    allowCancel: boolean;
    catchDetail?: DeclarationCatchDetails | null;
    onValueChange: (catchDetails: DeclarationCatchDetails | null) => void;
}

export const DeclarationCatchDetail = React.forwardRef<DynamicGridRef, DeclarationCatchDetailProps>((props, ref) => {
    console.log('catch detail rerender', props.catchDetail);
    const { t } = useTranslation();
    const { declaration, declarationTaxonomyRequirement, catchDetail, onSave, onCancel, allowCancel, onValueChange } = props;
    const dispatch = useDispatch();
    const [alert, setAlert] = useState<Message | null>(null);

    let currentTaxonomyRequirementState = declarationTaxonomyRequirement[0];

    if (catchDetail) {
        currentTaxonomyRequirementState = declarationTaxonomyRequirement.find(
            requirement => requirement.idTaxonomy === catchDetail.idTaxonomyRequiredTaxonomy
        ) as DeclarationSpeciesRequirementDetails;
    }

    const [currentTaxonomyRequirement, setCurrentTaxonomyRequirement] =
        useState<DeclarationSpeciesRequirementDetails>(currentTaxonomyRequirementState);

    const catchProperties = catchDetail?.catchProperties ?? [];

    const detailsFormRef = useRef<CatchDetailsRef>(null);
    const statisticsFormRef = useRef<StatisticsDetailsRef>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [catchGeneralizedLocation, setCatchGeneralizedLocation] = useState<CatchGeneralizedLocationType | null>(
        catchDetail?.generalizedLocation ?? null
    );
    const [catchGeometry, setCatchGeometry] = useState<GeoJsonPoint | null>(catchDetail?.geometry ?? null);

    const taxonomies: SelectFieldItem[] = [];
    declarationTaxonomyRequirement.forEach(taxonomy => {
        taxonomies.push({
            label: t(`taxonomyType.${taxonomy.idTaxonomy}`),
            value: taxonomy.idTaxonomy,
        });
    });

    const handleStatisticDetailChange = (latestValue: GridValue) => {
        onValueChange(generateDeclarationCatchDetails(false));
    };

    const onTaxonomyChanged = (taxonomy: DeclarationSpeciesRequirementDetails) => {
        setCurrentTaxonomyRequirement(taxonomy);
        onValueChange(generateDeclarationCatchDetails(false));
    };

    const isFormDataValid = (c: DeclarationCatchDetails | null) => {
        let invalidCount = 0;

        if (!detailsFormRef.current?.validate()) {
            invalidCount++;
        }

        if (!statisticsFormRef.current?.validate()) {
            invalidCount++;
        }

        if (catchGeneralizedLocation === null) {
            invalidCount++;
        } else if (isEmpty(catchGeneralizedLocation.featureLabel)) {
            invalidCount++;
        } else if (isEmpty(catchGeneralizedLocation.featureLabelSecondary)) {
            invalidCount++;
        }

        if (c) {
            const validationErrors = validateDeclarationCatch(declaration, c, currentTaxonomyRequirementState.requiredTaxonomyProperties);
            if (validationErrors.length > 0) {
                dispatch(setErrorMessage(t(`catchDeclaration.newDeclaration.declarationCatchValidationError.${validationErrors[0]}`)));
                return false;
            }
        }

        return invalidCount === 0;
    };

    const generateDeclarationCatchDetails = (needValidate?: boolean): DeclarationCatchDetails | null => {
        let catchInfo: DeclarationCatchDetails;
        if (catchDetail === null) {
            catchInfo = {
                catchTime: '',
                idTaxonomyRequiredTaxonomy: currentTaxonomyRequirement.idTaxonomy,
                idDeclarationRequirementRequiredTaxonomy: currentTaxonomyRequirement.idDeclarationRequirement,
                externalReference: null,
                catchProperties: [],
                geometry: null,
                generalizedLocation: null,
                tempId: `catchDetails_${new Date().getTime().toString()}` as string,
            };
        } else {
            catchInfo = { ...catchDetail! };
        }

        if (needValidate === true && isFormDataValid(catchInfo) === false) {
            return null;
        }

        const catchDetails = detailsFormRef.current?.getValue();
        const statisticsData = statisticsFormRef.current?.getValue();

        const gridValue: GridValue = { ...catchDetails, ...statisticsData };

        catchInfo!.catchProperties = Object.values(currentTaxonomyRequirement.requiredTaxonomyProperties).map(
            (requiredProperty): DeclarationCatchProperty => {
                const existProperty = catchProperties.find(property => property.propertyName === requiredProperty.name);

                return {
                    id: existProperty ? existProperty.id : null,
                    value: gridValue![requiredProperty.name].toString(),
                    idRequiredTaxonomyProperty: requiredProperty.id,
                    propertyName: requiredProperty.name,
                    dataType: requiredProperty.dataType,
                    unit: requiredProperty.unit as string,
                };
            }
        );

        if (gridValue![DeclarationSpeciesRequirementPropertyName.CATCH_DATE]) {
            catchInfo!.catchTime = moment(gridValue![DeclarationSpeciesRequirementPropertyName.CATCH_DATE] as string).toISOString();
        }

        if (gridValue![SpeciesFieldName]) {
            catchInfo!.idTaxonomyRequiredTaxonomy = gridValue![SpeciesFieldName] as KnownTaxonomyType;
        }

        catchInfo.geometry = catchGeometry;
        catchInfo.generalizedLocation = catchGeneralizedLocation;

        return catchInfo!;
    };

    const handleFormSave = () => {
        const catchInfo = generateDeclarationCatchDetails(false);

        if (isFormDataValid(catchInfo) === false) {
            onValueChange(catchInfo);
            return;
        }

        if (catchInfo === null) {
            return;
        }

        onSave(catchInfo);
    };

    const handleFormCancel = () => {
        onCancel();
    };

    /**
     * For now catch form only render when we have fisher requirements
     */
    if (declarationTaxonomyRequirement.length === 0) {
        return <></>;
    }

    const onSpatialRequirementsUpdated = (response: SpatialRequirementResponse | null) => {
        if (!response) return;
        if (response.spatialRequirementType === SpatialRequirementTypeEnum.OPTIONAL) {
            setAlert({
                text: t('catchDeclaration.optionalDeclarationNotis'),
                type: MessageType.INFO,
            });
        } else if (response.spatialRequirementType === SpatialRequirementTypeEnum.NOT_REQUIRED) {
            setAlert({
                text: t('catchDeclaration.notNeededEx.OUTSIDE_DECLARATION_AREA'),
                type: MessageType.INFO,
            });
        }
    };

    const getDetailElement = () => {
        return (
            <>
                <CatchDetails
                    ref={detailsFormRef}
                    taxonomyRequirement={currentTaxonomyRequirement}
                    catchProperties={catchProperties}
                    declarationTaxonomyRequirement={declarationTaxonomyRequirement}
                    taxonomies={taxonomies}
                    onTaxonomyChanged={onTaxonomyChanged}
                />
                <StatisticsDetails
                    ref={statisticsFormRef}
                    taxonomyRequirement={currentTaxonomyRequirement}
                    catchProperties={catchProperties}
                    declarationTaxonomyRequirement={declarationTaxonomyRequirement}
                    taxonomies={taxonomies}
                    onValueChange={handleStatisticDetailChange}
                />

                <CatchGeneralizedLocation
                    generalizedLocation={catchDetail?.generalizedLocation ?? null}
                    onGeometrySet={setCatchGeometry}
                    onGeneralizedLocationSet={setCatchGeneralizedLocation}
                    onSpatialRequirementsUpdated={onSpatialRequirementsUpdated}
                    showCircleOnMap={catchDetail?.id === undefined}
                    showExactLocation={catchDetail?.id === undefined}
                    catchDetails={catchDetail}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 2, marginBottom: 4 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFormSave}
                        disabled={!isFormDataValid(null)}
                        // disabled={!dirty || loading}
                        sx={{ minWidth: 200, marginRight: 2 }}
                    >
                        {t('common.save')}
                        {loading && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                    </Button>

                    {allowCancel && (
                        <CancelButton
                            variant="contained"
                            onClick={handleFormCancel}
                            // disabled={!dirty || loading}
                            sx={{
                                minWidth: 200,
                                backgroundColor: 'white',
                                color: '#0033A0',
                                boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                '&:hover': 'white',
                            }}
                        >
                            {t('common.cancel')}
                            {loading && <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />}
                        </CancelButton>
                    )}
                    {alert && <AlertDialogComponent props={alert} open={alert !== null} handleClose={() => setAlert(null)} />}
                </Box>
            </>
        );
    };
    return <>{getDetailElement()}</>;
});

DeclarationCatchDetail.displayName = 'CreateDeclarationCatchForm';

const CancelButton = styled(Button)({
    '&:hover': {
        backgroundColor: 'white',
    },
});
