import { Blocker, BrowserHistory, Transition } from 'history';
import React from 'react';
// eslint-disable-next-line camelcase
import { UNSAFE_NavigationContext } from 'react-router-dom';

// eslint-disable-next-line require-jsdoc
const useBlocker = (blocker: Blocker, when = true): void => {
    const navigator = React.useContext(UNSAFE_NavigationContext).navigator as BrowserHistory;

    React.useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
};

export default useBlocker;
