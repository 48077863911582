/* eslint-disable require-jsdoc */
import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import { Box } from '@mui/system';
import { Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUnreadNewsStats } from 'app/state/news/newsSlice';

function HomeMenuIcon() {
    const unreadCountStats = useSelector(selectUnreadNewsStats);
    const showBadge = unreadCountStats !== null && unreadCountStats.count > 0;

    if (!showBadge) {
        return <HomeIcon color="action" />;
    }

    return (
        <Box>
            <Badge badgeContent={unreadCountStats.count} color="error">
                <HomeIcon color="action" />
            </Badge>
        </Box>
    );
}

export default HomeMenuIcon;
