import { Consent, TokenData, UserData } from '@oma-kala-shared/core/model';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OmaKalaUserRole, UserRole } from 'app/model/UserRole';
import { RootState } from 'app/state/store';

export interface UserState {
    tokenData: TokenData | null;
    userData: UserData | null;
    userRoles: UserRole[] | null;
    tempTokenData: TokenData | null;
    // TODO: Need to remove after handle all kind of consents in backend [LOK-3887]
    webConsents: Consent[] | null;
}

export const initialState: UserState = {
    tokenData: null,
    userData: null,
    userRoles: null,
    tempTokenData: null,
    webConsents: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setTokenData: (state: UserState, action: PayloadAction<TokenData | null>) => {
            state.tokenData = action.payload;
        },
        setUserData: (state: UserState, action: PayloadAction<UserData | null>) => {
            state.userData = action.payload;
        },
        setUserRoles: (state: UserState, action: PayloadAction<UserRole[] | null>) => {
            state.userRoles = action.payload;
        },
        resetUserState: (state: UserState, action: PayloadAction<UserState | null>) => {
            return initialState;
        },
        setTempTokenData: (state: UserState, action: PayloadAction<TokenData | null>) => {
            state.tempTokenData = action.payload;
        },
        // TODO: Need to remove after handle all kind of consents in backend [LOK-3887]
        setWebConsents: (state: UserState, action: PayloadAction<Consent[] | null>) => {
            state.webConsents = action.payload;
        },
    },
});

export const { setTokenData, setUserData, setUserRoles, resetUserState, setTempTokenData, setWebConsents } = userSlice.actions;

export const selectUserData = (state: RootState): UserData | null => state.user.userData;
export const selectTokenData = (state: RootState): TokenData | null => state.user.tokenData;
export const selectUserRoles = (state: RootState): UserRole[] | null => state.user.userRoles;
export const selectIsLoggedIn = (state: RootState): boolean => !!state.user.tokenData && !!state.user.userData;
export const selectTempTokenData = (state: RootState): TokenData | null => state.user.tempTokenData;
// TODO: Need to remove after handle all kind of consents in backend [LOK-3887]
export const selectWebConsents = (state: RootState): Consent[] | null => state.user.webConsents;

/**
 * Returns true if the user is logged in and has the admin role.
 */
export const selectUserIsAdmin = createSelector(
    [selectIsLoggedIn, selectUserRoles],
    (isLoggedIn: boolean, userRoles: UserRole[] | null): boolean => {
        return isLoggedIn && !!userRoles && userRoles.some((r: UserRole) => r.code === OmaKalaUserRole.ADMIN);
    }
);

/**
 * Returns true if the user is logged in and has the editor role.
 */
export const selectUserIsEditor = createSelector(
    [selectIsLoggedIn, selectUserRoles],
    (isLoggedIn: boolean, userRoles: UserRole[] | null): boolean => {
        return isLoggedIn && !!userRoles && userRoles.some((r: UserRole) => r.code === OmaKalaUserRole.EDITOR);
    }
);

/**
 * Select UserData for the current user, only if the user is currently logged in.
 */
export const selectLoggedInUserData = createSelector(
    [selectUserData, selectTokenData],
    (userData: UserData | null, tokenData: TokenData | null) => (tokenData ? userData : null)
);

export default userSlice.reducer;
