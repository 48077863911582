import WebService from '@oma-kala-shared/core/logic/WebService';
import { TokenData, ContentResponse } from '@oma-kala-shared/core/model';

import { addExpirationTimeAndBody } from 'app/logic/LoginService';
import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';
import { setSyncState } from 'app/state/sync/syncSlice';

import { initializeStore } from './initializeStore';
import { createWebService } from './thunkHelpers';
import i18n from 'i18next';
import { setUnreadMessageCount } from '../news/newsSlice';

/**
 * Login the user
 * @param {string} syncToken
 * @param {string} email
 * @param {string} password
 * @return {AppThunk}
 */
export const loginUser =
    (syncToken: string, email: string, password: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        let service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<TokenData> = await service.loginUser(email, password);

        if (response.successful) {
            const tokenData: TokenData = addExpirationTimeAndBody(response.content);
            dispatch(initializeStore(tokenData));

            console.debug('login completed successfully');

            // Recreate to make request with tokenData
            service = await createWebService(dispatch, getState);
            const language = i18n.language;
            const getStatsResponse = await service.getUnreadNewsStats(language);

            if (getStatsResponse.successful) {
                const unreadCount = getStatsResponse.content;
                dispatch(setUnreadMessageCount(unreadCount));
            }

            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Successful } }));
        } else {
            dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Failed, message: response.message as string } }));
        }
    };
