import Map from 'ol/Map';
import { Coordinate } from 'ol/coordinate';

export interface MapHandler {
    init: () => void;
    destroy: () => void;
    setLocation: (coordinate: Coordinate, animateToLocation: boolean) => void;
}

/**
 * Base Map layers
 */
export class BaseMapHandler {
    protected defaultScale = 200000; // Default scale for the map. This is used when calculate zoom level

    /**
     * @param {Map} map reference to a OpenLayers map
     */
    constructor(protected map: Map) {}

    /**
     * Init function
     */
    init() {}

    /**
     * Destroys layers and other listeners used by this resource
     */
    destroy() {
        this.map.getView().animate({
            center: this.map.getView().getCenter(),
            duration: 700,
            zoom: 5,
        });
    }

    /**
     * Centers the map view to 'coordinate' and zooms to 1:200000. Smooth transition if 'animateToLocation' is true
     * @param {Coordinate} coordinate
     */
    setLocation(coordinate: Coordinate) {
        this.animateToCoordinate(coordinate, this.getZoom()!);
    }

    // eslint-disable-next-line valid-jsdoc
    /**
     * Centers the map view to 'coordinate' and zooms to 1:200000. Smooth transition if 'animateToLocation' is true
     * @param {Coordinate} coordinate
     * @param {number} [zoomLevel] zoomLevel
     */
    animateToCoordinate(coordinate: Coordinate, zoomLevel: number) {
        this.map.getView().animate({
            center: coordinate,
            duration: 700,
            zoom: zoomLevel,
        });
    }

    /**
     * getZoom 1:200000 (default)
     *
     * @param {number} [scale] The ground distance you want to represent on the map
     *
     * @return {number}
     */
    protected getZoom(scale = this.defaultScale) {
        const dpi = 96;
        const resolution = (scale * 0.0254) / dpi;
        const zoomLevel = this.map.getView().getZoomForResolution(resolution);
        return zoomLevel;
    }
}

export default BaseMapHandler;
