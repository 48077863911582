import { Alert, AlertColor, Slide, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Message, MessageType } from 'app/model';
import { clearCurrentMessage, selectCurrentMessage } from 'app/state/message/messageSlice';

/**
 * @return {JSX.Element}
 */
export function MessageSnackbar() {
    const dispatch = useDispatch();
    const currentMessage: Message | null = useSelector(selectCurrentMessage);

    // Remember previous message so we can retain the style while the message is closing.
    // Else, the snackbar will flicker with 'info' color when closing an 'error' message.
    const [previousMessage, setPreviousMessage] = useState<Message | null>(null);
    const messageToDisplay: Message | null = currentMessage ?? previousMessage;

    useEffect(() => {
        if (currentMessage) setPreviousMessage(currentMessage);
    }, [currentMessage]);

    const handleClose = () => dispatch(clearCurrentMessage());

    const severity: AlertColor = (() => {
        switch (messageToDisplay?.type) {
            case MessageType.INFO:
                return 'info';
            case MessageType.ERROR:
                return 'error';
            case MessageType.SUCCESS:
                return 'success';
            default:
                return 'info';
        }
    })();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!currentMessage}
            autoHideDuration={messageToDisplay?.timeout}
            onClose={handleClose}
            key={'topcenter'}
            TransitionComponent={Slide}
        >
            <Alert severity={severity} onClose={handleClose}>
                {messageToDisplay?.text}
            </Alert>
        </Snackbar>
    );
}
