import { replaceLocalEntityWithDeleted } from '@oma-kala-shared/core/logic';
import { Gear, LocalStateGear, GearVisibilityType } from '@oma-kala-shared/core/model';

import { selectUserGearLocalState, setUserGear } from '../../state/gear/gearSlice';
import { AppThunk, RootState } from '../store';

// import { syncUserGear } from './syncUserGear';

/**
 * Mark a gear instance as deleted in the local state and trigger a sync with the back-end
 * @param {string} gearId Gear ID
 * @return {AppThunk} Dispatchable thunk
 */
export const deleteUserGear =
    (gearId: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const gearState: LocalStateGear[] = selectUserGearLocalState(getState());
        const oldVersion: Gear | undefined = gearState.find(e => e.entity.id === gearId)?.entity;
        if (!oldVersion) {
            console.log(`Gear with ID ${gearId} doesn't exist in state.`);
            return;
        }
        const newGearState: LocalStateGear[] = replaceLocalEntityWithDeleted(gearState, oldVersion);
        const localNewGearState: LocalStateGear[] = newGearState.map(e => {
            if (e.entity === oldVersion) {
                const deletedGear: Gear | undefined = { ...oldVersion, gearVisibilityType: GearVisibilityType.DELETED };
                return {
                    ...e,
                    entity: deletedGear,
                };
            } else {
                return e;
            }
        });
        dispatch(setUserGear(localNewGearState));
        // dispatch(syncUserGear());
    };
