import { getLocalizedLinkTag } from '@oma-kala-shared/core/logic';
import WebService from '@oma-kala-shared/core/logic/WebService';
import { CMSItem, CMSRequest, CMSTag, ContentResponse, GetCmsPostsRequest } from '@oma-kala-shared/core/model';

import { BrowserWebService } from 'app/logic/BrowserWebService';
import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';

import { setCmsTermsAndConditions } from '../cms/cmsSlice';
import { createWebService } from './thunkHelpers';

/**
 * Fetch elements from our CMS for the given language and load them into the application state.
 * @param {string} language
 * @return {AppThunk}
 */
export const fetchCmsTermsAndConditionsMessages =
    (language: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const request: GetCmsPostsRequest = {
            fields: 'id,title,date,excerpt,content,tags',
            tags: getLocalizedLinkTag(language, {
                finnish: CMSTag.ELEMENT_TERMS_AND_CONDITIONS,
                swedish: CMSTag.ELEMENT_TERMS_AND_CONDITIONS_SV,
                english: CMSTag.ELEMENT_TERMS_AND_CONDITIONS_EN,
                sami: CMSTag.ELEMENT_TERMS_AND_CONDITIONS_SE,
            }).toString(),
            itemPerPage: 99,
        };

        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<CMSItem[]> = await service.fetchCmsPosts(request);

        dispatch(setCmsTermsAndConditions({ status: RequestStatus.Loading }));

        if (response.successful) {
            console.log(response);
            dispatch(setCmsTermsAndConditions({ status: RequestStatus.Successful, resource: response.content }));
        } else {
            console.debug('Error loading Terms and Conditions', response.message);
            dispatch(setCmsTermsAndConditions({ status: RequestStatus.Failed, message: response.message as string }));
        }
    };
