import {
    DeclarationCatchValidationError,
    DeclarationFisherRequirementPropertyName,
    DeclarationRegulation,
    FishingType,
    KnownGearType,
    KnownGearTypeProperty,
    KnownTaxonomyType,
    LayerCommonSettingName,
    MapLayer,
    TaxonomyTypePropertyName,
} from '@oma-kala-shared/core/model';
import { ResourceLanguage } from 'i18next';

import { DrawerFolderName } from 'app/components/layout/drawer/types';

/* eslint-disable max-len */
import { MessageType, NotificationStatus, OmaKalaLanguageCode, OmaKalaUserRole, RedirectView, RequestStatus } from 'app/model';
import { RouteName } from 'app/model/Route';

import { Translations } from './translations';

const translations: Translations & ResourceLanguage = {
    translations: {
        admin: {
            notifications: {
                browse: {
                    title: 'Sáddejuvvon push-dieđut',
                    description: 'Vuolábeale listtus oainnát árabut sáddejuvvon push-dieđuid ja daid dili',
                    helperText: 'Viiddit push-dieđuid vai oainnát guđege dieđu dili earái gielain ja dihtui gullevaš eará dieđuid.',
                    loaded: 'Push-dieđuid viežžan lihkostuvai.',
                    loadError: 'Push-dieđuid viežžan eahpelihkostuvai - leage buorre ja geahččal ođđasit {{ message }}.',
                    columns: {
                        createdAt: 'Dahkanáigi',
                        redirectView: 'Čuozáhatoainnus',
                        redirectId: 'Oidnosa ID',
                        language: 'Giella',
                        status: 'Dilli',
                        title: 'Bajilčála',
                        message: 'Diehtu',
                    },
                },
                send: {
                    buttons: {
                        submit: 'Sádde',
                    },
                    description: 'Sádde ođđa Push-dieđu Omakala-geavaheaddjiide.',
                    helperText:
                        'Dát diehtu dušše geavaheaddjiide, geaid earánastingiella Omakalas lea $t(translations:common:languages:{{ lang }}) som språk in Omakala.',
                    input: {
                        redirectToViewLabel: 'Stivre geavaheaddji dihto oidnosii applikašuvnnas (eaktodáhtolaš).',
                        messageLabel: 'Diehtu',
                        redirectViewLabel: 'Stivre oidnosii',
                        redirectViewIdLabel: 'Stivre oidnosa Idi',
                        roleGroupLabel: 'Rollajoavkkut',
                        titleLabel: 'Bajilčála',
                    },
                    notificationSent: 'Diehtu sáddejuvvon',
                    notificationSendingError: 'Juoga manai boastut dieđu sáddemis - leage buorre ja geahččal ođđasit.',
                    redirectViews: {
                        [RedirectView.NEWS]: 'Dieđáhusat',
                    },
                    requiredValuesMissing: 'Muhtun gáibiduvvon árvvut váilot.',
                    title: 'Sádde Push-dieđu',
                },
                status: {
                    [NotificationStatus.CREATED]: 'Dahkkon',
                    [NotificationStatus.FAILED]: 'Eahpelihkostuvvan',
                    [NotificationStatus.SENT]: 'Sáddejuvvon',
                    [NotificationStatus.IN_PROGRESS]: 'Jođus',
                },
                redirectView: {
                    [RedirectView.NEWS]: 'Dieđáhusat',
                },
            },
        },
        common: {
            yes: 'Kylla #fi#',
            no: 'Ei #fi#',
            clear: 'Tyhjennä #fi#',
            ok: 'Ok #fi#',
            close: 'Sulje #fi#',
            submit: 'Sádde',
            mandatory: 'bákkolaš',
            optional: 'eaktodáhtolaš',
            email: 'Šleađgapoasta',
            firstName: 'Ovdanamma',
            lastName: 'Sohkanamma',
            name: 'Namma',
            reload: 'Luđe ođđasit',
            return: 'Máhca',
            send: 'Sádde',
            search: 'Hae #fi#',
            password: 'Suollemassátni',
            confirmPassword: 'Nanne suollemassáni',
            passwordRules:
                'Suollemassánis galget leat unnimustá {{ minLength }} mearkka ja dat galgá sisttisdoallat sihke stuorra ja unna bustávaid, nummiriid ja spesiálamearkkaid',
            passwordsDoNotMatch: 'Suollemassánit eai dávis nubbi nuppi',
            requiredHelpText: 'Dát árvu lea bákkolaš',
            birthDate: 'Syntymäpäivä #fi#',
            address: {
                country: 'Eana',
                placeOfResidence: 'Ruovttugielda',
                postalCode: 'Poastanummir',
                postalTown: 'Poastadoaibmabáiki',
                street: 'Poastačujuhus',
                address: 'Osoite #fi#',
            },
            phoneNumber: 'Telefonnummir',
            login: 'Čálit sisa',
            loginDelimiterText: 'Tai #fi#',
            loginWithSvk: 'Čálit sisa SVK-konttuinat',
            svkTempIssue: {
                title: 'Varoitus #fi#',
                content:
                    'Toistaiseksi SVK-kirjautumismahdollisuus on käytettävissä vain sellaisille SVK-jäseneille, joilla ei ennestään ole Omakala-tiliä.\n\nNe SVK-jäsenet, joilla on jo Omakala-tili, ohjeistetaan käyttämään sitä kirjautuessaan Omakalaan.\n\nJatkossa tämä rajoitus poisttuu ja kaikki SVK-jäsenet pääsevät kirjautumaan SVK-tunnuksillaan. #fi#',
                cancel: 'Peruuta #fi#',
                continue: 'Ymmärrän, älä näytä enää #fi#',
                ok: 'Ok',
            },
            logout: 'Čálit olggos',
            loginError: 'Du šleađgapoasta dahje suollemassátni leat boastut. Dárkkis du sisačálihandieđuid ja geahččal ođđasit.',
            save: 'Vurke',
            cancel: 'Šluhtte',
            delete: 'Jávkat',
            back: 'Ruovttoluotta',
            date: 'Päivämäärä #fi#',
            languages: {
                [OmaKalaLanguageCode.EN]: 'Eŋgelasgiella',
                [OmaKalaLanguageCode.SV]: 'Ruoŧagiella',
                [OmaKalaLanguageCode.FI]: 'Suomagiella',
                [OmaKalaLanguageCode.SE]: 'Sámegiella',
            },
            loadMessage: {
                [RequestStatus.Failed]: 'Sisdoalu luđen eahpelihkostuvai',
                [RequestStatus.Loading]: 'Luđet sisdoalu...',
                [RequestStatus.NotInitiated]: 'Luđen ii leat álggahuvvon',
                [RequestStatus.Successful]: 'Luđen eahpelihkostuvai',
            },
            mobile: {
                appStore: {
                    linkAltText: 'Applikašuvnna App Store liŋkaboallu',
                    qrCodeAltText: 'App Store QR koda',
                },
                playStore: {
                    linkAltText: 'Applikašuvnna Play Store liŋkaboallu',
                    qrCodeAltText: 'Play Store QR koda',
                },
            },
            roles: {
                [OmaKalaUserRole.ADMIN]: 'Bajásdoalli',
                [OmaKalaUserRole.RECREATIONAL_FISHER]: 'Lustaáigge guolásteaddji',
                [OmaKalaUserRole.EDITOR]: 'Dieđuhábmejeaddji',
                [OmaKalaUserRole.ORGANIZER]: 'Järjestäjä', // #fi#
                [OmaKalaUserRole.SVK_USER]: 'Svk-käyttäjä', // #fi#
            },
            or: 'DAHJE',
            error: 'Odottamaton virhe',
            fishingType: {
                [FishingType.HOOK_AND_LINE]: 'Onginta',
                [FishingType.ANGLING]: 'Heittokalastus',
                [FishingType.FYKE_NETTING]: 'Rysäkalastus',
                [FishingType.GILLNETTING]: 'Verkkokalastus',
                [FishingType.ICE_FISHING]: 'Pilkintä',
                [FishingType.KATISKA]: 'Katiska- ja mertapyynti',
                [FishingType.LONGLINE]: 'Koukku- ja siimapyynti',
                [FishingType.LURE_FISHING]: 'Perhokalastus',
                [FishingType.TROLLING]: 'Uistelu',
                [FishingType.SEINE_NETTING]: 'Nuottakalastus',
            },
            form: {
                leavingNotification: 'Haluatko varmasti poistua? Kaikki syöttämäsi tiedot menetetään, mikäli et tallenna tai lähetä',
            },
            dialog: {
                messageType: {
                    [MessageType.INFO]: 'Tiedot', // #fi#
                    [MessageType.ERROR]: 'Varoitus', // #fi#
                    [MessageType.SUCCESS]: 'Menestys', // #fi#
                },
                doNotShowAgain: 'Älä näytä tätä uudelleen', // #fi#
            },
        },
        passwordProperties: {
            forgotPassword: 'Vajálduvvan suollemassátni?',
            validation: {
                description: 'Suollemassátni galgá sisttisdoallat:',
                digit: 'nummiriid',
                length: 'unnimustá {{ minLength }} mearkka',
                lowercase: 'smávva bustávaid',
                special: 'spesiálamearkkaid',
                uppercase: 'stuorra bustávaid',
                passwordConfirm: 'suollemassátni ja suollemassáni nannet galget dávistit nubbi nuppi',
                isNewPasswordDifferentThanNewOne: 'ođđa suollemassátni ii leat seammá go dálá',
            },
            messages: {
                noUserFound: 'Sosiáladorvodovddaldahkii laktašuvvan geavaheaddji ii gávdnon.',
                identificationUnsuccessful: 'Dovdán ii lihkostuvvan. Geahččal ođđasit.',
                passwordChanged: 'Suollemassáni lonuheapmi lihkostuvai.',
                passwordNotChanged: 'Feaila suollemassáni molsumis. Geahččal ođđasit.',
            },
        },
        header: {
            navigation: {
                news: 'Dieđáhusat',
                feedback: 'Atte máhcahaga',
                sendNotification: 'Sádde',
                browseNotifications: 'Bláđe',
            },
        },
        footer: {
            mainFooter: {
                omaKalaLinks: {
                    about: 'Dieđut bálvalusas',
                    terms: 'Geavahaneavttut',
                    privacyStatement: 'Diehtosuodječilgehus',
                    technicalRequirements: 'Teknihkalaš gáibádusat',
                    cmsLogin: 'CMS-hálddašanreaidu',
                    feedback: 'Atte máhcahaga',
                },
                links: {
                    link: 'Liŋkkat',
                    privacyStatement: 'Diehtosuodječilgehus',
                    teamsLink: 'Suolodatmearapilohta Teams-ságastallanjoavku',
                },
                lukeLinks: {
                    resourceCenter: 'Luondduriggodatguovddáš',
                    contact: 'Váldde oktavuođa',
                    media: 'Mediai',
                },
            },
            secondaryFooter: {
                gov: 'Luondduriggodatguovddáš',
                access: 'Fáhtehahttivuođačilgehus',
                support: 'Teknihkalaš doarjja ja máhcahat',
            },
        },
        home: {
            registrationInfo: 'Registreren dáhpáhuvvá mobiilaapplikašuvnnas',
            title: 'Ovdasiidu',
        },
        feedback: {
            title: 'Máhcahat',
            header: 'Atte máhcahaga bálvalusain',
            helpText:
                'Sáhtát addit máhcahaga bálvalusas áican feaillain dahje sáddet ovddidanevttohusaid. Du oktavuođadieđuid guođđin lea eaktodáhtolaš ja dat ávkkástallojit dušše vejolaš oktavuođaváldimiidda jus dárbbašat dárkilut dieđuid du máhcahagas.',
            feedback: 'Máhcahat',
            successMessage: 'Du máhcahat lea sáddejuvvon',
            errorMessage: 'Feaila máhcahaga sáddemis {{ message }}',
            captcha: 'Sihkkarastinteaksta',
            captchaMessage:
                'Sáhtát sáddet máhcahaga go leat čállán sihkkarastinteavstta. Sihkkarastinteaksta ii jerrojuvvo jus leat čálihan sisa bálvalussii.',
            backendUnavailableMessage:
                'Yhteyden muodostamisessa palvelimeen oli ongelmia. Jotkin toiminnot eivät ehkä toimi kunnolla. #fi#',
            email: 'Palautetta voi myös antaa sähköpostilla: ', // #fi#
        },
        profile: {
            title: 'Iežat dieđut',
            name: 'Namma',
            alias: 'Aliasnamma',
            email: 'Šleađgapoasta',
            address: 'Čujuhus',
            city: 'Gávpot',
            postalCode: 'Poastanummir',
            placeOfResidence: 'Ruovttugielda',
            phoneNumber: 'Telefonnummir',
            successMessage: 'Dieđut beaiváduvvon',
            errorMessage: 'Iežat dieđuid beaivádeamis dáhpáhuvai feaila: {{ message }}',
            avatarIconAlt: 'Profiilafállu',
            profileSections: {
                primaryInfo: {
                    title: 'Deháleamos dieđut',
                    description: 'Dárkkis ja hábme deháleamos dieđuidat',
                },
                changePassword: {
                    title: 'Molsso suollemassáni',
                    description: 'Don čálihuvvot olggos suollemassáni molsuma maŋŋá',
                    externalIdentity: 'Voit vaihtaa salasanasi käymällä {{ provider }} -sivustolla #fi#',
                },
                userConsent: {
                    title: 'Käyttäjän suostumukset', // #fi#
                },
                svkInfo: {
                    title: 'SVK-dieđut',
                    membership: {
                        title: 'Jäsenyyden tiedot (päivitetty {{time}})', // #fi#
                        dataUpdateHint:
                            'Jäsentiedot päivitetään jokaisen SVK-identiteetillä tapahtuvan kirjautumisen yhteydessä. Seuraavan kerran Omakala saa päivitetyt jäsentiedot seuraavan uloskirjautumisen ja sen jälkeisen sisäänkirjautumisen jälkeen', // #fi#
                        expiredDateInAvailable: 'Jäsenyyden päättymispäivä ei ole saatavilla', // #fi#
                        validUntil: 'Jäsenyyden päättymispäivä: {{time}}', // #fi#
                    },
                },
            },
        },
        news: {
            title: 'Dieđáhusat',
            moreNews: 'lasi dieđáhusat',
            noMoreNews: 'Eai gávdnot eanet ođđasat.',
            noExist: 'Ođđasat eai gávdno.',
        },
        cookieDialog: {
            cookieConfiguration: 'Diehtočoahkuid meroštallan',
            description:
                'Omakala ávkkástallá diehtočoahkuid sierra dárkkuhusaide. Vai sáhtát geavahit siidduid, don galggat dohkket vealtameahttun diehtočoahkuid.',
            essentialCookie:
                'Ávkkástallat diehtočoahkuid neahttasiiddu doaibmama ja dorvvolašvuođa dáhkideapmin sierra doaimmain, dego sisačáliheamis ja sisdoallohálddašeamis. Diehtočoahkut leat vealtameahttumat ja vurkejuvvojit álo automáhtalaččat.',
            analyticCookie:
                'Analyhtalaš diehtočoahkuid ávkkástallat siidduid geavaheami ja luđema čuovvumii. Analystihkkadieđuid ávkkástallat siidduid bajásdollui ja ovddideapmái. Lassin diehtočoahkuid ávkkástallat Google Analytics diehtočoahkuid geavahangeavadaga mielde.',
            privacyLink: 'Diehtosuodječilgehus',
        },
        routes: {
            [RouteName.ABOUT]: 'Bálvalusas',
            [RouteName.FEEDBACK]: 'Atte máhcahaga',
            [RouteName.HOME]: 'Ruoktu',
            [RouteName.NEWS]: 'Ođđasat',
            [RouteName.NOTIFICATIONS]: 'Almmuhusat',
            [RouteName.PROFILE]: 'Kontu',
            [RouteName.SEND_NOTIFICATION]: 'Sádde almmuhusaid',
            [RouteName.TECHNICAL_REQUIREMENTS]: 'Teknihkalaš gáibádusat',
            [RouteName.TERMS_AND_CONDITION]: 'Geavahaneavttut',
            [RouteName.CMS_ITEM]: 'CMS',
            [RouteName.NEWS_ITEM]: 'Artihkal',
            [RouteName.LOGIN]: 'Sisačáliheapmi',
            [RouteName.RESET_PASSWORD]: 'Suollemassáni máhcaheapmi',
            [RouteName.REGISTRATION]: 'Register',
            [RouteName.GEAR]: 'Gaskaoamit',
            [RouteName.GEAR_ITEM]: 'Välineet', // #fi#
            [RouteName.CREATE_CATCH_DECLARATION]: 'Uuden saalisilmoituksen luominen', // #fi#'
            [RouteName.CATCH_DECLARATION_GROUP]: 'Saalisilmoitus {{- newline}} - Kalastusmatkayritys', // #fi#
            [RouteName.CATCH_DECLARATION_PERSONAL]: 'Saalisilmoitus {{- newline}} - Henk. koht.', // #fi#
            [RouteName.CATCH_DECLARATION]: 'Saalisilmoitus', // #fi#
            [RouteName.SUPPORT]: 'Kayttäjätuki', // #fi#
            [RouteName.PRIVACY_POLICY]: 'Yksityisyyskäytäntö', // #fi#
        },
        drawerMenu: {
            folders: {
                [DrawerFolderName.LINKS]: 'Liŋkkat',
                [DrawerFolderName.LUKE]: 'Luke',
                [DrawerFolderName.SERVICE_INFORMATION]: 'Dieđut bálvalusas',
                [DrawerFolderName.CATCH_DECLARATION]: 'Saalisilmoitus', // #fi#
                [DrawerFolderName.ADMIN]: 'Ylläpito', // #fi#
                [DrawerFolderName.SUPPORT_AND_FEEDBACK]: 'Kayttäjätuki & Palautetta', // #fi#
                [DrawerFolderName.PUSH_NOTIFICATIONS]: 'Push-ilmoitus', // #fi#
                [DrawerFolderName.ACCOUNT]: 'Käyttäjätili', // #fi#
            },
        },
        registration: {
            title: 'Registreren',
            info: 'Registering requires strong authentication (Suomi.fi).',
            helpText: 'Vállje "Joatkke" sirdašuvvama várás Suomi.fi-bálvalussii.',
            acceptTermsAndConditions: 'Dohkkehan',
            registration: 'Rekisteröinti', // #fi#
            termsAndConditions: 'Geavahaneavttut',
            alradyHaveAccount: 'Sinulla on jo tili ', // #fi#
            identificationUnsuccessful: 'Tunnistaminen epäonnistui', // #fi#
            validation: {
                email: {
                    format: 'Boastto šleađgapoastta hápmi',
                    empty: 'Šleađgapoasta ii sáhte leat guorus',
                },
            },
            messages: {
                registrationSuccessful: 'Registreren lihkostuvai! Dál sáhtát čálihit sisa.',
                registrationUnsuccessful: 'Registreren ii lihkostuvvan, geahččal maŋŋelis ođđasit.',
            },
            sections: {
                primaryInfo: {
                    title: 'Deháleamos dieđut',
                    description: 'Atte deháleamos dieđuid',
                },
                contactInfo: {
                    title: 'Oktavuođadieđut',
                    description: 'Atte oktavuođadieđuid',
                },
                password: {
                    title: 'Suollemassátni',
                    description: 'Atte dorvvolaš suollemassáni',
                },
            },
        },
        gear: {
            addNewGear: 'Lasit ođđa gaskaoami',
            titleMyGear: 'Mu gaskaoamit',
            titleNewGear: 'Ođđa gaskaoapmi',
            titleEditGear: 'Hábme gaskaoami',
            titleGearType: 'Gaskaoapmetiipa',
            gearsNotFound: 'Iežan gaskaoamit eai gávdnon',
            gearIconAccessibilityLabel: 'Gaskaoamitsikon',
            deletegearTitle: 'Jávkadatgo gaskaoami?',
            allGear: 'Buot gaskaomiid',
            deleteGearMessage:
                'Jávkaduvvon gaskaoapmi seailu ain dáhpáhusain, maidda dat lea dál válljejuvvon, muhto it sáhte hábmet dan dahje válljet šat ođđa dáhpáhusaide.',
            editPropertyHeader:
                'Mii lea $t(translations:gear:gearType:{{ gearType }}:name) $t(translations:gear:gearTypeProperty:{{ propertyName }}:name)?',
            gearType: {
                [KnownGearType.ROD]: {
                    name: 'oaggunstággu',
                    possessiveName: 'oaggunstággu',
                    defaultGearName: 'Iežat oaggunstággu',
                },
                [KnownGearType.NET]: {
                    name: 'Fierbmi',
                    possessiveName: 'Du fierbmi',
                    defaultGearName: 'Iežan fierbmi',
                },
                [KnownGearType.TRAP]: {
                    name: 'bivddus',
                    possessiveName: 'du bivddus',
                    defaultGearName: 'Iežan bivddus',
                },
                [KnownGearType.KATISKA]: {
                    name: 'guollehehkke',
                    possessiveName: 'du guollehehkke',
                    defaultGearName: 'Iežan guollehehkke',
                },
                [KnownGearType.FYKE]: {
                    name: 'meardi',
                    possessiveName: 'du meardi',
                    defaultGearName: 'Iežan meardi',
                },
                [KnownGearType.SPINNING_ROD]: {
                    name: 'šlivgunstággu',
                    possessiveName: 'du šlivgunstággu',
                    defaultGearName: 'Iežan šlivgunstággu',
                },
                [KnownGearType.FLY_ROD]: {
                    name: 'dolgevuoggastággu',
                    possessiveName: 'du šlivgunstággu',
                    defaultGearName: 'Iežan dolgevuoggastággu',
                },
                [KnownGearType.TROLLING_ROD]: {
                    name: 'uštinvuogga',
                    possessiveName: 'uštinvuogga',
                    defaultGearName: 'Iežan uštinvuogga',
                },
                [KnownGearType.SEINE]: {
                    name: 'nuohtti',
                    possessiveName: 'du nuohtti',
                    defaultGearName: 'Iežan nuohtti',
                },
                [KnownGearType.SINGLE_HOOK_AND_LINE]: {
                    name: 'seakteáŋkor',
                    possessiveName: 'du seakteáŋkor',
                    defaultGearName: 'Iežan seakteáŋkor',
                },
                [KnownGearType.SPRING_LOADED_FISH_HOOK]: {
                    name: 'čuolastanáŋkor',
                    possessiveName: 'du čuolastanáŋkor',
                    defaultGearName: 'Iežan čuolastanáŋkor',
                },
                [KnownGearType.OTHER_HOOK_GEAR]: {
                    name: 'eará áŋkorbivddus',
                    possessiveName: 'du áŋkorbivddus',
                    defaultGearName: 'Iežan áŋkorbivddus',
                },
                [KnownGearType.LONG_LINE]: {
                    name: 'liidna',
                    possessiveName: 'du liidna',
                    defaultGearName: 'Iežan liidna',
                },
                [KnownGearType.ICE_ROD]: {
                    name: 'bilkastággu',
                    possessiveName: 'du bilkastággu',
                    defaultGearName: 'Iežan bilkastággu',
                },
                [KnownGearType.OTHER]: {
                    name: 'eará',
                    possessiveName: 'du gaskaoapmi',
                    defaultGearName: 'Iežan gaskaoapmi',
                },
            },
            gearTypeProperty: {
                [KnownGearTypeProperty.HEIGHT]: {
                    name: 'allodat',
                },
                [KnownGearTypeProperty.LENGTH]: {
                    name: 'guhkkodat',
                },
                [KnownGearTypeProperty.LENGTH_SEINE_BAG]: {
                    name: 'bađa guhkkodat',
                },
                [KnownGearTypeProperty.NODE_SPACING]: {
                    name: 'čuolbmagaska',
                },
                [KnownGearTypeProperty.NODE_SPACING_FYKE_BAG]: {
                    name: 'bađa čuolbmagaska',
                },
                [KnownGearTypeProperty.NODE_SPACING_SEINE_BAG]: {
                    name: 'bađa čuolbmagaska',
                },
                [KnownGearTypeProperty.WIRE_STRENGTH]: {
                    name: 'árppu gassodat',
                },
                [KnownGearTypeProperty.HOOK_COUNT]: {
                    name: 'áŋkoriid mearri',
                },
                [KnownGearTypeProperty.DESCRIPTION]: {
                    name: 'govvádus',
                },
            },
            gearDeleted: 'Gaskaoapmi jávkaduvvon',
            gearUpdated: 'Gaskaoapmi beaiváduvvon',
            gearSaved: 'Gaskaoapmi vurkejuvvon',
            callFailed: 'Doaibmabidju eahpelihkostuvai. Leage buorre ja geahččal ođđasit.',
        },
        about: {
            pageTitle: 'Dieđut Omakalas',
            downloadAndroid: 'Luđe Omakala Androidii',
            downloadIos: 'Luđe Omakala IOSii',
        },
        registrationPrompt: {
            text: 'Jatkaaksesi sinun pitää ensin tunnistautua suomi.fi -palvelussa ja tämän jälkeen rekisteröityä Omakala-käyttäjäksi. Sinut ohjataan tunnistautumiseen 5 sekunnin kuluttua #fi#',
        },
        errorDuringSvkLogin: {
            title: 'Kirjautuminen SVK:n tunnuksilla epäonnistui. Ota yhteyttä omakala@luke.fi. #fi#',
        },
        unknownSvkLoginError: {
            title: 'Odottamaton virhe kirjautumisessa. Ota yhteyttä omakala@luke.fi #fi#',
        },
        catchDeclaration: {
            validation: {
                greaterThan: ':attribute tulee olla suurempi kuin :greater_than', // #fi
            },
            list: {
                searchCondition: 'Saalisilmoitukset hakuehdolla:', // #fi#
                showAll: 'Kaikki ilmoitukset', // #fi#
                title: {
                    group: 'Saalisilmoitukset - Kalastusmatkayritys', // #fi#
                    personal: 'Saalisilmoituset - Henkilökohtainen', // #fi#
                },
            },
            generalizedLocation: {
                generalizing: 'Valitsemaasi sijaintia karkeistetaan', // #fi#
                generalizedError: 'Sijaintisi karkeistus ei jostain syystä onnistunut', // #fi#
            },
            type: {
                group: 'Ryhmän saalisilmoitus',
                personal: 'Henkilökohtainen saalisilmoitus',
            },
            FIRST_NAME: 'Etunimi',
            LAST_NAME: 'Sukunimi',
            BIRTH_DATE: 'Syntymäpäivä',
            STREET_ADDRESS: 'Street address',
            MUNICIPALITY: 'Katuosoite',
            COUNT: 'Kalastajien määrä',
            fishIcon: 'Kalakuvake',
            userId: 'Käyttäjätunnus',
            catchDeclarationId: 'ilmoitus ID',
            windowText: 'Etsi ilmoituksen parametrillä',
            species: 'Laji',
            location: 'Paika',
            fishingMethod: 'Pyyntitapa',
            fisherGroupCount: 'Ryhmän #fi#',
            primaryInfo: {
                title: 'Saalis ilmoitus',
                description: 'Tarkista ja muokkaa saalis ilmoitukset',
            },
            newDeclaration: {
                title: {
                    group: 'Uusi saalisilmoitus - Kalastusmatkayritys', // #fi#
                    personal: 'Uusi saalisilmoitus - Henkilökohtainen', // #fi#
                },
                fisherInfoTitle: 'Ilmoittajatiedot',
                createNewCatch: 'Uusi saalis',
                catchInfoTitle: 'Saalistiedot',
                submitDeclaration: 'Ilmoituksen lähettäminen',
                messages: {
                    validateErrorMessage: 'Jotain on pielessä, tarkista vielä', // #fi#
                    releaseCountGreaterThanCountMessage: 'Vapautettujen lukumäärä ei voi olla suurempi kuin kokonaismäärä', // #fi#
                    adiposeFinCutCountGreaterThanCountMessage: 'Rasvaevällisten yksilöiden määrä ei voi olla suurempi kuin kokonaismäärä', // #fi#
                    invalidAdiposeFinRealeaseCountMessage:
                        'Rasvaevällisten yksilöiden vapautettujen määrä ei voi olla suurempi kuin vapautettujen yksilöiden kokonaismäärä tai rasvaevällisten määrä', // #fi#
                },
                declarationCatchValidationError: {
                    [DeclarationCatchValidationError.DUPLICATE_CATCH]: 'Saalis on identtinen aiemmin lisätyn saaliin kanssa', // #fi#
                    [DeclarationCatchValidationError.INVALID_REQUIREMENT_ID_REFERENCE]:
                        'Virheellinen tunnus - ota yhteyttä omakala@luke.fi', // #fi#
                    [DeclarationCatchValidationError.INVALID_CATCH_REFERENCE_ID_FORMAT]:
                        'Virheellinen vaatimusviite - ota yhteyttä omakala@luke.fi', // #fi#
                    [DeclarationCatchValidationError.INVALID_DATE_FORMAT]: 'Virheellinen päivämäärämuoto - ota yhteyttä omakala@luke.fi', // #fi#
                },
            },
            editDeclaration: {
                title: 'Muokkaa ilmoitus #fi#',
                saveDeclaration: 'Ilmoituksen tallentaminen #fi#',
                editText:
                    'Tämä saalis on jo ilmoitettu saalisrekisteriin. Mikäli haluat muuttaa lakisääteiseen ilmoitukseen liittyviä tietoja, ole yhteydessä Luonnonvarakeskukseen.',
            },
            fisherProperties: {
                [DeclarationFisherRequirementPropertyName.FIRST_NAME]: 'Etunimi',
                [DeclarationFisherRequirementPropertyName.LAST_NAME]: 'Sukunimi',
                [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: 'Kunta',
                [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: 'Syntymäpäivä',
                [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: 'Osoite',
                [DeclarationFisherRequirementPropertyName.COUNT]: 'Henkilömäärä',
            },
            catchProperties: {
                SPECIES: 'Laji',
                WEIGHT: 'Paino',
                LENGTH: 'Pituus',
                CATCH_DATE: 'Päivämäärä',
                CATCH_GEAR: 'Menetelmä',
                CATCH_GEAR_NODE_SPACING: 'Solmuväli',
                CATCH_GEAR_HEIGHT: 'Allodat',
                CATCH_GEAR_LENGTH: 'Guhkkodat',
                CATCH_GEAR_WIRE_STRENGTH: 'árppu gassodat',
                COUNT: 'Kreivi',
                RELEASE_COUNT: 'Julkaistu',
                ADIPOSE_FIN_CUT_COUNT: 'Rasvaevällisten yksilöiden lukumäärä', // #fi#
                ADIPOSE_FIN_CUT_RELEASE_COUNT: 'Vapautettujen rasvaevällisten yksilöiden lukumäärä', // #fi#
            },
            fetchMoreRows: 'Näytä lisää',
            addNew: 'lisätä',
            resultsFound: 'tuloksia löytyi',
            noResultsFound: 'Ei tuloksia',
            source: 'Lähde',
            automatic: 'Mobiili, automaatti ilmoitus', // #fi#
            manual: 'Mobiili, manuaali-ilmoitus', // #fi#
            webform: 'Web-sivu', // #fi#
            newLocationSetInfo: 'Saaliin sijaintia on muutettu. Karkeistettu sijainti päivitetään tallennettaessa',
            noLocationSetInfo: 'Valitse sijainti kartalta. Se yleistetään tallentamisen jälkeen',
            fisherPersonalType: 'Henkilökohtainen',
            fisherGroupType: 'Ryhmäinen',
            singleCatchType: 'Yksittäissaalis',
            singleCatchRelease: 'Vapautettu', // #fi#
            singleCatchAdiposeFin: 'Rasvaevä leikattu', // #fi#
            singleCatchWeight: 'Paino', // #fi#
            bulkCatchType: 'Massasaalis',
            deletionReason: 'Ilmoita saalisilmoituksen poistamisen syy',
            declarationCatchDetailDeleteAlert: 'Tämä saalis poistetaan seuraavan syyn vuoksi: {} #fi#',
            declarationCatchDetailUnsavedAlert: 'Tämä saalis lisätään ilmoitukseen tallennuksen jälkeen #missing#',
            reason: 'Syy',
            declarationDeleted: 'Ilmoitus on poistettu',
            declarationSaved: 'Ilmoitus on tallennettu onnistuneesti!',
            requestFailed: 'Yllättävä virhetilanne, yritä uudelleen tai ota yhteyttä omakala@luke.fi',
            fisherGroupFieldPrefix: 'Järjestäjä',
            notNeededEx: {
                DECLARER_UNDER_AGE: 'Ilmoitusta ei vaadita alle 16-vuotiailta',
                OUTSIDE_DECLARATION_AREA: 'Valitulla alueella ilmoittaminen on vapaaehtoista',
                ALREADY_DECLARED: 'Tyyppi "{}" on jo ilmoitettu',
                TAXONOMY_NOT_REQUIRED: 'Tyyppi "{}" ei vaadi määritystä valitulle alueelle',
                NO_REQUIREMENTS_ACTIVE: 'Mikään ilmoitusvaatimus ei ole aktiivinen',
                OTHER: 'Tuntematon syy',
            },
            notifications: {
                delete: {
                    lukeEmployeeNotice:
                        'Saalisilmoitus on poistettu lakisääteisestä rekisteristä. Mikäli saalis oli kokonaan virheellinen se tulee poistaa myös henkilökohtaisesta saalislokista. Jos kyseessä on korjaus tietoihin, tulee tiedot korjata henkilökohtaiseen saalislokiin. \n\nMikäli käyttäjällä on automaattinen saalisilmoitus käytössä (joka on oletus), hänen tulee poistaa/korjata tiedot henkilökohtaiseen saalislokiinsa saman päivän aikana, koska muuten saalis ilmoitetaan uudelleen',
                },
            },
            needToUpdateProfile: 'Jotta voit lähettää ilmoituksia, sinun on päivitettävä profiilisi näillä ominaisuuksilla:', // #fi#
            userShouldUseApp: 'Suosittelemme ilmoittamaan saaliit Omakala-sovelluksella', // #fi#
            regulation: {
                [DeclarationRegulation.INVASIVE_SET_FREE]: 'Toivottavaa olisi, ettei vieraslajeja vapauteta', // #fi#
                [DeclarationRegulation.ADIPOSE_FIN_SET_FREE]:
                    'Säädösten mukaan rasvaevällinen {{ species }} on vapautettava tietyillä alueilla', // #fi#
            },
            waterRegion: 'Vesistöalue', // #fi#
            optionalDeclarationNotis:
                'Tällä alueella ei vaadita lakisääteistä saalisilmoitusta, mutta vapaaehtoisuuden pohjalta sellaisen voi tehdä.', // #fi#
        },
        taxonomyType: {
            [KnownTaxonomyType.ALLIS_SHAD]: 'Pilkkusilli #fi#',
            [KnownTaxonomyType.ALPINE_BULLHEAD]: 'Kirjoeväsimppu #fi#',
            [KnownTaxonomyType.ARCTIC_CHAR]: 'Nieriä #fi#',
            [KnownTaxonomyType.ASP]: 'Toutain #fi#',
            [KnownTaxonomyType.ATLANTIC_SALMON]: 'Lohi #fi#',
            [KnownTaxonomyType.ATLANTIC_STURGEON]: 'Sinisampi #fi#',
            [KnownTaxonomyType.BALTIC_HERRING]: 'Silakka #fi#',
            [KnownTaxonomyType.BELICA]: 'Allikkosalakka #fi#',
            [KnownTaxonomyType.BLACK_GOBY]: 'Mustatokko #fi#',
            [KnownTaxonomyType.BLEAK]: 'Salakka #fi#',
            [KnownTaxonomyType.BRILL]: 'Silokampela #fi#',
            [KnownTaxonomyType.BROADNOSED_PIPEFISH]: 'Särmäneula #fi#',
            [KnownTaxonomyType.BROOK_LAMPREY]: 'Pikkunahkiainen #fi#',
            [KnownTaxonomyType.BROOK_STICKLEBACK]: 'Viisipiikki #fi#',
            [KnownTaxonomyType.BROOK_TROUT]: 'Puronieriä #fi#',
            [KnownTaxonomyType.BROWN_BULLHEAD]: 'Piikkimonni #fi#',
            [KnownTaxonomyType.BROWN_TROUT]: 'Taimen #fi#',
            [KnownTaxonomyType.BURBOT]: 'Made #fi#',
            [KnownTaxonomyType.CHINESE_SLEEPER]: 'Rohmutokko #fi#',
            [KnownTaxonomyType.CHUB]: 'Turpa #fi#',
            [KnownTaxonomyType.COD]: 'Turska #fi#',
            [KnownTaxonomyType.COMMON_BREAM]: 'Lahna #fi#',
            [KnownTaxonomyType.COMMON_CARP]: 'Karppi #fi#',
            [KnownTaxonomyType.COMMON_DAB]: 'Hietakampela #fi#',
            [KnownTaxonomyType.COMMON_GOBY]: 'Liejutokko #fi#',
            [KnownTaxonomyType.COMMON_NASE]: 'Nokkasärki #fi#',
            [KnownTaxonomyType.COMMON_RUDD]: 'Sorva #fi#',
            [KnownTaxonomyType.COMMON_SEASNAIL]: 'Imukala #fi#',
            [KnownTaxonomyType.COMMON_WHITEFISH]: 'Siika #fi#',
            [KnownTaxonomyType.CRUCIAN_CARP]: 'Ruutana #fi#',
            [KnownTaxonomyType.DACE]: 'Seipi #fi#',
            [KnownTaxonomyType.EELPOUT]: 'Kivinilkka #fi#',
            [KnownTaxonomyType.EURASIAN_MINNOW]: 'Mutu #fi#',
            [KnownTaxonomyType.EUROPEAN_ANCHOVY]: 'Sardelli #fi#',
            [KnownTaxonomyType.EUROPEAN_BITTERLING]: 'Katkerokala #fi#',
            [KnownTaxonomyType.EUROPEAN_BULLHEAD]: 'Kivisimppu #fi#',
            [KnownTaxonomyType.EUROPEAN_EEL]: 'Ankerias #fi#',
            [KnownTaxonomyType.EUROPEAN_SEABASS]: 'Meribassi #fi#',
            [KnownTaxonomyType.FIFTEENSPINED_STICKLEBACK]: 'Vaskikala #fi#',
            [KnownTaxonomyType.FLOUNDER]: 'Kampela #fi#',
            [KnownTaxonomyType.FOURBEARD_ROCKLING]: 'Neliviiksimade #fi#',
            [KnownTaxonomyType.FOURHORN_SCULPIN]: 'Härkäsimppu #fi#',
            [KnownTaxonomyType.GARFISH]: 'Nokkakala #fi#',
            [KnownTaxonomyType.GOLDFISH]: 'Kultakala #fi#',
            [KnownTaxonomyType.GRAYLING]: 'Harjus #fi#',
            [KnownTaxonomyType.GREAT_SANDEEL]: 'Isotuulenkala #fi#',
            [KnownTaxonomyType.GUDGEON]: 'Törö #fi#',
            [KnownTaxonomyType.IDE]: 'Säyne #fi#',
            [KnownTaxonomyType.LAKE_TROUT]: 'Harmaanieriä #fi#',
            [KnownTaxonomyType.LAND_LOCKED_ATLANTIC_SALMON]: 'Järvilohi #fi#',
            [KnownTaxonomyType.LESSER_SANDEEL]: 'Pikkutuulenkala #fi#',
            [KnownTaxonomyType.LONGSPINED_BULLHEAD]: 'Piikkisimppu #fi#',
            [KnownTaxonomyType.LUMPSUCKER]: 'Rasvakala #fi#',
            [KnownTaxonomyType.MACKEREL]: 'Makrilli #fi#',
            [KnownTaxonomyType.NINESPINE_STICKLEBACK]: 'Kymmenpiikki #fi#',
            [KnownTaxonomyType.PELED]: 'Peledsiika #fi#',
            [KnownTaxonomyType.PERCH]: 'Ahven #fi#',
            [KnownTaxonomyType.PIKE]: 'Hauki #fi#',
            [KnownTaxonomyType.PIKE_PERCH]: 'Kuha #fi#',
            [KnownTaxonomyType.PINK_SALMON]: 'Kyttyrälohi #fi#',
            [KnownTaxonomyType.PRUSSIAN_CARP]: 'Hopearuutana #fi#',
            [KnownTaxonomyType.PUMPKINSEED]: 'Aurinkoahven #fi#',
            [KnownTaxonomyType.RAINBOW_TROUT]: 'Kirjolohi #fi#',
            [KnownTaxonomyType.RIVER_LAMPREY]: 'Nahkiainen #fi#',
            [KnownTaxonomyType.ROACH]: 'Särki #fi#',
            [KnownTaxonomyType.ROCK_GUNNEL]: 'Teisti #fi#',
            [KnownTaxonomyType.ROUND_GOBY]: 'Mustatäplätokko #fi#',
            [KnownTaxonomyType.RUFFE]: 'Kiiski #fi#',
            [KnownTaxonomyType.RUSSIAN_STURGEON]: 'Venäjänsampi #fi#',
            [KnownTaxonomyType.SAND_GOBY]: 'Hietatokko #fi#',
            [KnownTaxonomyType.SEA_LAMPREY]: 'Merinahkiainen #fi#',
            [KnownTaxonomyType.SHORTHORN_SCULPIN]: 'Isosimppu #fi#',
            [KnownTaxonomyType.SIBERIAN_STURGEON]: 'Siperiansampi #fi#',
            [KnownTaxonomyType.SICHEL]: 'Miekkasärki #fi#',
            [KnownTaxonomyType.SILVER_BREAM]: 'Pasuri #fi#',
            [KnownTaxonomyType.SMELT]: 'Kuore #fi#',
            [KnownTaxonomyType.SNAKE_BLENNY]: 'Elaska #fi#',
            [KnownTaxonomyType.SPINED_LOACH]: 'Rantanuoliainen #fi#',
            [KnownTaxonomyType.SPRAT]: 'Kilohaili #fi#',
            [KnownTaxonomyType.STONE_LOACH]: 'Kivennuoliainen #fi#',
            [KnownTaxonomyType.STRAIGHTNOSE_PIPEFISH]: 'Siloneula #fi#',
            [KnownTaxonomyType.STURGEON]: 'Sampi #fi#',
            [KnownTaxonomyType.TENCH]: 'Suutari #fi#',
            [KnownTaxonomyType.THREESPINE_STICKLEBACK]: 'Kolmipiikki #fi#',
            [KnownTaxonomyType.TURBOT]: 'Piikkikampela #fi#',
            [KnownTaxonomyType.TWAITE_SHAD]: 'Täpläsilli #fi#',
            [KnownTaxonomyType.TWO_SPOTTED_GOBY]: 'Seitsenruototokko #fi#',
            [KnownTaxonomyType.VENDACE]: 'Muikku #fi#',
            [KnownTaxonomyType.VIMBA_BREAM]: 'Vimpa #fi#',
            [KnownTaxonomyType.WELS_CATFISH]: 'Monni #fi#',
            [KnownTaxonomyType.WHITE_FINNED_GUDGEON]: 'Valkoevätörö #fi#',
            [KnownTaxonomyType.ZOPE]: 'Sulkava #fi#',
            [KnownTaxonomyType.OTHER]: 'Muut #fi#',
        },
        taxonomyTypeProperty: {
            [TaxonomyTypePropertyName.ADIPOSE_FIN_CUT]: {
                name: 'rasvaevä leikattu #fi#',
                bulkName: 'rasvaevä leikattu #fi#',
                estimatedName: 'rasvaevä leikattu #fi#',
            },
            [TaxonomyTypePropertyName.ADIPOSE_FIN]: {
                name: 'rasvaevä ', // #fi#
                bulkName: 'rasvaevä', // #fi#
                estimatedName: 'rasvaevä', // #fi#
            },
            [TaxonomyTypePropertyName.CATCH_AND_RELEASE]: {
                name: 'saalis vapautettu #fi#',
                bulkName: 'saalis vapautettu #fi#',
                estimatedName: 'saalis vapautettu #fi#',
            },
            [TaxonomyTypePropertyName.LENGTH]: {
                name: 'pituus #fi#',
                bulkName: 'kokonaispituus #fi#',
                estimatedName: 'arvioitu pituus #fi#',
            },
            // these three properties are only received for data validation,
            // and shouldn't be displayed to the user - which is why they're empty
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_1]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_2]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.MUST_RELEASE_NON_ADIPOSE_FIN]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.WEIGHT]: {
                name: 'paino #fi#',
                bulkName: 'kokonaispaino #fi#',
                estimatedName: 'arvioitu paino #fi#',
            },
        },
        map: {
            searchBar: {
                clearResults: 'Gurre ohcanbohtosiid',
                label: 'Oza',
                noResultsFound: 'Ohcanbohtosat eai gávdnon',
                placeholder: 'Oza...',
                resultsFound: 'Gávdnojedje {{ count }} ohcanbohtosa',
                requestError: 'Ohcama ii sáhttán čađahit. Duogášvuogádahkii ii oaččo oktavuođa.',
                result: {
                    region: 'Guovlu',
                    subregion: 'Oassi',
                    municipality: 'Gielda',
                    placeType: 'Tiipa',
                    placeTypeDescription: 'Govvádus',
                    placeTypeCategory: 'Kategoriija',
                    placeTypeGroup: 'Joavku',
                    placeTypeSubgroup: 'Vuollejoavku',
                },
            },
            layers: {
                [MapLayer.ACTIVITY]: { name: 'Guolástandáhpáhusat', settings: {} },
                [MapLayer.CATCH]: { name: 'Sálašdáhpáhusat', settings: {} },
                [MapLayer.WFS_FISHING_RESTRICTIONS]: { name: 'Guolástanráddjehusat', settings: {} },
                [MapLayer.HIGHLIGHT_LAYER]: { name: 'Válljejuvvon čuozáhagat', settings: {} },
                [MapLayer.POINT_OF_INTEREST]: { name: 'Čuozáhatčuoggát', settings: {} },
                [MapLayer.POSITION]: { name: 'Sajádat', settings: {} },
                [MapLayer.ROUTE]: { name: 'Máđidja', settings: {} },
                [MapLayer.ORTHOPHOTO_LAYER]: { name: 'Áibmogovva', settings: {} },
                [MapLayer.VECTOR_LAYER]: { name: 'Oppalaškárta', settings: {} },
                [MapLayer.TERRAIN_LAYER]: { name: 'Eanadatkárta', settings: {} },
                [MapLayer.DEPTH_CURVES]: { name: 'Čikŋodatgávat', settings: {} },
                [MapLayer.WMS_FISHING_RESTRICTIONS]: {
                    name: 'Guolástanráddjehusat',
                    settings: {
                        [LayerCommonSettingName.FROM_DATE]: 'Beaivemearis',
                        [LayerCommonSettingName.TO_DATE]: 'Beaivemearrái',
                        deviatingRestrictionsOnly: 'Dušše spiehkkaseaddji bivdomihtut ja ráfáiduhttináiggit',
                    },
                },
            },
            settings: {
                mapLayer: {
                    title: 'Kártadásiid stellemat',
                },
            },
        },
    },
};

export default translations;
