import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Grid, TextField, InputAdornment, IconButton, Button, CircularProgress } from '@mui/material';
import { PASSWORD_MIN_LENGTH } from '@oma-kala-shared/core/logic';
import { ChangePasswordRequest, ContentResponse, TokenData, UserData } from '@oma-kala-shared/core/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PasswordValidationDisplay from 'app/components/common/PasswordValidationDisplay';
import BrowserWebService from 'app/logic/BrowserWebService';
import { ExtendedPasswordValidationResult } from 'app/model/ExtendedPasswordValidationResult';
import { setErrorMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { store } from 'app/state/store';
import { createWebService, logoutUser } from 'app/state/thunks';
import { selectUserData } from 'app/state/user/userSlice';

export const ChangePasswordForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData: UserData | null = useSelector(selectUserData);
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState<ExtendedPasswordValidationResult>({
        isValid: false,
        length: false,
        lowercase: false,
        uppercase: false,
        digit: false,
        special: false,
        passwordConfirmation: false,
        isNewPasswordDifferentThanNewOne: false,
    });

    const handlePasswordValidation = (password: string, passwordConfirmation: string, currentPassword: string) => {
        const isLengthValid = password.length >= PASSWORD_MIN_LENGTH;
        const includesLowercase = password.toUpperCase() !== password;
        const includesUppercase = password.toLowerCase() !== password;
        const includesInteger = /\d/.test(password);
        const includesSpecialCharacter = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password);
        const isPasswordConfirmationValid = password === passwordConfirmation && password.length > 0;
        const isNewPasswordDifferentThanNewOne = password !== currentPassword && currentPassword.length > 0;
        const validationResult: ExtendedPasswordValidationResult = {
            isValid:
                isLengthValid &&
                includesLowercase &&
                includesUppercase &&
                includesInteger &&
                includesSpecialCharacter &&
                isPasswordConfirmationValid,
            length: isLengthValid,
            lowercase: includesLowercase,
            uppercase: includesUppercase,
            digit: includesInteger,
            special: includesSpecialCharacter,
            passwordConfirmation: isPasswordConfirmationValid,
            isNewPasswordDifferentThanNewOne: isNewPasswordDifferentThanNewOne,
        };
        return validationResult;
    };

    useEffect(() => {
        const passwordValidationResult: ExtendedPasswordValidationResult = handlePasswordValidation(
            password,
            passwordConfirmation,
            currentPassword
        );
        setPasswordValidation(passwordValidationResult);
    }, [password, passwordConfirmation]);

    const handleNewPasswordSave = async () => {
        const userId = userData?.id;
        if (!userId) {
            return;
        }
        setIsSendingRequest(true);
        const webService: BrowserWebService = await createWebService(dispatch, store.getState);
        const request: ChangePasswordRequest = { userId, currentPassword, newPassword: password };
        const response: ContentResponse<TokenData> = await webService.changePassword(request);

        setIsSendingRequest(false);
        if (response.successful) {
            dispatch(setSuccessMessage(t('passwordProperties.messages.passwordChanged')));
        } else {
            console.debug('change password request failed with message:', response.message);
            dispatch(setErrorMessage(t('passwordProperties.messages.passwordNotChanged')));
        }
    };

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        value={currentPassword}
                        onChange={e => setCurrentPassword(e.target.value)}
                        label={'Current Password'}
                        size="small"
                        type={isCurrentPasswordVisible ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setIsCurrentPasswordVisible(!isCurrentPasswordVisible)}
                                    >
                                        {isCurrentPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        label={'New Password'}
                        size="small"
                        type={isPasswordVisible ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    >
                                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        value={passwordConfirmation}
                        onChange={e => setPasswordConfirmation(e.target.value)}
                        label={'Repeat New Password'}
                        type={isPasswordConfirmationVisible ? 'text' : 'password'}
                        size={'small'}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password confirmation visibility"
                                        onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                                    >
                                        {isPasswordConfirmationVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Box mt={2}>
                <PasswordValidationDisplay passwordValidation={passwordValidation} />
            </Box>
            <Box mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNewPasswordSave()}
                    fullWidth
                    disabled={!passwordValidation.isValid || isSendingRequest}
                >
                    {isSendingRequest && <CircularProgress color="inherit" size={16} style={{ marginRight: 6 }} />}
                    {t('common.save')}
                </Button>
            </Box>
        </Box>
    );
};
