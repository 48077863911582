import {
    DeclarationCatchDetails,
    DeclarationCatchProperty,
    DeclarationFisherRequirementPropertyName,
    DeclarationRequirementDetails,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    UserData,
} from '@oma-kala-shared/core/model';
import { getSetting, storeSetting } from './StorageService';

export type DeclarationStorageKey = 'useAppWarningDisabled';

export const getMissingFisherProperties = (userData: UserData, requirements: DeclarationRequirementDetails) => {
    const nullOrEmpty = (str: string | null | undefined) => {
        return !str || str.trim().length === 0;
    };

    const missingProperties: DeclarationFisherRequirementPropertyName[] = [];

    if (
        requirements.requiredFisherProperties.find(x => x.name === DeclarationFisherRequirementPropertyName.FIRST_NAME) &&
        nullOrEmpty(userData.firstName)
    ) {
        missingProperties.push(DeclarationFisherRequirementPropertyName.FIRST_NAME);
    }
    if (
        requirements.requiredFisherProperties.find(x => x.name === DeclarationFisherRequirementPropertyName.LAST_NAME) &&
        nullOrEmpty(userData.lastName)
    ) {
        missingProperties.push(DeclarationFisherRequirementPropertyName.LAST_NAME);
    }
    if (
        requirements.requiredFisherProperties.find(x => x.name === DeclarationFisherRequirementPropertyName.BIRTH_DATE) &&
        nullOrEmpty(userData.birthDateIso)
    ) {
        missingProperties.push(DeclarationFisherRequirementPropertyName.BIRTH_DATE);
    }
    if (
        requirements.requiredFisherProperties.find(x => x.name === DeclarationFisherRequirementPropertyName.STREET_ADDRESS) &&
        nullOrEmpty(userData.addressStreet)
    ) {
        missingProperties.push(DeclarationFisherRequirementPropertyName.STREET_ADDRESS);
    }
    if (
        requirements.requiredFisherProperties.find(x => x.name === DeclarationFisherRequirementPropertyName.MUNICIPALITY) &&
        nullOrEmpty(userData.municipality)
    ) {
        missingProperties.push(DeclarationFisherRequirementPropertyName.MUNICIPALITY);
    }
    return missingProperties;
};

/**
 * Stores bool controlling if info message should be shown
 * @param {boolean} disabled The data to store
 */
export function storeUseAppWarningDisabled(disabled: boolean) {
    storeSetting('useAppWarningDisabled', JSON.stringify(disabled));
}

/**
 * Gets bool controlling if info message should be shown
 * @param {boolean} disabled The data to store
 * @return {boolean}
 */
export function getUseAppWarningDisabled(): boolean {
    const val = getSetting('useAppWarningDisabled');
    if (!val) return false;
    return JSON.parse(val);
}

export const getMissingCatchProperties = (c: DeclarationCatchDetails, r: DeclarationSpeciesRequirementDetails) => {
    const nullOrEmpty = (str: string | null | undefined) => {
        return !str || str.trim().length === 0;
    };
    const nullOrEmptyProp = (props: DeclarationCatchProperty[], type: DeclarationSpeciesRequirementPropertyName) => {
        const prop = props.find(x => x.propertyName === type);
        if (!prop) return true;
        return nullOrEmpty(prop.value);
    };

    const req = r.requiredTaxonomyProperties;
    const properties = [
        DeclarationSpeciesRequirementPropertyName.CATCH_GEAR,
        DeclarationSpeciesRequirementPropertyName.COUNT,
        DeclarationSpeciesRequirementPropertyName.LENGTH,
        DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT,
        DeclarationSpeciesRequirementPropertyName.WEIGHT,
    ];

    const missingProperties = properties.filter(p => req.some(r => r.name === p) && nullOrEmptyProp(c.catchProperties, p));

    if (req.find(x => x.name === DeclarationSpeciesRequirementPropertyName.CATCH_DATE) && nullOrEmpty(c.catchTime)) {
        missingProperties.push(DeclarationSpeciesRequirementPropertyName.CATCH_DATE);
    }
    return missingProperties;
};
