import { styled } from '@mui/material';
import { ContentResponse, IdentificationData, UserData } from '@oma-kala-shared/core/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { setErrorMessage, setInfoMessage } from 'app/state/message/messageSlice';
import { createWebService } from 'app/state/thunks';

import { RootState } from 'app/state/store';
import LandingWrapper from '../landing/LandingWrapper';
import { RegistrationForm } from './RegistrationForm';

export const RegistrationPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useStore<RootState>();
    const { t } = useTranslation();

    const [identificationId, setIdentificationId] = useState<string | null>(null);
    const [userIdentificationData, setUserIdentificationData] = useState<IdentificationData | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const fetchAndVerifyUserData = async (identificationId: string, encodedUserIdentificationData: string) => {
            const userData = await fetchUserDataByIdentificationId(identificationId);
            if (userData.successful && userData?.content?.id) {
                dispatch(setInfoMessage(t('registration.alradyHaveAccount')));
                navigate('/auth', { state: { showSvkMessage: true } });
                return;
            }
            const parsedUserIdentificationData: IdentificationData = JSON.parse(decodeURIComponent(atob(encodedUserIdentificationData)));
            setUserIdentificationData(parsedUserIdentificationData);
            setIdentificationId(identificationId);
        };

        const identificationIdFromUrl = searchParams.get('identificationId');
        const encodedUserIdentificationData = searchParams.get('userData');
        if (!identificationIdFromUrl || !encodedUserIdentificationData) {
            dispatch(setErrorMessage(t('registration.identificationUnsuccessful')));
            navigate('/auth');
            return;
        }
        fetchAndVerifyUserData(identificationIdFromUrl, encodedUserIdentificationData);
    }, []);

    const fetchUserDataByIdentificationId = async (identificationId: string) => {
        const webService = await createWebService(dispatch, store.getState);
        const resp: ContentResponse<UserData> = await webService.getUserByIdentification(identificationId);
        return resp;
    };

    return (
        // <ContentPage title={t('registration.title')}>
        //     {userIdentificationData && identificationId && (
        //         <RegistrationForm userIdentificationData={userIdentificationData} identificationId={identificationId} />
        //     )}
        // </ContentPage>
        <LandingWrapper
            visibleBackButton={true}
            visibleLoginButton={false}
            main={
                <Wrapper>
                    {userIdentificationData && identificationId && (
                        <RegistrationForm userIdentificationData={userIdentificationData} identificationId={identificationId} />
                    )}
                </Wrapper>
            }
        />
    );
};

const Wrapper = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    background: 'linear-gradient(180deg, rgba(30, 41, 59, 0) 0%, #1E293B 100%)',
});
