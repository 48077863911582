import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestState } from 'app/model';
import { RootState } from 'app/state/store';

export interface SyncState {
    tokenStates: { [syncToken: string]: RequestState };
}

export const initialState: SyncState = {
    tokenStates: {},
};

const syncSlice = createSlice({
    name: 'sync',
    initialState: initialState,
    reducers: {
        setSyncState: (state: SyncState, action: PayloadAction<{ syncToken: string; value: RequestState }>) => {
            state.tokenStates[action.payload.syncToken] = action.payload.value;
        },
        clearSyncState: (state: SyncState, action: PayloadAction<string>) => {
            delete state.tokenStates[action.payload];
        },
    },
});

export const { setSyncState, clearSyncState } = syncSlice.actions;

export const selectTokenSyncState =
    (syncToken: string) =>
    (state: RootState): RequestState | undefined =>
        state.sync.tokenStates[syncToken];

export default syncSlice.reducer;
