import MapContext from 'app/components/map/MapContext';
import { useContext, useEffect, useRef } from 'react';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';

interface MapHandler {
    init: () => void;
    destroy: () => void;
    setLocation: (coordinate: Coordinate, animateToLocation: boolean) => void;
}

const useMapLayer = <T extends MapHandler>(initializeHandler: (map: Map) => T) => {
    const map = useContext(MapContext);
    const handlerRef = useRef<T | null>(null);

    useEffect(() => {
        if (!map) return;

        if (handlerRef.current === null) {
            handlerRef.current = initializeHandler(map);
        } else {
            handlerRef.current.init();
        }

        return () => {
            handlerRef.current?.destroy();
        };
    }, [map, initializeHandler]);

    return handlerRef.current;
};

export default useMapLayer;
