import { Blocker } from 'history';
import React from 'react';
import useBlocker from './useBlocker';

// eslint-disable-next-line require-jsdoc
export function usePrompt(message: string, when: boolean) {
    const blocker: Blocker = React.useCallback(
        tx => {
            if (window.confirm(message)) {
                tx.retry();
            }
        },
        [message]
    );

    useBlocker(blocker, when);
}

export default usePrompt;
