import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getStoreButtonImage, StoreVariantEnum } from '../logic/ImageService';

/**
 *
 */
export type StoreImagesButton = [string, string];

/**
 * @return {StoreImagesButton} SyncTokenData
 */
export function useStoreButtonImage(): StoreImagesButton {
    const { t, i18n } = useTranslation();

    const [appStoreButtonImage, setAppStoreButtonImage] = useState(getStoreButtonImage(i18n.language, StoreVariantEnum.APP_STORE));
    const [playStoreButtonImage, setPlayStoreButtonImage] = useState(getStoreButtonImage(i18n.language, StoreVariantEnum.PLAY_STORE));

    useEffect(() => {
        setAppStoreButtonImage(getStoreButtonImage(i18n.language, StoreVariantEnum.APP_STORE));
        setPlayStoreButtonImage(getStoreButtonImage(i18n.language, StoreVariantEnum.PLAY_STORE));
    }, [i18n.language]);

    return [appStoreButtonImage, playStoreButtonImage];
}
