import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { getMandatoryOrOptionalTranslation } from '@oma-kala-shared/core/logic/TranslationService';
import WebService from '@oma-kala-shared/core/logic/WebService';
import {
    CMSTag,
    ContentResponse,
    IdentificationData,
    OmaKalaLanguageCode,
    RegisterUserRequest,
    RegistrationResponse,
} from '@oma-kala-shared/core/model';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PasswordValidationDisplay from 'app/components/common/PasswordValidationDisplay';
import PhoneNumberInput from 'app/components/common/inputs/PhoneNumberInput';
import BrowserWebService from 'app/logic/BrowserWebService';
import { clearToken, getToken } from 'app/logic/LoginService';
import { validatePassword } from 'app/logic/ValidationService';
import { ExtendedPasswordValidationResult } from 'app/model/ExtendedPasswordValidationResult';
import { selectTermsAndConditionsVersion } from 'app/state/cms/cmsSlice';
import { setErrorMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { createWebService } from 'app/state/thunks';
import { fetchCmsTermsAndConditionsMessages } from 'app/state/thunks/fetchCmsTermsAndConditionsMessages';
import { getPermanentTokenAndInitialize } from 'app/state/thunks/getPermanentTokenAndInitialize';

import { TermsAndConditionsPage } from '../termsAndConditions/TermsAndConditionsPage';
import { RegistrationSectionWrapper } from './RegistrationSectionWrapper';
import { TermsAndConditionsCard } from 'app/components/layout/TermsAndConditionsCard';

interface RegistrationFormProps {
    userIdentificationData: IdentificationData;
    identificationId: string;
}

export const RegistrationForm = ({ userIdentificationData, identificationId }: RegistrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const termsAndConditionsVersion = useSelector(selectTermsAndConditionsVersion);
    const { t } = useTranslation();
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [email, setEmail] = useState(userIdentificationData.email ?? '');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [streetAddress, setStreetAddress] = useState(
        userIdentificationData.permanentFinnishAdressStreetFi ?? userIdentificationData.permanentForeignAddressPlainText ?? ''
    );
    const [postalCode, setPostalCode] = useState(userIdentificationData.permanentFinnishAddressPostalCode ?? '');
    const [postalTown, setPostalTown] = useState(userIdentificationData.permanentFinnishAddressPostalTownFi ?? '');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberCode, setPhoneNumberCode] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [emailError, setEmailError] = useState<string | null>(null);

    const [passwordVisibilityProperties, setPasswordVisibilityProperties] = useState({
        isPasswordVisible: false,
        isConfirmPasswordVisible: false,
    });

    useEffect(() => {
        dispatch(fetchCmsTermsAndConditionsMessages(i18next.language));
    }, [i18next.language]);

    const [isTermsAndConditionsPageVisible, setIsTermsAndConditionsPageVisible] = useState(false);

    const [passwordValidation, setPasswordValidation] = useState<ExtendedPasswordValidationResult>({
        isValid: false,
        length: false,
        lowercase: false,
        uppercase: false,
        digit: false,
        special: false,
        passwordConfirmation: false,
    });

    useEffect(() => {
        const passwordValidationResult = validatePassword(password, passwordConfirmation);
        setPasswordValidation(passwordValidationResult);
    }, [password, passwordConfirmation]);

    useEffect(() => {
        if (email.length < 1 || !emailError) return;
        handleEmailValidation(email);
    }, [email]);

    const handleEmailValidation = (email: string) => {
        if (email.length < 1) {
            setEmailError(t('registration.validation.email.empty'));
            return;
        }
        const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
        if (!isEmailValid) {
            setEmailError(t('registration.validation.email.format'));
            return;
        }
        setEmailError(null);
    };
    const tempToken = getToken();
    const handleOpenTermsAndConditions = () => setIsTermsAndConditionsPageVisible(true);
    const handleCloseTermsAndConditions = () => setIsTermsAndConditionsPageVisible(false);

    const togglePasswordConfirmationVisibility = () =>
        setPasswordVisibilityProperties(curr => ({ ...curr, isConfirmPasswordVisible: !curr.isConfirmPasswordVisible }));
    const togglePasswordVisibility = () =>
        setPasswordVisibilityProperties(curr => ({ ...curr, isPasswordVisible: !curr.isPasswordVisible }));

    const handlePhoneNumberChange = (phoneNumber: string, countryCode: string) => {
        setPhoneNumber(phoneNumber);
        setPhoneNumberCode(countryCode);
    };
    const store = useStore();
    const handleFormSubmit = async () => {
        setIsSendingRequest(true);
        let service: WebService | null = null;
        const tempToken = getToken();
        if (tempToken) {
            service = new BrowserWebService(tempToken.access_token);
        } else {
            service = await createWebService(dispatch, store.getState);
        }
        const registrationRequest: RegisterUserRequest = prepareRegistrationRequest();

        const response: ContentResponse<RegistrationResponse> = await service.registerUser(registrationRequest);

        setIsSendingRequest(false);
        if (response.successful) {
            dispatch(setSuccessMessage(t('registration.messages.registrationSuccessful')));
            if (!tempToken) {
                navigate('/auth');
                return;
            }
            dispatch(
                getPermanentTokenAndInitialize(
                    tempToken,
                    error => {
                        dispatch(setErrorMessage(t(error)));
                        navigate('/auth');
                    },
                    () => {
                        navigate('/');
                    }
                )
            );

            clearToken();
        } else {
            dispatch(setErrorMessage(t('registration.messages.registrationUnsuccessful')));
            navigate('/auth');
            clearToken();
        }
    };

    const prepareRegistrationRequest = () => {
        const preparedRegistrationRequest: RegisterUserRequest = {
            identificationId,
            addressMunicipality: userIdentificationData.homeMunicipalityNameFi,
            addressPostalCode: postalCode,
            addressPostalCountry: 'FI',
            addressPostalTown: postalTown,
            addressStreet: streetAddress,
            alias: `${userIdentificationData?.firstName} ${userIdentificationData?.lastName}`,
            applicationLanguage: i18next.language as OmaKalaLanguageCode,
            businessLanguage: i18next.language as OmaKalaLanguageCode,
            email: email,
            firstName: userIdentificationData?.lastName,
            lastName: userIdentificationData?.firstName,
            password: password,
            phoneCountryCode: phoneNumberCode,
            phoneNumber: phoneNumber,
            sex: null,
            termsAccepted: termsAccepted,
            termsAcceptedVersion: termsAndConditionsVersion,
        };
        return preparedRegistrationRequest;
    };

    return (
        <Card sx={root}>
            <Typography variant="h4" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0' }}>
                {t('registration.registration')}
            </Typography>
            <RegistrationSectionWrapper title="" description="">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label={getMandatoryOrOptionalTranslation(t('common.name'), true, t)}
                            value={`${userIdentificationData?.firstName} ${userIdentificationData?.lastName}`}
                            InputProps={{
                                readOnly: true,
                                sx: input,
                            }}
                            InputLabelProps={{
                                sx: label,
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label={getMandatoryOrOptionalTranslation(t('common.email'), true, t)}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onBlur={() => handleEmailValidation(email)}
                            helperText={emailError ?? ''}
                            error={!!emailError}
                            InputProps={{
                                sx: input,
                            }}
                            InputLabelProps={{
                                sx: label,
                            }}
                        />
                    </Grid>
                </Grid>
            </RegistrationSectionWrapper>

            <RegistrationSectionWrapper
                title={t('registration.sections.contactInfo.title')}
                description={t('registration.sections.contactInfo.description')}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={getMandatoryOrOptionalTranslation(t('common.address.street'), false, t)}
                            value={streetAddress}
                            onChange={e => setStreetAddress(e.target.value)}
                            InputProps={{
                                sx: input,
                            }}
                            InputLabelProps={{
                                sx: label,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={getMandatoryOrOptionalTranslation(t('common.address.postalCode'), false, t)}
                            value={postalCode}
                            onChange={e => setPostalCode(e.target.value)}
                            InputProps={{
                                sx: input,
                            }}
                            InputLabelProps={{
                                sx: label,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={getMandatoryOrOptionalTranslation(t('common.address.postalTown'), false, t)}
                            value={postalTown}
                            onChange={e => setPostalTown(e.target.value)}
                            InputProps={{
                                sx: input,
                            }}
                            InputLabelProps={{
                                sx: label,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneNumberInput
                            label={getMandatoryOrOptionalTranslation(t('common.phoneNumber'), false, t)}
                            name={'phone number'}
                            number={phoneNumber}
                            countryCode={phoneNumberCode}
                            handleChange={handlePhoneNumberChange}
                            specialLabel={''}
                        />
                    </Grid>
                </Grid>
            </RegistrationSectionWrapper>
            {!tempToken && (
                <RegistrationSectionWrapper
                    title={t('registration.sections.password.title')}
                    description={t('registration.sections.password.description')}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label={getMandatoryOrOptionalTranslation(t('common.password'), true, t)}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type={passwordVisibilityProperties.isPasswordVisible ? 'text' : 'password'}
                                InputProps={{
                                    sx: input,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => togglePasswordVisibility()}>
                                                {passwordVisibilityProperties.isPasswordVisible ? (
                                                    <Visibility sx={{ color: 'black' }} />
                                                ) : (
                                                    <VisibilityOff sx={{ color: 'black' }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    sx: label,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                value={passwordConfirmation}
                                onChange={e => setPasswordConfirmation(e.target.value)}
                                label={getMandatoryOrOptionalTranslation(t('common.confirmPassword'), true, t)}
                                type={passwordVisibilityProperties.isConfirmPasswordVisible ? 'text' : 'password'}
                                InputProps={{
                                    sx: input,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => togglePasswordConfirmationVisibility()}
                                            >
                                                {passwordVisibilityProperties.isConfirmPasswordVisible ? (
                                                    <Visibility sx={{ color: 'black' }} />
                                                ) : (
                                                    <VisibilityOff sx={{ color: 'black' }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    sx: label,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordValidationDisplay passwordValidation={passwordValidation} />
                        </Grid>
                    </Grid>
                </RegistrationSectionWrapper>
            )}
            <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'}>
                    <Checkbox value={termsAccepted} onChange={() => setTermsAccepted(curr => !curr)} />
                    <Typography>
                        {t('registration.acceptTermsAndConditions')}{' '}
                        <Box sx={termsAndCondition}>
                            <a onClick={handleOpenTermsAndConditions}>{t('registration.termsAndConditions')}.</a>
                        </Box>
                    </Typography>
                    <Typography>*</Typography>
                </Box>
            </Grid>

            <Grid container>
                <Button
                    disabled={!((passwordValidation.isValid || tempToken) && email && !emailError && termsAccepted)}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    fullWidth
                    size="large"
                    onClick={handleFormSubmit}
                >
                    {isSendingRequest && <CircularProgress color="inherit" size={16} style={{ marginRight: '0.5rem' }} />}
                    {t('common.submit')}
                </Button>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isTermsAndConditionsPageVisible}
                onClose={handleCloseTermsAndConditions}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box>
                        <TermsAndConditionsCard
                            tagset={{
                                finnish: CMSTag.ELEMENT_TERMS_AND_CONDITIONS,
                                swedish: CMSTag.ELEMENT_TERMS_AND_CONDITIONS_SV,
                                english: CMSTag.ELEMENT_TERMS_AND_CONDITIONS_EN,
                                sami: CMSTag.ELEMENT_TERMS_AND_CONDITIONS_SE,
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTermsAndConditions}>{t('common.close')}</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

const root = {
    background: 'white',
    padding: '30px',
    color: 'black !important',
    borderRadius: '10px',
};
const termsAndCondition = {
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
        borderBottom: '1px solid black',
    },
};
const input = {
    border: '1px solid white',
};
const label = {
    color: '#black !important',
};
