import { OmaKalaLanguageCode } from 'app/model';

import { OmaKalaUserRole } from './UserRole';

/* eslint-disable no-unused-vars */
export interface PushNotification {
    id: string;
    status: NotificationStatus;
    title: string;
    message: string;
    created: string; // ISO String
    language: OmaKalaLanguageCode;
    redirectView: string;
    redirectId: string;
}

export enum NotificationStatus {
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    SENT = 'SENT',
}

/**
 * Represents a view that the user can be redirected to
 * when sending push notifications.
 */
export enum RedirectView {
    // TODO: This should probably be in the shared frontend project
    // to ensure consistency with web/mobile navigation
    NEWS = 'NEWS',
}

export interface PushNotificationMessage {
    title: string;
    message: string;
    lang: OmaKalaLanguageCode;
}

export interface PushNotificationRequest {
    roles: OmaKalaUserRole[];
    messages: PushNotificationMessage[];
    redirectId: string | null;
    redirectView: string | null;
}
