import { Typography } from '@mui/material';
import { CMSItem } from '@oma-kala-shared/core/model';
import { isNumber } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ContentPage } from 'app/components/layout';
import { RequestStatus } from 'app/model';
import { selectCmsPages, selectCmsPagesStatus } from 'app/state/cms/cmsSlice';
import { fetchCmsPages } from 'app/state/thunks/fetchCmsPages';

/**
 * @return {JSX.Element}
 */
export function CmsPostingPage() {
    const { cmsTagName } = useParams();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const cmsPagesStatus: RequestStatus = useSelector(selectCmsPagesStatus);
    const cmsPages: CMSItem[] = useSelector(selectCmsPages);
    const slug = `${cmsTagName}-${i18n.language}`;

    useEffect(() => {
        dispatch(fetchCmsPages(i18n.language, slug));
    }, [i18n.language, slug]);

    useEffect(() => {
        switch (cmsPagesStatus) {
            case RequestStatus.Failed:
                // we do this so that the user is notified of a failure to retrieve news articles
                // dispatch(setErrorMessage(t('registration.termsAndConditions.loadError')));
                break;
            default:
                break;
        }
    }, [cmsPagesStatus]);

    const getPage = (): CMSItem | RequestStatus => {
        switch (cmsPagesStatus) {
            case RequestStatus.Failed:
                return cmsPagesStatus;
            case RequestStatus.NotInitiated:
            case RequestStatus.Loading:
                return cmsPagesStatus;
            default:
                return cmsPages?.length > 0 ? cmsPages[0] : RequestStatus.Failed;
        }
    };

    const getTitle = () => {
        const cmsItem = getPage();
        return isNumber(cmsItem) ? t(`common.loadMessage.${cmsItem}`) : cmsItem.title.rendered;
    };

    const getContent = () => {
        const cmsItem = getPage();
        return isNumber(cmsItem) ? t(`common.loadMessage.${cmsItem}`) : cmsItem.content.rendered;
    };

    return (
        <ContentPage>
            <Typography variant="h2">
                <p dangerouslySetInnerHTML={{ __html: getTitle() }}></p>
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: getContent() }} />
        </ContentPage>
    );
}
