import { Gear, GearType } from '@oma-kala-shared/core/model';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GearForm from 'app/components/common/GearForm';
import { ContentPage } from 'app/components/layout';
import { useSyncToken } from 'app/hooks';
import { selectGearTypes } from 'app/state/gear/gearSlice';
import { saveGear } from 'app/state/thunks/saveGear';
import { updateGear } from 'app/state/thunks/updateGear';

export const GearDetailsPage = () => {
    const { state } = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const gear: Gear = state.gear;
    const gearType: GearType = state.gearType;
    const isNewGear = state.isNewGear;
    const gearTypes: GearType[] = useSelector(selectGearTypes);
    const defaultErrors = gearTypes
        .find(val => val.id === gearType.id)
        ?.properties.filter(type => type.mandatory === true)
        ?.map(val => ({ [val.name]: '' }))
        .reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key];
            return acc;
        }, {});
    const [gearState, setGearState] = useState<Gear>(gear);
    const [propertyErrors, setPropertyErrors] = useState(defaultErrors);
    const [syncToken, loading] = useSyncToken({ onSuccess: () => {}, onError: () => {} });

    const handleChangeGearValue = (e: any) => {
        const { name, value } = e.target;
        setGearState(() => ({
            ...gearState,
            [name]: value,
        }));
    };

    const handleChangePropertyValue = (e: any) => {
        const { id, value, name } = e.target;
        setGearState(prev => ({
            ...prev,
            properties: prev.properties.map(property => (property.id === id ? { ...property, value: value } : property)),
        }));
        if (!value || value === '') {
            setPropertyErrors(() => ({
                ...propertyErrors,
                [name]: t('common.requiredHelpText'),
            }));
        } else
            setPropertyErrors(() => ({
                ...propertyErrors,
                [name]: '',
            }));
    };

    const handleSave = (newGearState: Gear) => {
        if (!isNewGear) {
            dispatch(updateGear(syncToken, gear, newGearState));
        } else {
            dispatch(saveGear(syncToken, newGearState));
        }
    };

    return (
        <ContentPage title={isNewGear ? t('gear.titleNewGear') : t('gear.titleEditGear')}>
            <GearForm
                gearState={gearState}
                loading={loading}
                gearNameValue={gearState.name}
                properties={gearState.properties}
                gearType={gearType}
                handleChangePropertyValue={handleChangePropertyValue}
                handleChangeGearValue={handleChangeGearValue}
                handleSaveGear={handleSave}
                errors={propertyErrors}
            />
        </ContentPage>
    );
};
