import { Card, CardContent, Skeleton, styled, Typography } from '@mui/material';
import { CMSItem } from '@oma-kala-shared/core/model';
import { News, NewsSourceEnum } from '@oma-kala-shared/core/model/News';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { setNewsDetails } from 'app/state/news/newsSlice';
import { selectIsLoggedIn } from 'app/state/user/userSlice';
import lukeLogo from 'resources/images/icons/Luke_logo.png';
import omakalaLogo from 'resources/images/icons/omakala_logo.png';
import Badge, { badgeClasses } from '@mui/material/Badge';
import externalNewsLogo from 'resources/images/icons/new-tab-icon.png';
import { isExternalNews } from '@oma-kala-shared/core/logic';

/**
 * @param {News} param0
 * @return {JSX.Element}
 */
export function NewsItemPreview({ item, isLoading, isUnreadNews }: { item: News; isLoading: boolean; isUnreadNews?: boolean }) {
    const image = item.mediaUrl ?? (item.source === NewsSourceEnum.LUKE ? lukeLogo : isExternalNews(item) ? externalNewsLogo : omakalaLogo);
    const sourseNews = item.source === NewsSourceEnum.LUKE ? lukeLogo : isExternalNews(item) ? externalNewsLogo : omakalaLogo;
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const backgroundColor = isLoggedIn ? 'white' : '#2B3950';
    const titleColor = isLoggedIn ? 'black' : 'white';
    const dateColor = isLoggedIn ? '#0033A0' : '#94A3B8';
    const textColor = isLoggedIn ? 'black' : '#94A3B8';

    const dispatch = useDispatch();
    const url = isLoggedIn ? '/news' : '/';
    const newsUrl = `/news/${item.id}`;

    const handleClickNews = () => {
        if (item.source === NewsSourceEnum.LUKE) {
            item.link && window.open(item.link, '_blank', 'noreferrer');
        } else if (isExternalNews(item)) {
            item.link && window.open(item.excerpt!, '_blank', 'noreferrer');
        } else {
            dispatch(setNewsDetails(item));
        }
    };
    return (
        <RouterLink
            to={item.source === NewsSourceEnum.LUKE || isExternalNews(item) ? url : newsUrl}
            style={{ textDecoration: 'none', display: 'inline-block', width: '300px' }}
        >
            <Card
                style={{
                    height: 400,
                    width: 300,
                    border: !isLoggedIn ? 'none' : isUnreadNews ? '3px solid red' : '1px solid #0033A0',
                    position: 'relative',
                    paddingBottom: 10,
                    cursor: 'pointer',
                    backgroundColor: backgroundColor,
                }}
                onClick={handleClickNews}
            >
                {!isLoading ? (
                    <div
                        style={{
                            backgroundColor: '#EEEEEE',
                            height: '150px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={image}
                            alt=""
                            style={{
                                objectFit: !item.mediaUrl ? 'contain' : 'cover',
                                width: '100%',
                                height: item.mediaUrl ? '150px' : '80px',
                            }}
                        />
                    </div>
                ) : (
                    <Skeleton variant="rectangular" width={'100%'} height={150} />
                )}
                <CardContent>
                    {!isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: dateColor,
                                }}
                            >
                                {moment(item.publishDate).format('DD MMM YYYY')}
                                {isUnreadNews && (
                                    <Badge
                                        variant="dot"
                                        color="error"
                                        sx={{
                                            marginLeft: 2,
                                            [`& .${badgeClasses.dot}`]: {
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                            },
                                        }}
                                    />
                                )}
                            </Typography>
                            <img
                                id="abc"
                                src={sourseNews}
                                style={{
                                    height: 25,
                                    width: 25,
                                    display: item.mediaUrl ? '' : 'none',
                                }}
                            />
                        </div>
                    ) : (
                        <Skeleton variant="rectangular" height={27} width={100} />
                    )}

                    {!isLoading ? (
                        <>
                            <Typography variant="h6" sx={{ fontWeight: 700, color: titleColor }}>
                                {item.title}
                            </Typography>
                            <Typography
                                sx={{
                                    color: textColor,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    height: 50,
                                    WebkitLineClamp: 4,
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {item.excerpt}
                            </Typography>
                        </>
                    ) : (
                        <Skeleton variant="rectangular" height={150} width={'100%'} sx={{ margin: '10px 0' }} />
                    )}
                </CardContent>
            </Card>
        </RouterLink>
    );
}

const CMSMessageTitle = styled('span')({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
});

const CMSMessageExcerpt = styled('span')({
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineHeight: '24px',
    maxHeight: 96,
    marginTop: 8,
    '& p': {
        margin: 0,
    },
});

// Remove read more link from excerpt, if found
const getExcerpt = (item: CMSItem): string => {
    const regex = /(.*)(<a class="more-link".+<\/a>)(.+)/;
    if (!regex.test(item.excerpt.rendered)) {
        return item.excerpt.rendered;
    }
    const matches: RegExpExecArray | null = regex.exec(item.excerpt.rendered);
    return matches ? matches[1] + matches[3] : item.excerpt.rendered;
};
