import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uuidv4 } from 'app/logic/Common';
import { RequestState, RequestStatus } from 'app/model';
import { clearSyncState, selectTokenSyncState } from 'app/state/sync/syncSlice';

/**
 * Generic implementation of a hook for async state handling inside components.
 *
 * Example usage:
 *  const [syncToken, isLoading] = useSyncToken({
 *      onSuccess: (msg?: string) => {
 *          if (msg) {
 *              dispatch(setInfoMessage(msg));
 *          }
 *           onSubmit();
 *      },
 *      onError: (msg: string) => Alert.alert('', msg),
 *  });
 *
 * and dispatch an async operation using the token received:
 *
 * dispatch(doSomeAsyncOperations(syncToken));
 * @param {SyncTokenProps} props
 * @return {SyncTokenData} [syncToken, isLoading]
 */
export interface SyncTokenProps {
    onSuccess: (response?: any, message?: string) => any;
    onError: (message: string) => any;
}

export type SyncTokenData = [string, boolean];

/**
 * TODO
 * @param {SyncTokenProps} param0
 * @return {SyncTokenData} SyncTokenData
 */
export function useSyncToken({ onSuccess, onError }: SyncTokenProps): SyncTokenData {
    const [syncToken] = useState(uuidv4());
    const dispatch = useDispatch();

    const componentRequestState: RequestState | undefined = useSelector(selectTokenSyncState(syncToken));

    const isLoading = componentRequestState?.status === RequestStatus.Loading;

    useEffect(() => {
        return () => {
            dispatch(clearSyncState(syncToken));
        };
    }, []);

    useEffect(() => {
        if (!componentRequestState) {
            return;
        }

        switch (componentRequestState.status) {
            case RequestStatus.Successful:
                onSuccess(componentRequestState.resource, componentRequestState.message);
                break;
            case RequestStatus.Failed:
                onError(componentRequestState.message);
                break;
        }
    }, [componentRequestState]);

    return [syncToken, isLoading];
}
