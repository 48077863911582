import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const SkeletonLoader: React.FC<{}> = (props) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={400} width={'100%'} />
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={100} width={'100%'} style={{ marginBottom: 20 }} />
                <Skeleton animation="wave" variant="rectangular" height={100} width={'100%'} style={{ marginBottom: 20 }} />
                <Skeleton animation="wave" variant="rectangular" height={100} width={'100%'} style={{ marginBottom: 20 }} />
                <Skeleton animation="wave" variant="rectangular" height={50} width={'100%'} style={{ marginBottom: 20 }} />
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={400} width={'100%'} />
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={100} width={'100%'} style={{ marginBottom: 20 }} />
                <Skeleton animation="wave" variant="rectangular" height={100} width={'100%'} style={{ marginBottom: 20 }} />
                <Skeleton animation="wave" variant="rectangular" height={100} width={'100%'} style={{ marginBottom: 20 }} />
                <Skeleton animation="wave" variant="rectangular" height={50} width={'100%'} style={{ marginBottom: 20 }} />
            </Grid>
        </Grid>
    );
};

export default SkeletonLoader;
