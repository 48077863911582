import { cleanExcerptFromParagraphTags } from '@oma-kala-shared/core/logic';
import { CMSItem } from '@oma-kala-shared/core/model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadableResource, RequestStatus } from 'app/model';
import { RootState } from 'app/state/store';

export interface CMSState {
    links: LoadableResource<CMSItem[]>;
    elements: LoadableResource<CMSItem[]>;
    messages: LoadableResource<CMSItem[]>;
    pages: LoadableResource<CMSItem[]>;
    termsAndConditions: LoadableResource<CMSItem[]>;
}

export const initialState: CMSState = {
    links: { status: RequestStatus.NotInitiated },
    elements: { status: RequestStatus.NotInitiated },
    messages: { status: RequestStatus.NotInitiated },
    pages: { status: RequestStatus.NotInitiated },
    termsAndConditions: { status: RequestStatus.NotInitiated },
};

const cmsSlice = createSlice({
    name: 'cms',
    initialState: initialState,
    reducers: {
        setCmsLinks: (state: CMSState, action: PayloadAction<LoadableResource<CMSItem[]>>) => {
            state.links = action.payload;
        },
        setCmsElements: (state: CMSState, action: PayloadAction<LoadableResource<CMSItem[]>>) => {
            state.elements = action.payload;
        },
        setCmsMessages: (state: CMSState, action: PayloadAction<LoadableResource<CMSItem[]>>) => {
            state.messages = action.payload;
        },
        setCmsPages: (state: CMSState, action: PayloadAction<LoadableResource<CMSItem[]>>) => {
            state.pages = action.payload;
        },
        setCmsTermsAndConditions: (state: CMSState, action: PayloadAction<LoadableResource<CMSItem[]>>) => {
            state.termsAndConditions = action.payload;
        },
    },
});

export const { setCmsLinks, setCmsElements, setCmsMessages, setCmsPages, setCmsTermsAndConditions } = cmsSlice.actions;

export const selectCmsLinksResource = (state: RootState): LoadableResource<CMSItem[]> => state.cms.links;

export const selectCmsLinks = (state: RootState): CMSItem[] =>
    state.cms.links.status === RequestStatus.Successful ? state.cms.links.resource : [];

export const selectCmsElementsResource = (state: RootState): LoadableResource<CMSItem[]> => state.cms.elements;

export const selectCmsElements = (state: RootState): CMSItem[] =>
    state.cms.elements.status === RequestStatus.Successful ? state.cms.elements.resource : [];

export const selectCmsMessagesResource = (state: RootState): LoadableResource<CMSItem[]> => state.cms.messages;

export const selectCmsMessages = (state: RootState): CMSItem[] =>
    state.cms.messages.status === RequestStatus.Successful ? state.cms.messages.resource : [];

export const selectCmsTagsResource = (state: RootState): LoadableResource<CMSItem[]> => state.cms.pages;

export const selectCmsPages = (state: RootState): CMSItem[] =>
    state.cms.pages.status === RequestStatus.Successful ? state.cms.pages.resource : [];

export const selectCmsPagesStatus = (state: RootState): RequestStatus => state.cms.pages.status;

export const selectTermsAndConditionsStatus = (state: RootState): RequestStatus => state.cms.termsAndConditions.status;

export const selectTermsAndConditions = (state: RootState): CMSItem[] =>
    state.cms.termsAndConditions.status === RequestStatus.Successful ? state.cms.termsAndConditions.resource : [];

export const selectTermsAndConditionsVersion = (state: RootState): string | null =>
    state.cms.termsAndConditions.status === RequestStatus.Successful
        ? cleanExcerptFromParagraphTags(state.cms.termsAndConditions.resource[0].excerpt.rendered)
        : null;

export default cmsSlice.reducer;
