import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { CMSItem, OmaKalaLanguageCode } from '@oma-kala-shared/core/model';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ContentPage } from 'app/components/layout';
import { useSyncToken } from 'app/hooks';
import { OmaKalaUserRole, PushNotificationMessage, PushNotificationRequest, RedirectView } from 'app/model';
import { selectCmsMessages } from 'app/state/cms/cmsSlice';
import { setErrorMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { fetchCmsMessages, sendPushNotification } from 'app/state/thunks';

// eslint-disable-next-line no-unused-vars
type ExpandedMap = { [key in OmaKalaLanguageCode]?: boolean };
// eslint-disable-next-line no-unused-vars
type NotificationLanguageMap = { [key in OmaKalaLanguageCode]?: PushNotificationMessage };
// eslint-disable-next-line no-unused-vars
type ErrorMap = { [key in ErrorKey]?: any };

type ErrorKey = keyof Record<OmaKalaLanguageCode, string> | 'roles' | 'linkedView' | 'linkedViewId';

export const SendPushNotification = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [syncToken, isLoading] = useSyncToken({
        onSuccess: (msg?: string) => {
            if (msg) {
                dispatch(setSuccessMessage(msg));
            }
            navigate('/admin/notification/browse');
        },
        onError: (msg: string) => {
            dispatch(setErrorMessage(msg));
        },
    });

    const langs: OmaKalaLanguageCode[] = Object.values(OmaKalaLanguageCode);
    const allRoles: OmaKalaUserRole[] = Object.values(OmaKalaUserRole);

    const [errors, setErrors] = React.useState<ErrorMap>({});
    const [expanded, setExpanded] = React.useState<ExpandedMap>({ [OmaKalaLanguageCode.FI]: true });
    const [notificationMessages, setNotificationMessages] = React.useState<NotificationLanguageMap>({});
    const [redirectToView, setRedirectToView] = React.useState<boolean>(false);
    const [redirectView, setRedirectView] = React.useState<RedirectView | undefined>();
    const [redirectViewId, setRedirectViewId] = React.useState<string | undefined>();
    const [selectedRoles, setSelectedRoles] = React.useState<OmaKalaUserRole[]>([]);
    // TODO: filter linkable views depending on selected linkedView
    // when implementing multiple navigations
    const linkableViews: RedirectView[] = [RedirectView.NEWS];

    const cmsMessages: CMSItem[] = useSelector(selectCmsMessages);

    useEffect(() => {
        dispatch(fetchCmsMessages(i18n.language));
    }, [i18n.language]);

    const handleAccordionPress = (lang: OmaKalaLanguageCode) => () => setExpanded({ ...expanded, [lang]: !expanded[lang] });

    const handleMessageChange = (lang: OmaKalaLanguageCode) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const existingMessage: PushNotificationMessage | undefined = notificationMessages[lang];
        const updatedMessages: NotificationLanguageMap = {
            ...notificationMessages,
            [lang]: {
                title: existingMessage?.title ?? '',
                message: event.target.value,
                lang: lang,
            },
        };
        setNotificationMessages(updatedMessages);
    };

    const handleTitleChange = (lang: OmaKalaLanguageCode) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const existingMessage: PushNotificationMessage | undefined = notificationMessages[lang];
        const updatedMessages: NotificationLanguageMap = {
            ...notificationMessages,
            [lang]: {
                title: event.target.value,
                message: existingMessage?.message,
                lang: lang,
            },
        };
        setNotificationMessages(updatedMessages);
    };

    const handleRedirectToViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRedirectToView(event.target.checked);
    };

    const handleRedirectViewChange = (event: SelectChangeEvent) => {
        setRedirectView(event.target.value as RedirectView);
    };

    const handleRedirectViewIdChange = (event: SelectChangeEvent) => {
        setRedirectViewId(event.target.value);
    };

    const handleSelectRole = (event: SelectChangeEvent<string[]>) => {
        const updatedRoles: string | string[] = event.target.value;
        setSelectedRoles(
            typeof updatedRoles === 'string' ? (updatedRoles.split(',') as OmaKalaUserRole[]) : (updatedRoles as OmaKalaUserRole[])
        );
    };

    const handleSubmit = () => {
        // eslint-disable-next-line no-unused-vars
        const errors: { [key in ErrorKey]?: any } = {};
        for (const l of langs) {
            const existingNotification: PushNotificationMessage | undefined = notificationMessages[l];
            if (!existingNotification || existingNotification.message.length === 0 || existingNotification.title.length === 0) {
                errors[l] = true;
            }
        }

        if (redirectToView) {
            // if linkToView is selected, make sure the user has chosen both
            // a view to link to and a specific ID in that view
            if (!redirectView) {
                errors['linkedView'] = true;
            } else if (!redirectViewId) {
                errors['linkedViewId'] = true;
            }
        }

        if (selectedRoles.length === 0) {
            errors['roles'] = true;
        }

        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        const pushNotificationMessages: PushNotificationMessage[] = Object.values(notificationMessages);
        const request: PushNotificationRequest = {
            messages: pushNotificationMessages,
            roles: selectedRoles,
            redirectView: redirectToView && redirectView ? redirectView : null,
            redirectId: redirectToView && redirectViewId ? redirectViewId : null,
        };
        dispatch(sendPushNotification(syncToken, request));
    };

    const handleCancel = () => navigate('/');

    return (
        <ContentPage title={t('admin.notifications.send.title')} description={t('admin.notifications.send.description')}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        {langs.map((lang: OmaKalaLanguageCode) => {
                            const existingNotification: PushNotificationMessage | undefined = notificationMessages[lang];
                            const titleIsSet: boolean = !!existingNotification?.title && existingNotification.title.length > 0;
                            const messageIsSet: boolean = !!existingNotification?.message && existingNotification.message.length > 0;
                            const hasError: boolean = errors[lang] !== undefined && (!titleIsSet || !messageIsSet);
                            return (
                                <Grid item key={lang}>
                                    <Accordion expanded={expanded[lang] ?? false} onChange={handleAccordionPress(lang)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`accordion-content-${lang}`}
                                            id={`accordion-header-${lang}`}
                                        >
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: hasError ? theme.palette.error.main : undefined }}
                                                    >
                                                        {t(`common.languages.${lang}`)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {t('admin.notifications.send.helperText', { lang: lang })}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <FormControl fullWidth variant="standard">
                                                <TextField
                                                    label={t('admin.notifications.send.input.titleLabel')}
                                                    id="notification-title"
                                                    variant="filled"
                                                    sx={{ mb: 1 }}
                                                    value={existingNotification?.title ?? ''}
                                                    required
                                                    error={hasError && !titleIsSet}
                                                    onChange={handleTitleChange(lang)}
                                                />
                                                <TextField
                                                    label={t('admin.notifications.send.input.messageLabel')}
                                                    id="notification-message"
                                                    variant="filled"
                                                    multiline
                                                    rows={4}
                                                    value={existingNotification?.message ?? ''}
                                                    required
                                                    error={hasError && !messageIsSet}
                                                    onChange={handleMessageChange(lang)}
                                                />
                                            </FormControl>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="role-groups-label-id">{t('admin.notifications.send.input.roleGroupLabel')}</InputLabel>
                        <Select
                            labelId="role-groups-label-id"
                            id="role-groups-select-id"
                            multiple
                            required
                            // we have to do this typecast since the typescript type
                            // doesn't recognize that we need to use an string- array for multi-select
                            value={(selectedRoles as any) ?? ''}
                            label={'' + t('admin.notifications.send.input.roleGroupLabel')}
                            onChange={handleSelectRole}
                            error={errors['roles'] !== undefined && selectedRoles.length === 0}
                        >
                            {allRoles.map((role: OmaKalaUserRole) => {
                                return (
                                    <MenuItem key={role} value={role}>
                                        {t(`common.roles.${role}`)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>
                            NOTE: Roles are currently hardcoded in the backend, modifying this will not change the messages
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Switch defaultChecked={false} onChange={handleRedirectToViewChange} />}
                        label={'' + t('admin.notifications.send.input.redirectToViewLabel')}
                    />
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="linked-view" disabled={!redirectToView}>
                            {t('admin.notifications.send.input.redirectViewLabel')}
                        </InputLabel>
                        <Select
                            labelId="linked-view"
                            id="linked-view-select"
                            value={redirectView ?? ''}
                            label={t('admin.notifications.send.input.redirectViewLabel')}
                            onChange={handleRedirectViewChange}
                            disabled={!redirectToView}
                            error={!!errors['linkedView'] && !redirectView}
                        >
                            {linkableViews.map((view: RedirectView) => {
                                return (
                                    <MenuItem key={view} value={view}>
                                        {t(`admin.notifications.send.redirectViews.${view}`)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="linked-view-id" disabled={!redirectToView || !redirectView}>
                            {t('admin.notifications.send.input.redirectViewIdLabel')}
                        </InputLabel>
                        <Select
                            labelId="linked-view-id"
                            id="linked-view-id-select"
                            value={redirectViewId ?? ''}
                            label={t('admin.notifications.send.input.redirectViewIdLabel')}
                            onChange={handleRedirectViewIdChange}
                            disabled={!redirectToView || !redirectView}
                            error={!!errors['linkedViewId'] && !redirectViewId}
                        >
                            {cmsMessages.map((msg: CMSItem) => {
                                return (
                                    <MenuItem key={msg.id} value={msg.id.toString()}>
                                        {msg.id + ' ' + msg.title.rendered}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item hidden={Object.keys(errors).length === 0}>
                    <Typography variant="body1" style={{ color: theme.palette.error.main }}>
                        {t('admin.notifications.send.requiredValuesMissing')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" disabled={isLoading} onClick={handleSubmit}>
                                {t('admin.notifications.send.buttons.submit')}
                                {isLoading && <CircularProgress color="secondary" size={16} sx={{ ml: 1 }} />}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" disabled={isLoading} onClick={handleCancel}>
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentPage>
    );
};
