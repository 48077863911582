import { TokenData, UserData } from '@oma-kala-shared/core/model';

import { clearToken, storeToken } from 'app/logic/LoginService';
import {
    clearUserData,
    clearUserRelatedSettingsForOtherUsers,
    clearUserRoles,
    getUserData,
    getUserRoles,
    storeLatestLoggedInUser,
} from 'app/logic/UserSettingService';
import { UserRole } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';
import { resetUserState, setTokenData, setUserData, setUserRoles } from 'app/state/user/userSlice';

import { fetchUserData } from './fetchUserData';
import { fetchUserRoles } from './fetchUserRoles';

/**
 * Initializes the redux store with any cached values from local storage.
 * Should only be called once on initial app load or when switching users
 * @param {TokenData} initialToken The token to initialize store with
 * @return {AppThunk} Dispatchable thunk
 */
export const initializeStore =
    (initialToken: TokenData | null): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('initializing store');
        const tokenData: TokenData | null = initialToken;
        dispatch(setTokenData(tokenData));
        if (tokenData !== null) {
            const oid = tokenData.body.oid;
            if (!oid) return;
            clearUserRelatedSettingsForOtherUsers(oid);
            storeToken(tokenData);
            storeLatestLoggedInUser(oid);
        } else {
            clearToken();
            clearUserData();
            clearUserRoles();
            dispatch(resetUserState(null));
        }

        // Need to get fresh data from backend
        dispatch(fetchUserData());

        const userRoles: UserRole[] | null = getUserRoles();
        dispatch(setUserRoles(userRoles));

        if (userRoles === null) {
            dispatch(fetchUserRoles());
        }

        console.debug('finished initializing store');
    };
