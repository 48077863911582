import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { OmaKalaUserRole, UserRole } from 'app/model';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

interface ProtectedRouteProps {
    allowedRoles: OmaKalaUserRole[] | null;
    userRoles: UserRole[] | null;
    isHiddenForLoggedIn?: boolean;
    children: JSX.Element;
}

export const ProtectedRoute = ({ allowedRoles = null, userRoles, isHiddenForLoggedIn = false, children }: ProtectedRouteProps) => {
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);

    if ((!allowedRoles || allowedRoles.length < 1) && !(isHiddenForLoggedIn && isLoggedIn)) {
        return children;
    }
    const isSuitableRole = userRoles?.filter(role => allowedRoles?.includes(role.code)).length ?? 0 > 0;

    if (!isSuitableRole || (isHiddenForLoggedIn && isLoggedIn)) {
        return <Navigate to="/home" replace />;
    }
    return children;
};
