import WebService from '@oma-kala-shared/core/logic/WebService';
import { CMSCategory, CMSItem, CMSRequest, ContentResponse, GetCmsPostsRequest } from '@oma-kala-shared/core/model';

import { RequestStatus } from 'app/model';
import { setCmsElements } from 'app/state/cms/cmsSlice';
import { AppThunk, RootState } from 'app/state/store';

import { createWebService } from './thunkHelpers';

/**
 * Fetch elements from our CMS for the given language and load them into the application state.
 * @param {string} language
 * @return {AppThunk}
 */
export const fetchCmsElements =
    (language: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        const categories = [
            CMSCategory.ELEMENTTI_FI.toString(),
            CMSCategory.ELEMENTTI_EN.toString(),
            CMSCategory.ELEMENTTI_SV.toString(),
            CMSCategory.ELEMENTTI_SE.toString(),
        ].join();
        const request: GetCmsPostsRequest = {
            fields: 'id,title,content,tags,categories',
            categories: categories,
            itemPerPage: 99,
        };
        const service: WebService = await createWebService(dispatch, getState);
        const response: ContentResponse<CMSItem[]> = await service.fetchCmsPosts(request);

        dispatch(setCmsElements({ status: RequestStatus.Loading }));

        if (response.successful) {
            dispatch(setCmsElements({ status: RequestStatus.Successful, resource: response.content }));
        } else {
            dispatch(setCmsElements({ status: RequestStatus.Failed, message: response.message as string }));
        }
    };
