import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { PASSWORD_MIN_LENGTH } from '@oma-kala-shared/core/logic';
import WebService from '@oma-kala-shared/core/logic/WebService';
import { UserData, ContentResponse, ResetPasswordRequest, ResetPasswordResponse } from '@oma-kala-shared/core/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PasswordValidationDisplay from 'app/components/common/PasswordValidationDisplay';
import { ContentPage } from 'app/components/layout';
import BrowserWebService from 'app/logic/BrowserWebService';
import { ExtendedPasswordValidationResult } from 'app/model/ExtendedPasswordValidationResult';
import { setErrorMessage, setInfoMessage, setSuccessMessage } from 'app/state/message/messageSlice';
import { createWebService } from 'app/state/thunks';
import { RootState } from 'app/state/store';

export const ResetPasswordPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useStore<RootState>();
    const { t } = useTranslation();
    const [identificationId, setIdentificaitonId] = useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState<ExtendedPasswordValidationResult>({
        isValid: false,
        length: false,
        lowercase: false,
        uppercase: false,
        digit: false,
        special: false,
        passwordConfirmation: false,
    });

    const handlePasswordValidation = (password: string, passwordConfirmation: string) => {
        const isLengthValid = password.length >= PASSWORD_MIN_LENGTH;
        const includesLowercase = password.toUpperCase() !== password;
        const includesUppercase = password.toLowerCase() !== password;
        const includesInteger = /\d/.test(password);
        const includesSpecialCharacter = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password);
        const isPasswordConfirmationValid = password === passwordConfirmation && password.length > 0;
        const validationResult: ExtendedPasswordValidationResult = {
            isValid:
                isLengthValid &&
                includesLowercase &&
                includesUppercase &&
                includesInteger &&
                includesSpecialCharacter &&
                isPasswordConfirmationValid,
            length: isLengthValid,
            lowercase: includesLowercase,
            uppercase: includesUppercase,
            digit: includesInteger,
            special: includesSpecialCharacter,
            passwordConfirmation: isPasswordConfirmationValid,
        };
        return validationResult;
    };

    const fetchUserDataByIdentificationId = async (identificationId: string) => {
        const webService = await createWebService(dispatch, store.getState);
        const resp: ContentResponse<UserData> = await webService.getUserByIdentification(identificationId);
        return resp;
    };

    const submitNewPassword = async () => {
        if (!identificationId) {
            dispatch(setErrorMessage(t('passwordProperties.messages.identificationUnsuccessful')));
            return;
        }
        setIsSendingRequest(true);
        const webService: WebService = new BrowserWebService();
        const request: ResetPasswordRequest = { password: password };
        const response: ContentResponse<ResetPasswordResponse> = await webService.changePasswordWithIdentification(
            identificationId,
            request
        );
        setIsSendingRequest(false);
        if (response.successful) {
            dispatch(setSuccessMessage(t('passwordProperties.messages.passwordChanged')));
            navigate('/auth');
        } else {
            dispatch(setErrorMessage(t('passwordProperties.messages.passwordNotChanged')));
            navigate('/auth');
        }
    };

    useEffect(() => {
        const fetchAndVerifyUserData = async (identificationId: string) => {
            const userData = await fetchUserDataByIdentificationId(identificationId);
            if (!userData || !userData?.successful) {
                dispatch(setInfoMessage(t('passwordProperties.messages.noUserFound')));
                navigate('/auth');
                return;
            }
            setIdentificaitonId(identificationId);
        };

        const identificationIdFromUrl = searchParams.get('identificationId');
        if (!identificationIdFromUrl) {
            dispatch(setErrorMessage(t('passwordProperties.messages.identificationUnsuccessful')));
            navigate('/auth');
            return;
        }
        fetchAndVerifyUserData(identificationIdFromUrl);
    }, []);

    useEffect(() => {
        const passwordValidationResult = handlePasswordValidation(password, passwordConfirmation);
        setPasswordValidation(passwordValidationResult);
    }, [password, passwordConfirmation]);

    return (
        <Box sx={{ height: '90vh' }}>
            <Card
                variant="outlined"
                title="Reset Password"
                style={{
                    minWidth: 400,
                    maxWidth: 700,
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 15,
                }}
            >
                <CardContent>
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <Box>
                            <TextField
                                variant="outlined"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                label={'New Password'}
                                size="small"
                                type={isPasswordVisible ? 'text' : 'password'}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                            >
                                                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box>
                            <TextField
                                variant="outlined"
                                value={passwordConfirmation}
                                onChange={e => setPasswordConfirmation(e.target.value)}
                                label={'Repeat New Password'}
                                type={isPasswordConfirmationVisible ? 'text' : 'password'}
                                size={'small'}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password confirmation visibility"
                                                onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                                            >
                                                {isPasswordConfirmationVisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box>
                            <PasswordValidationDisplay passwordValidation={passwordValidation} />
                        </Box>
                        <Button
                            variant="contained"
                            disabled={!passwordValidation.isValid || isSendingRequest}
                            onClick={() => {
                                submitNewPassword();
                            }}
                        >
                            {isSendingRequest && <CircularProgress color="inherit" size={16} style={{ marginRight: '0.5rem' }} />}
                            {t('common.save')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};
