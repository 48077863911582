import React, { useEffect, useState } from 'react';

import { Box, CardMedia, Grid, styled, Typography } from '@mui/material';
import { CMSItem, CMSTag, LocalizedTags } from '@oma-kala-shared/core/model';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { filterCmsItemsByTag, getDefaultTagsets, getLocalizedLinkTag } from 'app/logic/CmsService';
import { getStoreQrCodeImage, StoreVariantEnum } from 'app/logic/ImageService';
import { useStoreButtonImage } from 'app/hooks/useStoreButtonImage';
import { selectCmsElements } from 'app/state/cms/cmsSlice';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

import { LazyBackground } from './LazyBackground';

const HomeWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    marginBottom: '-50px',
});

const MainWrapper = styled('div')({
    maxWidth: '900px',
    marginTop: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    '@media only screen and (min-width: 900px)': {
        textAlign: 'left',
        maxWidth: '1200px',
        justifyItems: 'center',
    },
});

const SplashMessage = styled('div')({
    color: 'white',
    margin: '12px',
    marginBottom: '40px',
    fontSize: '24px',
    '@media only screen and (min-width: 768px)': {
        marginBottom: '30px',
    },
});

/**
 * @return {JSX.Element}
 */
export function HomePage() {
    const { t, i18n } = useTranslation();
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const [appStoreButtonImage, playStoreButtonImage] = useStoreButtonImage();

    // Dynamic content from CMS to home page
    const elements: CMSItem[] = useSelector(selectCmsElements);
    const tagset: LocalizedTags = getDefaultTagsets();
    const cmsItem: CMSItem | undefined = first(filterCmsItemsByTag(elements, getLocalizedLinkTag(i18n.language, tagset)));

    return !isLoggedIn ? (
        <HomeWrapper>
            <LazyBackground>
                <MainWrapper>
                    <Grid container justifyContent="center">
                        <Grid item md={6} lg={8}>
                            <SplashMessage>
                                {cmsItem && (
                                    <>
                                        <Typography sx={{ marginBottom: 2 }} lineHeight={{ xs: 1 }} fontSize={{ lg: 72, xs: 42 }}>
                                            <span dangerouslySetInnerHTML={{ __html: cmsItem.title.rendered }}></span>
                                        </Typography>
                                        <Typography sx={{ marginBottom: 6 }} fontSize={{ lg: 22, sm: 22 }}>
                                            <span dangerouslySetInnerHTML={{ __html: cmsItem.content.rendered }}></span>
                                        </Typography>
                                    </>
                                )}
                                <Grid container>
                                    {/* Google play link */}
                                    <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                        <a href="https://play.google.com/store/apps/details?id=fi.luke.omakala">
                                            <img
                                                style={{ height: '60px' }}
                                                src={playStoreButtonImage}
                                                alt={t('common.mobile.playStore.linkAltText')}
                                            />
                                        </a>
                                        <Box sx={{ m: 5 }}>
                                            <CardMedia
                                                component="img"
                                                image={getStoreQrCodeImage(StoreVariantEnum.PLAY_STORE)}
                                                alt={t('common.mobile.playStore.qrCodeAltText')}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                        <a href="https://apps.apple.com/app/omakala/id1587329867">
                                            <img
                                                style={{ height: '60px' }}
                                                src={appStoreButtonImage}
                                                alt={t('common.mobile.appStore.linkAltText')}
                                            />
                                        </a>
                                        <Box sx={{ m: 5 }}>
                                            <CardMedia
                                                component="img"
                                                image={getStoreQrCodeImage(StoreVariantEnum.APP_STORE)}
                                                alt={t('common.mobile.appStore.linkAltText')}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </SplashMessage>
                        </Grid>
                    </Grid>
                </MainWrapper>
            </LazyBackground>
        </HomeWrapper>
    ) : (
        <></>
    );
}
