import WebService from '@oma-kala-shared/core/logic/WebService';
import { SimpleResponse } from '@oma-kala-shared/core/model';
import i18n from 'i18next';
import { RequestStatus } from 'app/model';
import { setErrorMessage } from 'app/state/message/messageSlice';
import { setSuccessMessage } from '../message/messageSlice';
import { AppThunk, RootState } from '../store';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';

/**
 * Delete the specified declaration
 * @param {string} syncToken
 * @param {string} declarationId
 * @param {string} reason
 * @return {Promise<ContentResponse<boolean>>}
 */
export const deleteDeclaration =
    (syncToken: string, declarationId: string, reason: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('deleting declaration', declarationId);

        dispatch(setSyncState({ syncToken, value: { status: RequestStatus.Loading } }));

        const service: WebService = await createWebService(dispatch, getState);
        const response: SimpleResponse = await service.deleteDeclaration(declarationId, { deletionReason: reason });

        if (response.successful) {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Successful,
                        message: i18n.t('catchDeclaration.declarationDeleted'),
                    },
                })
            );
            dispatch(setSuccessMessage(i18n.t('catchDeclaration.declarationDeleted')));
        } else {
            console.debug('delete declaration failed with message:' + response.message);
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: i18n.t('catchDeclaration.requestFailed'),
                    },
                })
            );
            dispatch(setErrorMessage(i18n.t('catchDeclaration.requestFailed')));
        }
    };
