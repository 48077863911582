/**
 * This component will show only the consent can be shown in web
 * so we need to handle in difference way.
 *
 * We need to make extra request to get only user consent in web
 * and we should compare it
 */

import { Chip, Divider, IconButton, List, ListItem, ListItemText, Popover, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getLanguageDateFormat } from '@oma-kala-shared/core/logic';
import { SvkUserMembership } from '@oma-kala-shared/core/model';
import useAuthData from 'app/hooks/useAuthData';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { appTheme } from 'app/theme';
const SvkInfo = () => {
    const { userData } = useAuthData();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (userData?.svkUserProperties === null) {
        return <></>;
    }

    const getValidityText = (membership: SvkUserMembership) => {
        let text = '';
        if (membership.endDate !== '') {
            text = text.concat(
                t(`valid until ${membership.endDate}`, { time: moment(membership.endDate).format(getLanguageDateFormat(i18n.language)) })
            );
        } else {
            text = text.concat(t('profile.profileSections.svkInfo.membership.expiredDateInAvailable'));
        }

        return text;
    };

    return (
        <>
            <List
                style={{ border: '1px solid #0033A0', borderRadius: '12px' }}
                subheader={
                    <>
                        <Typography
                            style={{
                                borderBottom: '1px solid #0033A0',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '8px',
                                backgroundColor: appTheme.palette.primary.main,
                                color: 'white',
                                display: 'flex',
                                rowGap: '4px',
                            }}
                        >
                            <span>
                                {t('profile.profileSections.svkInfo.membership.title', {
                                    time: moment(userData!.lastLogin).format(getLanguageDateFormat(i18n.language)),
                                })}
                            </span>
                            <IconButton aria-label="hint" onClick={handleClick} style={{ padding: 0, marginLeft: '4px', color: 'white' }}>
                                <InfoIcon />
                            </IconButton>
                        </Typography>
                    </>
                }
            >
                {userData!.svkUserProperties!.memberships.map((membership: SvkUserMembership, index: number) => {
                    return (
                        <>
                            <ListItem key={index}>
                                <ListItemText
                                    primary={
                                        <Stack spacing={2}>
                                            {membership.title}
                                            <Box>
                                                <Chip label={getValidityText(membership)} />
                                            </Box>
                                            {index > 0 && <Divider component="li" />}
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        </>
                    );
                })}
            </List>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: { style: { maxWidth: '400px', padding: '10px', border: '1px solid #0033A0', borderRadius: '12px' } },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {t('profile.profileSections.svkInfo.membership.dataUpdateHint')}
            </Popover>
        </>
    );
};

export default SvkInfo;
