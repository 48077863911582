import { ContentResponse, DeclarationRequirementDetails } from '@oma-kala-shared/core/model';

import BrowserWebService from 'app/logic/BrowserWebService';
import { RequestStatus } from 'app/model';
import { AppThunk, RootState } from 'app/state/store';

import { setDeclarationRequirements } from '../declaration/declarationSlice';
import { setSyncState } from '../sync/syncSlice';
import { createWebService } from './thunkHelpers';

/**
 * Fetches declaration requirements from the backend service.
 *
 * @param {string} syncToken
 * @return {AppThunk}
 */
export const fetchDeclarationRequirements =
    (syncToken: string): AppThunk =>
    async (dispatch: any, getState: () => RootState) => {
        console.debug('fetching declaration requirements');

        dispatch(
            setSyncState({
                syncToken,
                value: {
                    status: RequestStatus.Loading,
                },
            })
        );

        const webService: BrowserWebService = await createWebService(dispatch, getState);
        const response: ContentResponse<DeclarationRequirementDetails[]> = await webService.fetchDeclarationRequirements();

        if (response.successful) {
            // add a small artifical delay to avoid a jittery UI
            setTimeout(() => {
                dispatch(
                    setSyncState({
                        syncToken,
                        value: {
                            status: RequestStatus.Successful,
                        },
                    })
                );
                dispatch(setDeclarationRequirements(response.content));
            }, 1000);
        } else {
            dispatch(
                setSyncState({
                    syncToken,
                    value: {
                        status: RequestStatus.Failed,
                        message: response.message as string,
                    },
                })
            );
        }
        console.debug('finished fetching push notifications');
    };
