import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { OMAKALA_LANGUAGE_ORDER } from 'app/model';
import { changeLanguage } from 'app/logic/LanguageService';

type LanguageMenuProps = Pick<React.ComponentProps<typeof Menu>, 'anchorEl' | 'onClose'>;

export const LanguageMenu = ({ anchorEl, onClose }: LanguageMenuProps) => {
    const isMenuOpen = Boolean(anchorEl);
    const { i18n } = useTranslation();

    const setLanguage = (code: string) => changeLanguage(i18n, code.toLowerCase());

    const renderMenuItem = () => {
        return OMAKALA_LANGUAGE_ORDER.map(languageCode => {
            return (
                <MenuItem key={languageCode} style={{ width: 100 }} onClick={() => setLanguage(languageCode.toUpperCase())}>
                    {languageCode.toUpperCase()}
                </MenuItem>
            );
        });
    };

    return (
        <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
            }}
        >
            {renderMenuItem()}
        </Menu>
    );
};
