import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef, ForwardedRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentPage } from 'app/components/layout';
import { DeclarationDetails, DeclarationSearchRequest, DeclarationSearchResult } from '@oma-kala-shared/core/model';
import { MessageSnackbar } from 'app/components/display';
import { PageType, PageTypeEnum } from '../DeclarationListPage';
import DeclarationList, { DeclarationListItemRenderer } from 'app/components/declaration/display/DeclarationList';
import { selectUserIsAdmin, selectUserIsEditor } from 'app/state/user/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'app/model/Route';
import DeclarationSearchModal, {
    FieldLabelTranslateKey,
    getSearchInfoFromSearchParams,
    SearchInfo,
} from 'app/components/declaration/modal/DeclarationSearchModal';
import { LukeDeleteDeclarationNoticeModal } from 'app/components/declaration/modal/LukeDeleteDeclarationNoticeModal';
import { SaveToPrivateCatchLogConsent } from 'app/components/declaration/modal/SaveToPrivateCatchLogConsent';

/**
 * Create a default update request for the given user data
 * @param {UserData | null} userData
 * @return {UpdateUserRequest}
 */

type DeclarationListPageListContentProps = {
    pageType: PageType;
    onEdit: (catchDeclaration: DeclarationDetails) => void;
    loading: boolean;
    onSearchDialogClosed: (searchParams: DeclarationSearchRequest) => void;
    onFetchMoreResults: () => void;
    canLoadMore: boolean;
    searchResults: DeclarationSearchResult | null;
    searchParams: DeclarationSearchRequest | null;
    itemRenderer: DeclarationListItemRenderer;
};

export type DeclarationListPageListContentRef = {
    showDeleteNoticeModal: () => void;
};

const DeclarationListPageListContentComponent = (
    {
        pageType,
        loading,
        canLoadMore,
        onFetchMoreResults,
        onSearchDialogClosed,
        searchResults,
        itemRenderer,
        searchParams,
    }: DeclarationListPageListContentProps,
    ref: ForwardedRef<DeclarationListPageListContentRef>
) => {
    const { t } = useTranslation();
    const isAdminUser: boolean = useSelector(selectUserIsAdmin);
    const isEditorUser: boolean = useSelector(selectUserIsEditor);
    const [isSearchModalOpen, setIsSearchModalOpen] = React.useState(false);
    const showSearchModal = () => setIsSearchModalOpen(true);
    const closeSearchModal = () => setIsSearchModalOpen(false);

    const [isDeleteNoticeModalOpen, setIsDeleteNoticeModalOpen] = useState<boolean>(false);
    const showDeleteNoticeModal = () => setIsDeleteNoticeModalOpen(true);
    const closeLukeDeleteNoticeModal = () => setIsDeleteNoticeModalOpen(false);

    useImperativeHandle(ref, () => ({
        showDeleteNoticeModal: showDeleteNoticeModal,
    }));

    const fetchMoreResults = () => {
        onFetchMoreResults();
    };

    const handleSearchDialogClose = (searchParams: DeclarationSearchRequest | null) => {
        closeSearchModal();
        if (!searchParams) return;
        onSearchDialogClosed(searchParams);
    };

    const navigate = useNavigate();
    const navigateToCreatePage = () => navigate(`${RoutePath.CATCH_DECLARATION}/create/${pageType}`);

    const shouldRenderConsentModal = () => {
        if (pageType !== PageTypeEnum.PERSONAL) {
            return false;
        }

        return true;
    };

    const searchInfo = searchParams ? getSearchInfoFromSearchParams(searchParams) : null;

    return (
        <ContentPage
            width="55%"
            title={t(`catchDeclaration.list.title.${pageType}`)}
            description={t('catchDeclaration.primaryInfo.description')}
            extraHeader={
                <Box pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" color="primary" onClick={navigateToCreatePage} sx={{ mr: 1 }}>
                        <Typography sx={{ fontWeight: 700 }}>
                            {t('catchDeclaration.addNew')}
                            <AddIcon fontSize={'small'} />
                        </Typography>
                    </Button>

                    {(isAdminUser || isEditorUser) && (
                        <Button variant="outlined" color="primary" onClick={showSearchModal}>
                            <Typography sx={{ fontWeight: 700 }}>{t('common.search')}</Typography>
                        </Button>
                    )}
                </Box>
            }
        >
            {searchInfo && <SearchInfoBox searchInfo={searchInfo} />}
            <DeclarationList itemRenderer={itemRenderer} searchResults={searchResults} />
            {(searchResults?.declarations?.length ?? -1) > 0 && !loading && canLoadMore && (
                <Box textAlign={'center'}>
                    <Button variant="outlined" onClick={fetchMoreResults} sx={{ textAlign: 'center' }}>
                        {t('catchDeclaration.fetchMoreRows')}
                    </Button>
                </Box>
            )}
            {loading && <CircularProgress color="primary" />}
            {/* TODO: We face problems with the search modal that after close the value is not keeped when we unmount. If we don unmount the value of group is wrong because does not refresh */}
            {isSearchModalOpen && searchParams && (
                <DeclarationSearchModal
                    open={isSearchModalOpen}
                    onClose={handleSearchDialogClose}
                    searchInfo={getSearchInfoFromSearchParams(searchParams)}
                />
            )}
            {isDeleteNoticeModalOpen && isAdminUser && <LukeDeleteDeclarationNoticeModal onClose={closeLukeDeleteNoticeModal} />}
            {shouldRenderConsentModal() && <SaveToPrivateCatchLogConsent />}
            <MessageSnackbar />
        </ContentPage>
    );
};

const SearchInfoBox = ({ searchInfo }: { searchInfo: SearchInfo }) => {
    const { t } = useTranslation();

    const isEmpty = Object.values(searchInfo).every((value: string | null) => value === null || value === '');

    return (
        <>
            <Typography sx={{ fontSize: '22px' }}>{t('catchDeclaration.list.searchCondition')}</Typography>
            <Box sx={{ marginBottom: '1rem' }}>
                {isEmpty ? (
                    <Typography sx={{ fontWeight: 600, marginLeft: '1rem' }}>{t('catchDeclaration.list.showAll')}</Typography>
                ) : (
                    Object.entries(searchInfo).map(([key, value]) => {
                        return value !== null && value !== '' ? (
                            <Box key={key} sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 600, marginLeft: '1rem' }}>{`${
                                    t(FieldLabelTranslateKey[key]) ?? key
                                }:`}</Typography>
                                <Typography>{`${value}`}</Typography>
                            </Box>
                        ) : (
                            <></>
                        );
                    })
                )}
            </Box>
        </>
    );
};

const DeclarationListPageListContent = React.forwardRef<DeclarationListPageListContentRef, DeclarationListPageListContentProps>(
    DeclarationListPageListContentComponent
);
DeclarationListPageListContent.displayName = 'DeclarationListPageListContent';

export default DeclarationListPageListContent;
