import WebService from '@oma-kala-shared/core/logic/WebService';
import { ContentResponse, FailedResponse, OmaKalaEndpointType } from '@oma-kala-shared/core/model';

import { PushNotificationRequest, SimpleResponse, UserRole } from 'app/model';
import { PushNotification } from 'app/model/Notification';

/**
 * Browser WebService implementation
 */
export class BrowserWebService extends WebService {
    private baseUrl: string | undefined = process.env.REACT_APP_BASE_URL;

    /**
     * Creates a new WebService inteded for use in mobile clients
     * @param {string} token The token to use in requests
     * @param {dispatch} dispatch Dispatch function to redux state
     */
    constructor(token: string | null = null) {
        super(token);
    }

    /**
     * Fetches all existing push notifications from the backend.
     * @return {Promise<ContentResponse<PushNotification[]>>}
     */
    public async fetchPushNotifications(): Promise<ContentResponse<PushNotification[]> | FailedResponse> {
        try {
            const response = await this.sendGet<PushNotification[]>(OmaKalaEndpointType.Push);
            return { successful: true, content: response.data };
        } catch (e) {
            console.error('Fetching push notifications threw an error', e);
            return this.createFailedResponse(e);
        }
    }

    /**
     * Sends the specified push- notifications.
     * @param {any} request
     * @return {Promise<SimpleResponse>}
     */
    public async sendPushNotification(request: PushNotificationRequest): Promise<SimpleResponse | FailedResponse> {
        try {
            // TODO: Monitor response somehow, to observe push- notification state?
            await this.sendPost(OmaKalaEndpointType.Push, request);
            return { successful: true };
        } catch (e) {
            console.error('Sending push- notification threw an exception', e);
            return this.createFailedResponse(e);
        }
    }

    /**
     * Gets data for the specified user
     * @param {string} id The user ID to fetch data for
     * @return {Promise<ContentResponse<UserRole[]> | FailedResponse>} Data found for the user
     */
    public async getUserRoles(id: string): Promise<ContentResponse<UserRole[]> | FailedResponse> {
        try {
            const resp = await this.sendGet<UserRole[]>(OmaKalaEndpointType.User, `${id}/roles`);
            return { successful: true, content: resp.data };
        } catch (e) {
            return this.onFailedResponse(e);
        }
    }

    /**
     * Returns the full endpoint URL for the specified endpoint.
     *
     * If the specified endpoint is not handled, this will throw an error.
     * @param {OmaKalaEndpointType} endpoint The endpoint type
     * @return {string} The endpoint URL
     */
    protected getEndpointUrl(endpoint: OmaKalaEndpointType): string {
        if (!this.baseUrl) {
            throw new Error('baseUrl missing');
        }

        let endpointUrl: string;
        switch (endpoint) {
            case OmaKalaEndpointType.Identification:
                endpointUrl = '/v1/identification';
                break;
            case OmaKalaEndpointType.User:
                endpointUrl = '/v2/user';
                break;
            case OmaKalaEndpointType.Registration:
                endpointUrl = '/v2/registration';
                break;
            case OmaKalaEndpointType.Poi:
                endpointUrl = '/v1/poi';
                break;
            case OmaKalaEndpointType.Push:
                endpointUrl = '/v1/push';
                break;
            case OmaKalaEndpointType.Activity:
                endpointUrl = '/v1/activity';
                break;
            case OmaKalaEndpointType.Gear:
                endpointUrl = '/v1/gears';
                break;
            case OmaKalaEndpointType.Catch:
                endpointUrl = '/v1/catch';
                break;
            case OmaKalaEndpointType.Messaging:
                endpointUrl = '/v1/messaging';
                break;
            case OmaKalaEndpointType.Cms:
                endpointUrl = '/v2/news/cms';
                break;
            case OmaKalaEndpointType.News:
                endpointUrl = '/v2/news';
                break;
            case OmaKalaEndpointType.Auth:
                endpointUrl = '/v1/auth';
                break;
            case OmaKalaEndpointType.CatchRegistry:
                endpointUrl = '/v1/catch-registry';
                break;
            case OmaKalaEndpointType.Map:
                endpointUrl = '/map/nls';
                break;
            default:
                throw new Error('Unknown endpoint specified: ' + (endpoint as OmaKalaEndpointType));
        }
        return this.baseUrl + endpointUrl;
    }

    /**
     * Generic exception handling function.
     * @param {any} error
     * @return {FailedResponse}
     */
    protected onFailedResponse(error: any): FailedResponse {
        console.log(error);
        return {
            successful: false,
            message: error.response?.message ?? error.message ?? error,
        };
    }
}

export default BrowserWebService;
